import React from 'react';

import { rndKeyGen } from 'React/helpers';

import IconsMap from './_IconsMap'

const interactButton = ({ e, catIndex, category, index, type, update, states, setStates, touch, contents, setContents, technologies, solutions }) => {
  if ((type === 'hover' || type === 'mouseout') && touch !== 'no-touch') return false;
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

  if (vw < 1024) {
    e.preventDefault();
  }

  interaction({ e, catIndex, category, index, type, update, states, setStates, contents, setContents, technologies, solutions });
}

const interaction = ({ e, catIndex, category, index, type, update, states, setStates, contents, setContents, technologies, solutions }) => {



  //17 Slider de la cabecera: Desplazamiento, solo soluciones.
  if (type === 'click' && catIndex >= technologies.subModules.length) {

    //console.log('indice: ', index, 'tecnologias: ', technologies.subModules.length)
    var title = category.subModules[index].title;
    //var position = contents.indexOf(true);
    var domain = window.location.hostname;
    var link = category.subModules[index].links[0].link;
    var url = window.location.origin + link;

    var dataObject = {
      event: "ga_event",
      event_name: "solutions_text_click",
      event_category: "solutions text click",
      event_action: title,
      event_label: url,
      event_value: undefined,
      link_domain: domain,
      link_text: title,
      link_url: url
    }
    //console.log({ dataObject })
    dataLayer.push(dataObject);
  }

  const closeClick = !states[catIndex + index].clicked;

  setStates(states.map((el, i) => (i === catIndex + index) && closeClick ? ({ hover: type === 'hover' ? true : false, clicked: type === 'click' ? true : false }) : ({ hover: false, clicked: false })))
  update({ category, index, catIndex, type: closeClick ? type : 'closeclick', contents, setContents, technologies, solutions });
}

const degDiff = ({ category, position }) => {
  if (category.subModules.length % 2 !== 0) return 0;
  else if (position === 'left') return 7;
  return -7;
}

const IconsList = ({ classes, catIndex, category, update, states, setStates, position, size, touch, contents, setContents, technologies, solutions }) => {
  const ratio = category.subModules.length === 10 ? 13 : 15;

  const listStyle = {
    left: { transform: `translateX(-86px) rotate(${90 - (ratio / 2) * (category.subModules.length - 1)}deg)` },
    right: { transform: `translateX(86px) rotate(${-50 + (ratio / 2) * (category.subModules.length - 1)}deg)` },
  }

  const buttonDegLeft = Array.from({ length: category.subModules.length }, (x, i) => (Math.ceil(category.subModules.length / 2) * ratio) - (i + 1) * ratio + degDiff({ category, position }));
  const buttonDegRight = Array.from({ length: category.subModules.length }, (x, i) => (-(Math.ceil(category.subModules.length / 2)) * ratio) + (i + 1) * ratio + degDiff({ category, position }));

  const list = (
    <ul className={`module-solutions-technologies__category-list ${classes ? classes : ''} module-solutions-technologies__category-list--${position} ${category.subModules.length < 4 ? 'module-solutions-technologies__category-list--align-start' : ''} ${category.subModules.length === 10 || size ? 'module-solutions-technologies__category-list--small' : ''}`} style={listStyle[position]}>
      {category.subModules.map((submodule, index) =>
        <li className='module-solutions-technologies__category-list-element' key={rndKeyGen()} style={{ transform: `rotate(${position === 'left' ? 270 + (ratio * index) : 50 - (ratio * index)}deg) translateX(${position === 'left' ? 240 : -330}px)` }}>
          <a href={submodule.links[0].link} title={submodule.links[0].Caption} className={`module-solutions-technologies__category-button ${states[catIndex + index].clicked ? 'module-solutions-technologies__category-button--clicked' : ''} ${states[catIndex + index].hover ? 'module-solutions-technologies__category-button--hover' : ''}`} onClick={(e) => interactButton({ e, catIndex, category, index, type: 'click', update, states, setStates, touch, contents, setContents, technologies, solutions })} onMouseEnter={(e) => interactButton({ e, catIndex, category, index, type: 'hover', update, states, setStates, touch, contents, setContents, technologies, solutions })} onMouseOut={(e) => interactButton({ e, catIndex, category, index, type: 'mouseout', update, states, setStates, touch, contents, setContents, technologies, solutions })} style={{ transform: `rotate(${position === 'left' ? buttonDegLeft[index] : buttonDegRight[index]}deg)` }}>
            <span className='module-solutions-technologies__category-icon module-solutions-technologies__category-icon--custom'>{IconsMap[submodule.icon]}</span>
            <span className='module-solutions-technologies__category-icon module-solutions-technologies__category-icon--arrow'>{IconsMap['Arrow']}</span>
            <span className='module-solutions-technologies__category-icon module-solutions-technologies__category-icon--cross'>{IconsMap['Cross']}</span>
          </a>
        </li>
      )}
    </ul>
  );

  return list;
}

export default IconsList;