// button.component.js
import React from 'react';

const ButtonCta = ({ label, container, path, target }) => {

    if (container == true) {
        return (
            <div className="module grid">
                <div className="grid-container align-center">
                    <a className="btn-cta" href={path} target={target} >
                        {label}
                    </a>
                </div>
            </div>
        );
    } else {
        return (
            <a className="btn-cta" href={path} target={target} style={{ minWidth: 'max-content' }}>
                {label}
            </a>
        );
    }
};

export default ButtonCta;