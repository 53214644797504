import React from 'react';
import { ReactComponent as IconLeaf } from 'Icons/leaf.svg';
import { ReactComponent as IconIndustrial } from 'Icons/industrial-24.svg';
import { ReactComponent as IconOtros } from 'Icons/otrosnegocios-24.svg';
import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

export default {
    Ciudades: <CiudadesIcon height="18" width="18" viewBox="0 0 24 24" />,
    MedioAmbiente: <IconLeaf height="18" width="18" viewBox="0 0 24 24" />,
    Energia: <EnergyIcon height="18" width="18" viewBox="0 0 24 24" />,
    Transporte: <MovilidadIcon height="18" width="18" viewBox="0 0 24 24" />,
    Agua: <AguaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Social: <SocialIcon height="18" width="18" viewBox="0 0 24 24" />,
    Inmobiliaria: <InmobiliariaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Capital: <CapitalIcon height="18" width="18" viewBox="0 0 24 24" />,
    Industrial: <IconIndustrial height="18" width="18" viewBox="0 0 24 24" />,
    Otros: <IconOtros height="18" width="18" viewBox="0 0 24 24" />
};
