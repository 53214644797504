import React, { useRef } from 'react';

import { useStickyHeader } from 'React/custom_hooks';
import { Link } from 'React/components';

import './HeaderLanding.scss';
import LogoFull from 'Images/logos/logo_full.svg';

const HeaderLandingOwnDomain = ({ stickyHeader = true, logo, hrefLogo, homeLink, noSlogan, isOwnLanguage, changeLanguageText, changeLanguageLink, cultureInfo}) => {
    const headerRef = useRef();

    // STICKY HEADER ON SCROLL UP
    useStickyHeader(headerRef, stickyHeader);

    return (
        <>
            {!logo && (

                <header className={`header-landing${isOwnLanguage ? " header-landing__lang" : ""}`} ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className={`logo${noSlogan ? ' logo-sainz' : ''}`} src={noSlogan ? '/static/images/logos/acciona-sainz-xe-team.jpg' : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>

                    {isOwnLanguage && (
                            <div  className="header-landing__language">
                                <div className="landing-own-domain__lang__label">

                                    {cultureInfo === "es" ?
                                        <span className="text__bold text__not-link">{"ES"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"ES"}</span>
                                        </a>
                                    }
                      
                                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>

                                    {cultureInfo === "en" ?
                                        <span className="text__bold text__not-link">{"EN"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"EN"}</span>
                                        </a>
                                    }
                                </div>
                            </div>
                        )
                    }
                    

                </header>
            )}

            {logo && (
                <header className={`page-header grid header-landing header-landing--logo${isOwnLanguage ? " header-landing-logo__lang" : " header-landing--logo"}`} ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={noSlogan ? Logo : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                    <Link path={hrefLogo ? hrefLogo.link : '/'} className="logo-home-right">
                        <img
                            className="logo"
                            src={logo.url}
                            alt={logo.name}
                            title={logo.title}
                        />
                    </Link>
                    {isOwnLanguage && (
                    <div  className="header-landing__language-with-logo">
                    <div className="landing-own-domain__lang__label">
                        {cultureInfo === "es" ?
                        <a href={changeLanguageLink}>
                            <span className="text__not-link">{"EN"}</span>
                        </a> : 
                        <span className="text__bold">{"EN"}</span>}
                
                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>
                        {cultureInfo === "en" ?
                        <a href={changeLanguageLink}>
                            <span className="text__not-link">{"ES"}</span>
                        </a> : 
                        <span className="text__bold">{"ES"}</span>}
                    </div>
                </div>
                    )
                    }
                 
                </header>
            )}
        </>
    );
};

export default HeaderLandingOwnDomain;
