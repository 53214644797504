import React, { useState } from 'react';
import { PropTypes as pt } from 'prop-types';

import { rndKeyGen } from 'React/helpers';
import './styles.scss';
import { Link, Lightbox, Wysiwyg } from 'React/components';
import { ReactComponent as PhoneIcon } from 'Icons/phone.svg';
import { ReactComponent as EnvelopeIcon } from 'Icons/email.svg';
// solution icons
import IconsMap from './_iconsMap';

const ContactInfo = ({ model, privacyPolityHtml, labelPrivacyPolicy, labelCorporateHeadquarters, labelDepartments }) => {

    const { mainAddress, mainLink, mainTel, mainEmailFax, departments, subModules, popup } = model;

    return (
        <div className="module contact-info grid">
            {mainAddress && (
                <div className="contact-info__block grid contact-info__hq">
                    <h2 className="title title--m">{labelCorporateHeadquarters}</h2>
                    <div className="sub-block">
                        {mainAddress && <p className="address body--m">{mainAddress}</p>}
                        {mainLink && <Link className="map-link" label={mainLink.caption} path={mainLink.link} type="arrow" />}
                    </div>
                    <div className="sub-block">
                        {mainTel && <NumberLink number={mainTel} type="tel" large={true} />}
                        {mainEmailFax && <NumberLink number={mainEmailFax} type="fax" large={true} />}
                        <DataHandlingInfoLink privacyPolityHtml={privacyPolityHtml} labelPrivacyPolicy={labelPrivacyPolicy} popup={popup} />
                    </div>
                </div>
            )}

            {departments && (
                <div className="contact-info__block contact-info__departments">
                    <h2 className="title title--m">{labelDepartments}</h2>
                    <ul className="emails-list grid">
                        {departments.map((dept, idx) => (
                            <li key={rndKeyGen()} className="sub-block">
                                <a className="email-link" href={`mailto:${dept.link}`}>
                                    <EnvelopeIcon />
                                    <span className="taglabel">{dept.caption}</span>
                                    <span className="body--m email">{dept.link}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                    <DataHandlingInfoLink privacyPolityHtml={privacyPolityHtml} labelPrivacyPolicy={labelPrivacyPolicy} popup={popup} />
                </div>
            )}

            {subModules && (
                <div className="contact-info__block grid contact-info__solutions">
                    {subModules.map((solution, idx) => (
                        <SolutionBusinessCard solution={solution} privacyPolityHtml={privacyPolityHtml} labelPrivacyPolicy={labelPrivacyPolicy} popup={popup} className="sub-block" key={rndKeyGen()} />
                    ))}
                </div>
            )}
        </div>
    );
};

// SUB COMPONENTS
// ==============

const NumberLink = ({ number, email, type, large = false }) => {
    // remove all non-integers and prefix with '00'
    const urlNumber = '00' + number.replace(/[^0-9]+/g, '');

    return (
        <a href={`tel:${urlNumber}`} className={`number-link ${type}`}>
            {type === 'tel' ? <PhoneIcon /> : <EnvelopeIcon />}
            <p className={large ? 'title--s' : 'link'}>{number}</p>
        </a>
    );
};

const DataHandlingInfoLink = ({ privacyPolityHtml, labelPrivacyPolicy, popup }) => {


    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');

    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };


    return (
        <>
            <p className="body--s data-handling-link">
                {privacyPolityHtml} <a href="#" onClick={() => handleLightboxClick(popup.text)}>{labelPrivacyPolicy}</a>
            </p>
            <Lightbox
                key={rndKeyGen()}
                isVisible={visibleLightbox}
                closeMethod={() => setVisibleLightbox(null)}
            >
                <Wysiwyg html={lightboxContent} />
            </Lightbox>
        </>
    );
}

const SolutionBusinessCard = ({ solution, className, privacyPolityHtml, labelPrivacyPolicy, popup }) => {
    const { title, address, tel, fax, link, icon, solutionPopup } = solution;

    //const IconComponent = IconsMap[icon];

    return (
        <div className={`solution-business-card ${className}`}>
            <h3 className="name title--s">
                {icon ? IconsMap[icon] : ''}
                {title}
            </h3>
            {address && (
                <p className="address body--m">{address}</p>
            )}
            {(tel || fax) && (
                <div className="number-links-block">
                    {tel && (
                        <NumberLink number={tel} type="tel" />
                    )}
                    {fax && (
                        <NumberLink number={fax} type="fax" />
                    )}
                </div>
            )}
            <DataHandlingInfoLink privacyPolityHtml={privacyPolityHtml} labelPrivacyPolicy={labelPrivacyPolicy} popup={solutionPopup ? solutionPopup : popup} />
            {link && (
                <Link className="map-link" label={link.caption} path={link.link} type={link.type} target={link.target} />
            )}
        </div>
    );
};


export default ContactInfo;
