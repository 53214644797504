import React, { useEffect, useState } from "react";
import { Link, Wysiwyg } from "React/components";
import { rndKeyGen } from 'React/helpers';



import IconsMap from "./_iconsMap";

import { useSlowFade } from "React/custom_hooks";

import "./styles.scss";

const Conversion = ({ title, subtitle, content, image, icon, links }) => {

  const [isHome, setIsHome] = useState(false)

  if (image == null) {
    image = { imageUrl: "" };
  }
  const { url: imageUrl } = image;

  const moduleRef = React.createRef();
  const contentRef = React.createRef();

  useSlowFade(moduleRef);

  useEffect(() => {
    const url = window.location.pathname;
    (url == '/' || url == '/es/') ? setIsHome(true) : null
  });


  useEffect(() => {
    if (typeof window !== "undefined") {
      const callback = (entries) => {
        entries.forEach((el) => {
          if (el.isIntersecting) {
            el.target.classList.add("is-visible");
          }
        });
      };

      // Instancing a new IntersectionObserver
      const observer = new IntersectionObserver(callback);

      // Adding a target to be observed
      observer.observe(moduleRef.current);

    }
  }, []);

  return (
    <section ref={moduleRef} className="module module-conversion">
      {title && (
        <div className="grid module-conversion__top-title-container">
          <h2 className="title--xs module-conversion__top-title">{title}</h2>
        </div>
      )}
      <div
        className="module-conversion__container"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className={`${!isHome ? 'grid module-conversion__content-internal' : 'grid module-conversion__content'} `} >
          <h3 className={`title--l module-conversion__title ${!content ? "module-conversion__title--only" : ""} `}
          >
            {subtitle}
          </h3>
          {content && (
            <div className={`module-conversion__wysiwyg`}>
              <Wysiwyg html={content} />
            </div>
          )}
          {icon && links && (
            <span
              className={`module-conversion__icon ${links.length < 2 ? "module-conversion__icon--bottom" : ""
                }`}
            >
              {IconsMap[icon]}
            </span>
          )}
          <ul className="module-conversion__links-list">
            {links && links.map((link) => (
              <li className="module-conversion__links-element" key={rndKeyGen()}>
                <Link
                  className="module-conversion__links-anchor"
                  type="arrow"
                  path={link.link}
                  iconColor="icon--white"
                  title={link.caption}
                  children={link.caption}
                  target={link.type === 'External' ? "_blank" : ""}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Conversion;
