import React from 'react';
import { PropTypes as pt } from 'prop-types';

import './styles.scss';
import { rndKeyGen } from 'React/helpers';
import { ReactComponent as TwitterIcon } from 'Icons/social_media/twitter-x.svg';
import { ReactComponent as ReplyIcon } from 'Icons/responder-16.svg';
import { ReactComponent as RetweetIcon } from 'Icons/retweet-16.svg';
import { ReactComponent as FavoriteIcon } from 'Icons/favorite-16.svg';

const RSSfeed = ({ feed }) => {

    return (
        <div className="module rss-feed grid">
            {feed.map((item) => {
                const { account, text, response, retweet, favorite } = item;

                return (
                    <div className="feed-item" key={rndKeyGen()}>
                        <h3 className="handle title--xs">
                            <TwitterIcon width="14" height="14" viewBox="0 0 24 24" /><a href={`http://twitter.com/intent/user?screen_name=${account}`} target="_blank">@{account}</a>
                        </h3>
                        <div className="text-content body--l" dangerouslySetInnerHTML={{ __html: text }}></div>
                        <ul className="actions-list">
                            <li>
                                <a href={response} target="_blank">
                                    <span className="action-link taglabel">
                                        <ReplyIcon /> Responder
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href={retweet} target="_blank">
                                    <span className="action-link taglabel">
                                        <RetweetIcon /> Retweet
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href={favorite} target="_blank">
                                    <span className="action-link taglabel">
                                        <FavoriteIcon className="favorite" /> Favorito
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

export default RSSfeed;
