import React, { useState, useEffect } from 'react';
import { useSlowFade, useFade } from 'React/custom_hooks';
import { Link, Lightbox, ContactFormGreenEnergy, ContactFormPoliticaPrivacidad } from 'React/components';
import { rndKeyGen } from 'React/helpers';
import { ReactComponent as DownloadIcon } from 'Icons/download.svg';

import './styles.scss';

const FormularioSimple = ({
    model,
    formTranslation
}) => {

    const {
        title,
        ctaText,
        anchor,
        form,
        culture } = model;

    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [hasParameter, setHasParameter] = useState(false);

    const handleLightboxClick = () => {
        setVisibleLightbox(true);
    };

    const moduleRef = React.createRef();

    useSlowFade(moduleRef);
    useFade(moduleRef);

    useEffect(() => {
        console.log(window.location.search);
        if (!hasParameter && (window.location.search.includes("?activeForm=true") || window.location.search.includes("&activeForm=true"))) {
            setHasParameter(true);
            setVisibleLightbox(true);
        }

    })

    return (
        <div ref={moduleRef} className="module-wysiwyg simple-form">
            <div className="grid">

                <div className="slow--y text-container">
                    {title && (
                        <h3 >
                            {title}
                        </h3>
                    )}
                    <div className="lead-text-container slow--y">
                        {ctaText && (
                            <div className="slow--y" onClick={() => handleLightboxClick()}>
                                <Link
                                    className={ctaText === "Enviar Formulario" ? 'btn-form-send' : 'btn-form-send_english'}
                                    label={ctaText}
                                    type='lightbox' />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <span className="custom-anchor-small" id={anchor} />
            {
                form &&
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLightbox}
                    closeMethod={() => setVisibleLightbox(null)}
                >
                    {form.type === 'GreenEnergy' && <ContactFormGreenEnergy title={form.title} description={form.description} translations={formTranslation} closeLightbox={setVisibleLightbox} popup={form.popup} culture={culture} />}
                    {form.type === 'Politica Privacidad' && <ContactFormPoliticaPrivacidad title={form.title} description={form.description} translations={formTranslation} closeLightbox={setVisibleLightbox} popup={form.popup} culture={culture} accessRight={form.accessRight} rectificationRight={form.rectificationRight} suppresionRight={form.suppresionRight} treatmentRight={form.treatmentRight} dataRight={form.dataRight} oppositionRight={form.oppositionRight} />}
                </Lightbox>
            }
        </div >
    );
};

export default FormularioSimple;
