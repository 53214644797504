import React, { useState, useEffect } from 'react';
import './styles.scss';
import { ReactComponent as IconWind } from 'Icons/energia-viento.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import IconsMap from './_iconsMap';
import { iconsMap } from '../ProyectosDestacados/_iconsMap';
import { removeAccents } from 'React/helpers';

const ResultadosProyectos = ({ frames, restyling }) => {

    const refList = frames.map(frame => React.createRef());

    const onHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.add('is-hovered');
    };

    const notHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.remove('is-hovered');
    };

    if (typeof window !== 'undefined') {

        return (
            <div className="grid module">
                <div className={`grid-container module-resultados ${restyling ? 'module-resultados--restyling' : ''}`}>
                    {frames.map(({ indexImage, legacyHeader, headerImage, title, _contentPath, country, region, solution, isInstalation, energyTechIcon, areaName }, idx) => {


                        if (title) {
                            var imageUrl = headerImage ? headerImage.url : legacyHeader.replace("//media/", "/media/");
                            if (imageUrl) imageUrl = imageUrl.replace("http://media.acciona-agua.com", "https://mediacdn.acciona-agua.com");
                            return (
                                <div className="module-resultados__item white-background" key={`image-frame-${idx}`} ref={refList[idx]}>
                                    <div className="resultados__image" style={{ backgroundImage: `url(${imageUrl ? imageUrl : IconsMap[solution.icon + 'Default']})` }}></div>
                                    <div className="resultados__container">
                                        <div >
                                            <h2 className="title--m">
                                                {!isInstalation &&
                                                    <a data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} href={_contentPath ? _contentPath : '#'}>{title ? title : ''}</a>
                                                }
                                                {isInstalation &&
                                                    <>{title ? title : ''}</>
                                                }
                                            </h2>
                                            <div className="taglabel">
                                                {IconsMap[energyTechIcon ? removeAccents(energyTechIcon) : IconsMap[areaName?.replace(" ", "")] ? areaName.replace(" ", "") : solution.icon]}
                                                <p className='energy-text'>{areaName || solution.name}</p>
                                                <p className='pipe'>
                                                    {(region || country) && (
                                                        <>
                                                            {region ? region : ''}
                                                            {country && region ? `, ${country}` : country}
                                                        </>
                                                    )}
                                                </p>

                                            </div>
                                        </div>
                                        {!isInstalation &&
                                            <div className="cta">
                                                <a href={_contentPath} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}><ArrowRight viewBox="0 0 94 94" /></a>
                                            </div>
                                        }
                                    </div>
                                    <ul className='resultados__info'>
                                        <li className='resultados__info-element'>
                                            <span className='resultados__info-label'>Titularidad</span>
                                            <span className='resultados__info-value'>Cliente</span>
                                        </li>
                                        <li className='resultados__info-element'>
                                            <span className='resultados__info-label'>Estado</span>
                                            <span className='resultados__info-value'>Instalada</span>
                                        </li>
                                    </ul>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="grid module">
                <div className="grid-container module-resultados">
                </div>
            </div>
        );
    }
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};


export default ResultadosProyectos;
