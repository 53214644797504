import React from 'react';

//Social Media
import { ReactComponent as IconLinkedin } from 'Icons/social_media/linkedin.svg';
import { ReactComponent as IconYoutube } from 'Icons/social_media/youtube.svg';
import { ReactComponent as IconInstagram } from 'Icons/social_media/instagram.svg';
import { ReactComponent as IconTwitter } from 'Icons/social_media/twitter-x.svg';
import { ReactComponent as IconFacebook } from 'Icons/social_media/facebook.svg';
import { ReactComponent as IconTiktok } from 'Icons/social_media/tiktok.svg';


import { ReactComponent as IconSuministroEnergiaRenovableHeader } from 'Icons/V3/AE_Ic_Soluciones_Suministro energía renovable.svg';
import { ReactComponent as IconHidrogenoVerdeHeader } from 'Icons/V3/AE_Ic_Soluciones_Hidrógeno verde.svg';
import { ReactComponent as IconServiciosEnergeticosHeader } from 'Icons/V3/AE_Ic_Soluciones_Servicios -energéticos.svg';
import { ReactComponent as IconMovilidadElectricaHeader } from 'Icons/V3/AE_Ic_Soluciones_Movilidad eléctrica.svg';
import { ReactComponent as IconAutoconsumoHeader } from 'Icons/V3/AE_Ic_Soluciones_Autoconsumo.svg';

import { ReactComponent as IconEolicaHeader } from 'Icons/V6/24x24_Eólica.svg';
import { ReactComponent as IconFotovoltaicaHeader } from 'Icons/V6/24x24_Fotovoltaica.svg';
import { ReactComponent as IconTermosolarHeader } from 'Icons/V6/24x24_Termosolar.svg';
import { ReactComponent as IconBiomasaHeader } from 'Icons/V6/24x24_Biomasa.svg';
import { ReactComponent as IconHidroelectricaHeader } from 'Icons/V6/24x24_Hidroelectrica.svg';
import { ReactComponent as IconMarinaHeader } from 'Icons/V6/24x24_Eólica marina.svg';
import { ReactComponent as IconAlmacenamientoHeader } from 'Icons/V6/24x24_Almacenamiento.svg';

import { ReactComponent as LogoFullIcon } from 'Images/logos/logo_full.svg';
import { ReactComponent as IconArrowRightLongIcon } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconExternalIcon } from 'Icons/linkexternal.svg';
import { ReactComponent as IconArrowRightIcon } from 'Icons/iconarrowright.svg';
import { ReactComponent as IconArrowCircledIcon } from 'Icons/arrow_circled.svg';
import { ReactComponent as ChevronDownIcon } from 'Icons/chevron_down-18.svg';
import { ReactComponent as ChevronDownBigIcon } from 'Icons/chevron_down-18.svg';
import { ReactComponent as SocialIcon } from 'Icons/social-24.svg';

import { ReactComponent as Energy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as Ciudades } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as Inmobiliaria } from 'Icons/soluciones/inmobiliaria.svg';
import { ReactComponent as AreaTermosolarIcon } from 'Icons/Tecno/V1/24x24_Termosolar.svg';
import { ReactComponent as AreaAlmacenamientoIcon } from 'Icons/Tecno/V1/24x24_Almacenamiento.svg';
import { ReactComponent as AreaBiomasaIcon } from 'Icons/Tecno/V1/24x24_Biomasa.svg';
import { ReactComponent as AreaEolicaMarinaIcon } from 'Icons/Tecno/V1/24x24_Eolica marina.svg';
import { ReactComponent as AreaEolicaIcon } from 'Icons/Tecno/V1/24x24_Eolica.svg';
import { ReactComponent as AreaFotovoltaicaIcon } from 'Icons/Tecno/V1/24x24_Fotovoltaica.svg';
import { ReactComponent as AreaHidroelectricaIcon } from 'Icons/Tecno/V1/24x24_Hidroelectrica.svg';

import { ReactComponent as Movilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as Agua } from 'Icons/water-24.svg';
import { ReactComponent as Social } from 'Icons/social-24.svg';
import { ReactComponent as AreaClientes } from 'Icons/area_clientes.svg';
import { ReactComponent as Proveedores } from 'Icons/proveedores.svg';
import { ReactComponent as Capital } from 'Icons/capital-24.svg';
import { ReactComponent as HogarIcon } from 'Icons/hogar.svg';
import { ReactComponent as EmpresaIcon } from 'Icons/empresa.svg';

const IconsMap = {
  Energia: <Energy width="18" height="18" viewBox="0 0 24 24" />,
  Termosolar: <AreaTermosolarIcon height="18" width="18" viewBox="0 0 24 24" />,
  Almacenamiento: (<AreaAlmacenamientoIcon height="18" width="18" viewBox="0 0 24 24" />),
  Biomasa: <AreaBiomasaIcon height="18" width="18" viewBox="0 0 24 24" />,
  EolicaMarina: (<AreaEolicaMarinaIcon height="18" width="18" viewBox="0 0 24 24" />),
  Eolica: <AreaEolicaIcon height="18" width="18" viewBox="0 0 24 24" />,
  Fotovoltaica: (<AreaFotovoltaicaIcon height="18" width="18" viewBox="0 0 24 24" />),
  Hidroelectrica: (<AreaHidroelectricaIcon height="18" width="18" viewBox="0 0 24 24" />),
  Transporte: <Movilidad width="18" height="18" viewBox="0 0 24 24" />,
  Agua: <Agua width="18" height="18" viewBox="0 0 24 24" />,
  Social: <Social width="18" height="18" viewBox="0 0 18 20" />,
  Ciudades: <Ciudades width="18" height="18" viewBox="0 0 24 24" />,
  Inmobiliaria: <Inmobiliaria width="18" height="18" viewBox="0 0 24 24" />,
  Capital: <Capital width="18" height="18" viewBox="0 0 24 24" />,
  EnergiaDefault: '/static/images/project_default/proyectos_930x600_energia.svg',
  CiudadesDefault: '/static/images/project_default/proyectos_930x600_ciudades.svg',
  TransporteDefault: '/static/images/project_default/proyectos_930x600_transportes.svg',
  AguaDefault: '/static/images/project_default/proyectos_930x600_agua.svg',
  SocialDefault: '/static/images/project_default/proyectos_930x600_social.svg',
  CapitalDefault: '/static/images/project_default/proyectos_930x600_financieras.svg',
  InmobiliariaDefault: '/static/images/project_default/proyectos_930x600_inmobiliaria.svg',
  Empresa: <EmpresaIcon />,
  Hogar: <HogarIcon />,
  LogoFull: <LogoFullIcon className="header-restyling__logo-svg" />,
  IconArrowRightLong: (<IconArrowRightLongIcon className="header-restyling__layer-link-arrow" />),
  IconExternal: <IconExternalIcon />,
  IconArrowRight: <IconArrowRightIcon />,
  IconArrowCircled: (<IconArrowCircledIcon height="94" width="94" viewBox="0 0 94 94" />),
  ChevronDown: <ChevronDownIcon />,
  ChevronDownSmall: (<ChevronDownIcon viewBox="0 0 12 7" className="header-restyling__layer-main-link-chevron header-restyling__layer-main-link-chevron--small" />),
  ChevronDownBig: (<ChevronDownBigIcon viewBox="0 0 32 17" className="header-restyling__layer-main-link-chevron header-restyling__layer-main-link-chevron--big" />),
  AreaClientes: <AreaClientes />,
  Proveedores: <Proveedores />,
};

const IconsMapLarge = {
  Energia: <Energy width="24" height="24" viewBox="0 0 24 24" />,
  Termosolar: <AreaTermosolarIcon width="24" height="24" viewBox="0 0 24 24" />,
  Almacenamiento: (<AreaAlmacenamientoIcon width="24" height="24" viewBox="0 0 24 24" />),
  Biomasa: <AreaBiomasaIcon width="24" height="24" viewBox="0 0 24 24" />,
  EolicaMarina: (<AreaEolicaMarinaIcon width="24" height="24" viewBox="0 0 24 24" />),
  Eolica: <AreaEolicaIcon width="24" height="24" viewBox="0 0 24 24" />,
  Fotovoltaica: (<AreaFotovoltaicaIcon width="24" height="24" viewBox="0 0 24 24" />),
  Hidroelectrica: (<AreaHidroelectricaIcon width="24" height="24" viewBox="0 0 24 24" />),
  Transporte: <Movilidad width="24" height="24" viewBox="0 0 24 24" />,
  Agua: <Agua width="24" height="24" viewBox="0 0 24 24" />,
  Social: <Social width="24" height="24" viewBox="0 0 24 24" />,
  Ciudades: <Ciudades width="24" height="24" viewBox="0 0 24 24" />,
  Inmobiliaria: <Inmobiliaria width="24" height="24" viewBox="0 0 24 24" />,
  Capital: <Capital width="24" height="24" viewBox="0 0 24 24" />,
};

const SolutionsIcons = {
  Autoconsumo: (<IconAutoconsumoHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  HidrogenoVerde: (<IconHidrogenoVerdeHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  MovilidadElectrica: (<IconMovilidadElectricaHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  ServiciosEnergeticos: (<IconServiciosEnergeticosHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  SuministroEnergiaRenovable: (<IconSuministroEnergiaRenovableHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  Almacenamiento: (<IconAlmacenamientoHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  Biomasa: (<IconBiomasaHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  Eolica: (<IconEolicaHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  Marina: (<IconMarinaHeader className="header-restyling__layer-link-svg" height="24" width="24" viewBox="0 0 24 24" />),
  Fotovoltaica: (<IconFotovoltaicaHeader height="24" width="24" viewBox="0 0 24 24" />),
  Hidroelectrica: (<IconHidroelectricaHeader height="24" width="24" viewBox="0 0 24 24" />),
  Termosolar: (<IconTermosolarHeader height="24" width="24" viewBox="0 0 24 24" />),
};

const IconsSocialMedia = {
  Linkedin: <IconLinkedin width="16" height="16" viewBox="0 0 13 13" />,
  Youtube: <IconYoutube width="20" height="20" viewBox="0 0 16 11" />,
  Instagram: <IconInstagram width="16" height="16" viewBox="0 0 13 13" />,
  Twitter: <IconTwitter width="16" height="16" viewBox="0 0 24 24" />,
  Facebook: <IconFacebook width="16" height="16" viewBox="0 0 9 13" />,
  Tiktok: <IconTiktok />,
};

export { IconsMap, IconsMapLarge, SolutionsIcons, IconsSocialMedia };
