import React, { useState, useEffect } from 'react';
import { TagTitle, InputRangeSlider, Link } from 'React/components';
import { useSliderSwipeFeatured } from 'React/custom_hooks';
import ReactPlayer from 'react-player';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as VideoPlay } from 'Icons/video_play-white.svg';
import { ReactComponent as Cross } from 'Icons/cross-32.svg';

import IconsMap from './_iconsMap';

const DistribuidorDestacadoSlider = ({ frames, anchor, restyling }) => {
    const [ video, setVideo ] = useState(undefined);

    const swipableComponent = React.createRef();
    const [activeFrame, setActiveFrame] = useState(1);

    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const nextHover = () => {
        swipableComponent.current.querySelector('.frame-active .next-button').classList.add('is-animated');
        swipableComponent.current.querySelector('.frame-active .prev-button').classList.remove('is-animated');
    };

    const prevHover = () => {
        swipableComponent.current.querySelector('.frame-active .next-button').classList.remove('is-animated');
        swipableComponent.current.querySelector('.frame-active .prev-button').classList.add('is-animated');
    };

    const removeHover = () => {
        swipableComponent.current.querySelector('.frame-active .next-button').classList.remove('is-animated');
        swipableComponent.current.querySelector('.frame-active .prev-button').classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < frames.length) {
            setActiveFrame(parseInt(activeFrame) + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(parseInt(activeFrame) - 1);
        }
    };

    const openVideo = (id) => {
        setVideo({ class: 'module-distribuidor-destacado__video-container--opening' });

        setTimeout(() => {
            setVideo({ id });
        }, 600);
    }

    const closeVideo = () => {
        setVideo({ ...video, class: 'module-distribuidor-destacado__video-container--closing' });

        setTimeout(() => {
            setVideo(undefined);
        }, 400);
    }

    useEffect(() => {
        const ref = swipableComponent.current;

        function callback(entries, observer) {
            entries.forEach((el) => {
                if (el.isIntersecting == true) {
                    el.target.classList.add('is-visible');
                }
            });
        }

        // Instancing a new IntersectionObserver
        const observer = new IntersectionObserver(callback);

        // Adding a target to be observed
        observer.observe(ref);
    });

    useSliderSwipeFeatured(swipableComponent, setActiveFrame, frames.length);

    return (
        <section
            data-active-frame={activeFrame}
            ref={swipableComponent}
            className={`module grid module-distribuidor-destacado with-slider grid-flow--left  ${restyling ? 'module-distribuidor-destacado--restyling' : ''}`}
        >
            {frames.length > 1 && (
                <>
                    <button
                        className="ghost-frame-button ghost-frame-button--left"
                        onMouseOver={prevHover}
                        onMouseOut={removeHover}
                        onClick={prevFrame}
                    >
                        Slider anterior
                </button>
                    <button
                        className="ghost-frame-button ghost-frame-button--right"
                        onMouseOver={nextHover}
                        onMouseOut={removeHover}
                        onClick={nextFrame}
                    >
                        Slider siguiente
                </button>
                </>
            )}
            <span className="custom-anchor" id={anchor} />
            {frames.map((frame, idx) => {

                const { title, href, text, image, tagTitle, solutions, download, ods, logo, anchor, videoId } = frame;

                return (

                    <div className="grid data-point grid-container full-width" key={`data-point-${idx}`} >
                        <div className={`grid-container--left `}>
                            <div className={`grid-container--left image`} style={{ backgroundImage: `url(${image ? image.url : ''})` }}>
                                { videoId &&
                                    <button type='button' onClick={() => openVideo(videoId)} className={`module-distribuidor-destacado__video-button`}>
                                        <VideoPlay />
                                    </button>
                                }
                                {ods && <img src={ods.url} className="ods" alt={ods.alt ? ods.alt : ''} title={ods.title} />}
                                {logo && <img src={logo.url} className="logo" alt={logo.alt ? logo.alt : ''} title={logo.title} />}
                            </div>
                        </div>
                        <div className={`grid-container--right slow--y`}>
                            <div className="grid-container--text slow--y">
                                {tagTitle ?
                                    <TagTitle style="slow--y" title={tagTitle} />
                                    : null}

                                {title ?
                                    <h2 className="title--l slow--y">{title}</h2>
                                    : null}

                                {text ? (
                                    <div className="mt-2">
                                        <p className="body--m slow--y">{text}</p>
                                    </div>
                                ) : null}
                                <span className="custom-anchor" id={anchor} />
                                {href ?
                                    <div className="mt-3"><Link className="slow--y" type="arrow" path={href.link} label={href.caption} target={href.target} /></div>
                                    : null}

                                {solutions &&
                                    <div className="soluciones-destacado">
                                        <ul className="grid-container">
                                            {solutions.map((solution, idx) => {
                                                const { icon, menuText, contentPath } = solution;

                                                return (
                                                    <li className="title--xs slow--y"><a href={contentPath}>{IconsMap[icon]}{menuText}</a></li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                }

                                {download &&
                                    <div className="mt-3 download slow--y"><Link type="download" path={download.umbracoFile ? download.umbracoFile : download.file} label={download.name ? download.name : download.title} target={'_blank'} bytes={download.umbracoBytes} extension={download.umbracoExtension} /></div>
                                }
                            </div>
                            <InputRangeSlider
                                framesCount={frames.length}
                                noIndicator
                                activeFrame={parseInt(activeFrame)}
                                changeHandler={setActiveFrame}
                                whiteThumb={false}
                            />
                            <div className="slider-controls slow--y">
                                <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                                    <IconArrowRightLong />
                                </button>
                                <button ref={nextButton} className="next-button" onClick={nextFrame}>
                                    <IconArrowRightLong />
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
            { video &&
                <div className={`module-distribuidor-destacado__video-container ${video && video.class ? video.class : ''}`}>
                    <div className='grid module-distribuidor-destacado__video-grid'>
                        <button type='button' className='module-distribuidor-destacado__video-close' onClick={() => closeVideo()}>
                            <span>Cerrar vídeo</span>
                            <Cross />
                        </button>
                        { video.id &&
                            <ReactPlayer
                                width="100%"
                                url={`//www.youtube.com/watch?v=${video.id}`}
                                playing={true}
                                controls
                                className='module-distribuidor-destacado__video'
                            />
                        }
                    </div>
                </div>
            }
        </section>
    );
};

export default DistribuidorDestacadoSlider;
