import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'React/components';
import { ReactComponent as IconArrowLong } from 'Icons/ArrowLong.svg';

import { useSlowFade } from 'React/custom_hooks';
import { rndKeyGen } from 'React/helpers';

import './styles.scss';

const DistribuidorDestacadoColor = ({
  title,
  background,
  subModules: items,
}) => {
  const ref = useRef(null);
  const [carousel, setCarousel] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [height, setHeight] = useState(0);

  useSlowFade(ref);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const callback = (entries) => {
        entries.forEach((el) => {
          if (el.isIntersecting) {
            el.target.classList.add('is-visible');
          }
        });
      }

      // Instancing a new IntersectionObserver
      const observer = new IntersectionObserver(callback);

      // Adding a target to be observed
      observer.observe(ref.current);
    }
  }, []);

  const moveCarouselLeft = () => {
    if (carousel > 0) setCarousel(carousel - 1);
  }

  const moveCarouselRight = () => {
    if (carousel < items.length - 1) setCarousel(carousel + 1);
  }

  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && carousel < items.length - 1) {
      setCarousel(carousel + 1);
    } else if (isRightSwipe && carousel > 0) {
      setCarousel(carousel - 1);
    }
  }

  const getPosition = (e) => {
    const eventDoc = (e.target && e.target.ownerDocument) || document;
    const doc = eventDoc.documentElement;
    const body = eventDoc.body;

    return e.clientX +
      (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
      (doc && doc.clientLeft || body && body.clientLeft || 0);
  }

  const onMouseDown = (e) => {
    setTouchEnd(null)
    setTouchStart(getPosition(e));
  }

  const onMouseUp = (e) => {
    const posX = getPosition(e);

    if (!touchStart) return
    const distance = touchStart - posX
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && carousel < items.length - 1) {
      setCarousel(carousel + 1);
    } else if (isRightSwipe && carousel > 0) {
      setCarousel(carousel - 1);
    }
  }

  const calculateHeights = () => {
    const contents = ref.current.querySelectorAll('.ddc__content');
    const minHeight = [...contents].reduce((acc, el) => Math.max(acc, el.scrollHeight), 0);
    setHeight(minHeight);
  };

  useEffect(() => {
    if (items.length > 1) {
      setHeight(0);

      window.removeEventListener('resize', calculateHeights);
      window.addEventListener('resize', calculateHeights);

      calculateHeights();
    }
  }, []);

  const modClass = `ddc--${background}`;

  return (
    <section ref={ref} className={`module ddc ${background ? modClass : ''}`} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} /* onMouseDown={onMouseDown}  */ onMouseUp={onMouseUp}>
      {items.map((item, index) =>
        <figure key={rndKeyGen()} className={`ddc__media ${index === carousel ? 'ddc__media--visible' : ''} ${item.number ? 'ddc__media--veil' : ''}`}>
          <img src={item.img.url}></img>
        </figure>
      )
      }
      <div className='grid'>
        <div className="ddc__media-content">
          {title && <p className="title--xs ddc__sub">{title}</p>}
          {items.map((item, index) =>
            <div key={rndKeyGen()} className={`ddc__media-container ${index === carousel ? 'ddc__media-container--visible' : ''}`}>
              {item.number &&
                <p className="cifras--l ddc__number">
                  {item.number}
                  {item.numberExtension && <span className='ddc__number-extension'>{item.numberExtension}</span>}
                </p>}
              {item.caption && <p className="title--xs ddc__caption">{item.caption}</p>}
            </div>
          )}
        </div>
        <div className='ddc__main-content'>
          <div className='ddc__main-wrapper' style={{ minHeight: `${height}px` }}>
            {items.map((item, index) =>
              <div key={rndKeyGen()} className={`ddc__main-container ${index === carousel ? 'ddc__main-container--visible' : ''}`}>
                <div className='ddc__content'>
                  {item.title && <h2 className="title--l ddc__heading">{item.title}</h2>}
                  {item.links &&
                    item.links.map((link) =>
                      <Link
                        className="ddc__link"
                        type={link.type}
                        path={link.link}
                        iconColor='icon--white'
                        title={link.caption}
                        children={link.caption}
                        target={link.type === 'External' ? "_blank" : ""}
                        key={rndKeyGen()}
                      />
                    )
                  }
                </div>
              </div>
            )}
          </div>
          {items.length > 1 &&
            <div className="ddc__slider slow--y">
              <div className="ddc__slider-bar" aria-hidden={true}>
                <ul className="ddc__slider-list">
                  {items.map((item, index) =>
                    <li key={rndKeyGen()} className='ddc__slider-list-element' style={{ width: `${100 / items.length}%` }}>
                      <button type='button' className='ddc__slider-list-button' onClick={() => setCarousel(index)} disabled={carousel === index}>
                        Ir al elemento {index} del slider
                      </button>
                    </li>
                  )}
                </ul>
                <span className="ddc__slider-bar--active" aria-hidden={true} style={{ width: `${100 / items.length}%`, left: `${(100 / items.length) * carousel}%` }}></span>
              </div>
              <button type='button' className={`ddc__slider-button ddc__slider-button--left ${carousel === 0 ? 'ddc__slider-button--disabled' : ''}`} onClick={moveCarouselLeft}><IconArrowLong /></button>
              <button type='button' className={`ddc__slider-button ${carousel === items.length - 1 ? 'ddc__slider-button--disabled' : ''}`} onClick={moveCarouselRight}><IconArrowLong /></button>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default DistribuidorDestacadoColor;
