import { getSiteLanguage } from '../helpers';

function useAnalytics() {

    const trackClick = (e) => {


        //16 Menú: Clic (II) Cuando hacemos click en un elemento del menú de navegación principal.
        if (e.currentTarget.className === 'header-restyling__layer-main-link' ||
            e.currentTarget.className === 'header-restyling__layer-link' ||
            e.currentTarget.className === 'header-restyling__layer-sublink'
        ) {

            const eventLabel = e.currentTarget.getAttribute('href');
            const linkUrl = e.currentTarget.href;

            var dataObject = {
                event: "ga_event",
                event_name: "menu",
                event_category: "menu",
                event_action: "main",
                event_label: eventLabel,
                event_value: undefined,
                link_text: eventLabel,
                link_url: linkUrl,
                location: "main"
            }
            dataLayer.push(dataObject);
        }
        //15 Menú: Clic (I) - Cuando hacemos click en un elemento del menú de navegación de la parte superior derecha de la pantalla.
        else if (e.currentTarget.className === 'stock-ticker' ||
            e.currentTarget.className === 'header-restyling__clients-link' ||
            e.currentTarget.className === 'header-restyling__energy-link' ||
            e.currentTarget.className === 'header-restyling__language-link'
        ) {

            const eventLabel = e.currentTarget.getAttribute('href');
            const linkUrl = e.currentTarget.href;
            const ruta = window.location.href;
            const changedCulture = e.currentTarget.getAttribute('title').toLowerCase();
            const currentCulture = changedCulture === 'español' ? 'English' : 'Español';
            var dataObjectLanguage = {}

            var dataObject = {
                event: 'ga_event',
                event_name: "menu",
                event_category: "menu",
                event_action: "top right",
                event_label: e.currentTarget.className === 'header-restyling__energy-link' ?
                    changedCulture.includes('energía') ?
                        '/contacto/'
                        : '/contact/'
                    : eventLabel,
                event_value: undefined,
                link_text: e.currentTarget.className === 'header-restyling__energy-link' ? changedCulture.includes('energía') ? '/contacto/' : '/contact/' : eventLabel,
                link_url: linkUrl,
                location: "top right"
            }


            if (changedCulture === 'english' || changedCulture === 'español') {
                dataObjectLanguage = {
                    event: 'ga_event',
                    event_name: 'language_change',
                    event_category: 'language change',
                    event_action: currentCulture.toLowerCase() + ' : ' + changedCulture,
                    event_label: undefined,
                    event_value: undefined,
                    option: currentCulture.toLowerCase() + ' : ' + changedCulture,
                }
                window.dataLayer.push(dataObjectLanguage);
            }
            window.dataLayer.push(dataObject);

        }

        else if (e.currentTarget.getAttribute("data-id") === 'newsletter') { //25 Newsletter: Enviar

            if (e.currentTarget.children[0].firstElementChild.children[0].firstChild.textContent.includes('newsletter')) {
                var dataObject = {
                    event: "ga_event",
                    event_name: "newsletter_submit",
                    event_category: "newsletter",
                    event_action: "submit",
                    event_label: undefined,
                    event_value: undefined
                }
                // console.log('25', dataObject);
            }

        }
    };

    //13 Agrupaciones de Contenido -  12 Variables de página
    const pageVariable = () => {

        const url = window.location.pathname;
        const siteLanguage = getSiteLanguage();
        let pageType = "";

        if (url === '/' || url === '/es/') {
            pageType = 'Home'
        }
        else if (url.includes("nuestro-proposito") || url.includes("our-purpose")) {
            pageType = "our-purpose";
        } else if (url.includes("soluciones") || url.includes("solutions")) {
            pageType = "solutions";
        } else if (url.includes("proyectos") || url.includes("projects")) {
            pageType = "projects";
        } else if (url.includes("actualidad") || url.includes("updates")) {
            pageType = "newsroom";
        } else if (url.includes("accionistas-inversores") || url.includes("shareholders-investors")) {
            pageType = "shareholders and investors"
        } else if (url.includes("resultados") || url.includes("results")) {
            pageType = "search";
        } else {
            pageType = "other";
        }
        dataLayer.push({
            language: siteLanguage,
            page_type: pageType,
        });
    }

    const scrollPercentage = () => {

        $("#mainLayout").scroll(function () {
            registerScrollLayout(calculateScrollPercentage(this));
        })

        var calculateScrollPercentage = function (mainElement) {
            var percentage = Math.floor(((mainElement.scrollTop + mainElement.clientHeight) / mainElement.scrollHeight) * 100);
            return percentage;
        };

        //Scroll percentages
        let percentagesProcessed = [];
        var registerScrollLayout = function (scrollPercentage) {

            let validPercentages = [10, 25, 50, 75, 100];
            scrollPercentage < 25 ? scrollPercentage = 10 : null;

            if (validPercentages.includes(scrollPercentage) && !percentagesProcessed.includes(scrollPercentage)) {

                percentagesProcessed.push(scrollPercentage);

                dataLayer.push({
                    event: "ga_event",
                    event_name: "scroll",
                    event_category: "scroll",
                    event_action: "vertical",
                    event_label: scrollPercentage + "%",
                    event_value: scrollPercentage,
                    non_interaction: true,
                    category: "vertical",
                    quantity: scrollPercentage
                });
            }
        }
    }


    return {
        trackClick,
        pageVariable,
        scrollPercentage
    };
}

export default useAnalytics;