import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconArrowRight } from 'Icons/iconarrowright.svg';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconDownload } from 'Icons/download.svg';
import { ReactComponent as IconPlus } from 'Icons/iconplus.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';

import uiContext from 'Data/_uiContext';

const _Link = ({ type, fileParams, bytes, extension, label, path, children, className, subsection, target, onClickFunction, module, title, iconColor, frame, frameTitle }) => {




    // the following path prefix needs to be modified to match actual production paths
    const href = `${process.env.NODE_ENV === 'production' ? '/dist' : ''}${path}`;
    const context = useContext(uiContext);

    const toggleSubsectionVisibility = () => {
        if (subsection) {
            const { order } = subsection;
            context.updateDesktopNavSubsState(order);
        }
        if (['/proyectos', '/actualidad', '/about-us', '/our-approach', '/projects', '/news'].includes(path)) {
            context.updateDesktopNavSubsState(null);
        }
    };

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const pushdataLayer = (e) => {

        if (module === 'distribuidor') {

            var url = window.location.pathname.split('/');
            var solutionType = url[length];

            var dataObject = {

                event: "ga_event",
                event_name: "click_cta",
                event_category: " click cta",
                event_action: "click cta",
                event_label: (frame + 1) + ' - ' + frameTitle.toLowerCase(),
                event_value: undefined,
                title: (frame + 1) + ' - ' + frameTitle.toLowerCase(),
                link_text: children,
                link_url: path,
                location: solutionType
            }
            window.dataLayer.push(dataObject);

        }
        if (module === 'distribuidorMultipleVisual') {
            var title = e.currentTarget.getAttribute('title');
            var dominio = window.location.hostname;
            var url = window.location.origin + path;

            var dataObject = {
                event: "ga_event",
                event_name: module === "distribuidorMultipleVisual" ? "solution_mosaic_click" : "distribution_module_click",
                event_category: module === "distribuidorMultipleVisual" ? "solution mosaic click" : "distribution module click",
                event_action: title.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                event_label: url,
                event_value: undefined,
                link_domain: dominio,
                link_text: title.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
                link_url: url
            }

            console.log(url);
            window.dataLayer.push(dataObject);
        }
    }

    if (type === 'arrow' || type === 'Internal' || type === 'Content') {

        const arrow = !label && !children ? <IconArrowRightLong className="cta-single" /> : <IconArrowRight className="cta-single" />;

        return (
            <>
                {module === 'distribuidor' &&
                    <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} ${className}`} href={href} onClick={pushdataLayer} data-title={title} target={target ? target : ''}>
                        <span>
                            {label}
                            {children}
                            {arrow}
                        </span>
                    </a>
                }
                {module === 'distribuidorMultipleVisual' &&
                    <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} ${className}`} href={href} target={target ? target : ''} children={children} title={title} onClick={pushdataLayer}>
                        <span>
                            {label}
                            {children}
                            {arrow}
                        </span>
                    </a>
                }
                {module != 'distribuidorMultipleVisual' && module != 'distribuidor' &&
                    <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} ${className}`} href={href} target={target ? target : ''}>
                        <span>
                            {label}
                            {children}
                            {arrow}
                        </span>
                    </a>
                }
            </>
        );
    }
    if (type === 'arrowLong') {
        return (
            <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} ${className}`} href={href} target={target ? target : ''}>
                <span>
                    {label}
                    {children}
                    <IconArrowRightLong />
                </span>
            </a>
        );
    }
    if (type === 'download' || type === 'Media' || type === 'File') {
        return (
            <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} link--download ${className}`} href={href} target='_blank'>
                <IconDownload />
                <span>
                    {label}
                    {children}
                </span>
                <p className="link--download-type">{bytes ? extension + ' ' + bytesToSize(bytes) : 'PDF 814KB'}</p>
            </a>
        );
    }
    if (type === 'legalPopup') {
        return (
            <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} link--download ${className}`} href="#" onClick={onClickFunction}>
                <IconDownload />
                <span>
                    {label}
                    {children}
                </span>
                <p className="link--download-type">{bytes ? extension + ' ' + bytesToSize(bytes) : 'PDF 814KB'}</p>
            </a>
        );
    }
    if (type === 'back') {
        return (
            <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} link--back ${className}`} href={href}>
                <IconArrowRight />
                <span>
                    {label}
                    {children}
                </span>
            </a>
        );
    }
    if (type === 'subscribe') {
        return (
            <a className={`link link--icon link--subscribe`} to="#">
                <IconCalendar />
                <span>
                    {label}
                    {children}
                </span>
            </a>
        );
    }
    if (type === 'lightbox') {
        return (
            <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} link--lightbox ${className}`} href="#">
                <span>
                    {label}
                    {children}
                    <IconPlus />
                </span>
            </a>
        );
    }
    if (type === 'External') {
        return (
            <>
                {module === 'distribuidor' &&
                    <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} link--external ${className}`} href={href} onClick={pushdataLayer} data-title={title} target={target ? target : ''}>
                        <span>
                            {label}
                            {children}
                            <IconExternal />
                        </span>
                    </a>
                }
                {module != 'distribuidor' &&
                    <a className={`link link--icon ${iconColor ? iconColor : 'icon--red'} link--external ${className}`} href={href} target={target ? target : ''}>
                        <span>
                            {label}
                            {children}
                            <IconExternal />
                        </span>
                    </a>
                }
            </>
        );
    } else {
        return (
            <>
                {module === 'distribuidor' &&
                    <a className={className} href={href} onClick={pushdataLayer} data-title={title} onMouseOver={toggleSubsectionVisibility} target={target ? target : ''}>
                        {label}
                        {children}
                    </a>
                }
                {module != 'distribuidor' &&
                    <a className={className} href={href} onMouseOver={toggleSubsectionVisibility} target={target ? target : ''}>
                        {label}
                        {children}
                    </a>
                }
            </>
        );
    }
};

export default _Link;
