import React, { useState, useEffect } from 'react';

import './styles.scss';

import { TagTitle, Link } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';

import { useSlowFade } from 'React/custom_hooks';

const EquipoDirectivoDestacado = ({ title, subModules, anchor, legend }) => {
    const [activeAudio, setActiveAudio] = useState(null);

    // slider functionality
    const thisModule = React.createRef();

    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    useSlowFade(thisModule);

    // audio controls
    const audioElementRefs = subModules.map((element) => React.createRef());
    const audioStatsRefs = subModules.map((element) => React.createRef());

    const togglePlay = (frameId) => {
        setActiveAudio((oldState) => (oldState === frameId ? null : frameId));
    };

    useEffect(() => {
        audioElementRefs.forEach((ref) => {
            if (ref.current != null) {
                ref.current.pause();
            }
        });

        if (activeAudio !== null) {
            audioElementRefs[activeAudio - 1].current.play();
        }
    }, [activeAudio]);

    // audio stats
    const formatedAudioTime = (timeFloat) => {
        if (timeFloat === Infinity) return '<i>∞</i>';

        const seconds = parseInt(timeFloat);
        if (seconds < 60) return `0’${seconds}’’`;

        if (seconds >= 60) return `${parseInt(seconds / 60)}’${seconds % 60}’’`;

        return timeFloat;
    };

    useEffect(() => {
        audioElementRefs.forEach((audioEl, idx) => {
            if (audioStatsRefs[idx].current != null) {
                const statsElement = audioStatsRefs[idx].current;
                const [oldTime, oldDuration] = statsElement.innerText.split('/');

                audioEl.current.addEventListener('loadedmetadata', (event) => {
                    const { duration } = event.target;

                    statsElement.innerHTML = `${oldTime} / ${formatedAudioTime(duration)}`;
                });

                audioEl.current.addEventListener('timeupdate', (event) => {
                    const { currentTime, duration } = event.target;

                    statsElement.innerHTML = `${formatedAudioTime(currentTime)} / ${formatedAudioTime(duration)}`;
                });
            }
        });
        return () => {
            audioElementRefs.forEach((audioEl) => {
                audioEl.current.removeEventListener('loadedmetadata');
                audioEl.current.removeEventListener('timeupdate');
            });
        };
    }, []);

    return (
        <div className="module grid equipo-directivo-destacado" ref={thisModule}>
            <div className="slow--y equipo-directivo-destacado-container" id={anchor}>
                <div className="grid-container">{title && <TagTitle equipo title={title} />}</div>

                <div className="frames-container grid full-width">
                    {subModules.map((frame, idx) => {
                        const { status, personImage, personName, audio, cta, isDirector } = frame;
                        return (
                            <div className="frame" key={`tecno-frame-${idx}`}>
                                <div className="frame-content">
                                    {personImage && (
                                        <div className="person-picture">
                                            <img src={personImage.url} alt={personImage.alt ? personImage.alt : ''} title={image.title} />
                                        </div>
                                    )}

                                    <div className="person-content">
                                        <div className="person-name">
                                            {status && <h3 className="title--m">{status}</h3>}
                                            {cta &&
                                                <div className="person-data">
                                                    <h2 className="title--s">
                                                        <a href={cta.link} target={cta.target}>{personName}</a>
                                                        {isDirector &&
                                                            <div className="icon-container">
                                                                <IconDireccion className="hover-info" />
                                                                <div className="info">
                                                                    {legend}
                                                                </div>
                                                            </div>
                                                        }
                                                    </h2>
                                                </div>
                                            }
                                            {!cta &&
                                                <div className="person-data">
                                                    <h2 className="title--s">
                                                        {personName}
                                                        {isDirector &&
                                                            <div className="icon-container">
                                                                <IconDireccion className="hover-info" />
                                                                <div className="info">
                                                                    {legend}
                                                                </div>
                                                            </div>
                                                        }
                                                    </h2>
                                                </div>
                                            }
                                            {/*cta &&
                                                <Link type="arrow" path={cta.link} label={cta.caption + " "}></Link>
                                            */}
                                            <div className="person-audio title--xs">
                                                {audio && (
                                                    <div className="player-content">
                                                        <div className="audio title--xs">
                                                            <audio id="player" ref={audioElementRefs[idx]}>
                                                                <source src={audio.umbracoFile} type="audio/mpeg" />
                                                            </audio>

                                                            {audio.name}
                                                            <button id="player-button" onClick={() => togglePlay(idx + 1)}>
                                                                <img src="/static/icons/video.svg" />
                                                            </button>
                                                            <span className="audio-stats" ref={audioStatsRefs[idx]}>
                                                                0'0'' / --
                              </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EquipoDirectivoDestacado;
