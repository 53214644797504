// button.component.js
import React, { useEffect } from 'react';
import { Link, TagTitle, EnlaceAgrupador } from 'React/components';

import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import './Styles.scss';


const error404 = ({ labelError404, label404Alternatives, label404VerifyUrl, labelPageNotFound, labelGoToHome, homeUrl }) => {
    return (
        <div className="template-404 module grid">
            <div className="grid-container">
                <div className="module-404">
                    <h2 className="title--l">{labelError404}</h2>
                    <p className="body--l mt-3">{labelPageNotFound}</p>
                    <p className="body--m mt-3">
                        {label404VerifyUrl}
					</p>
                    <p className="body--m mt-1"> {label404Alternatives}
				 </p>
                    <a href={homeUrl} className="btn-cta mt-4">{labelGoToHome}</a>
                </div>
            </div>
        </div>
    )

};

export default error404;