import React, { useState, useEffect, useRef } from "react";
import { Link } from "React/components";

import { useSlowFade } from "React/custom_hooks";

import "./index.scss";

const DistribuidorMultipleVisual = ({ subModules: elements }) => {
  const ref = useRef(null);
  const [heights, setHeights] = useState(undefined);

  useSlowFade(ref);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const callback = (entries) => {
        entries.forEach((el) => {
          if (el.isIntersecting) {
            el.target.classList.add("is-visible");
          }
        });
      };

      // Instancing a new IntersectionObserver
      const observer = new IntersectionObserver(callback);

      // Adding a target to be observed
      observer.observe(ref.current);
    }
  }, []);

  const resetHeights = () => {
    setHeights(undefined);
  };

  const mouseOver = (e) => {
    const el = e.target
      .closest(".module-distribuidor-multiple-visual__element")
      .querySelector(".module-distribuidor-multiple-visual__info-container");
    if (el) el.style.minHeight = el.dataset.elHeight + "px";
  };

  const mouseOut = (e) => {
    const el = e.target
      .closest(".module-distribuidor-multiple-visual__element")
      .querySelector(".module-distribuidor-multiple-visual__info-container");
    if (el) el.style.minHeight = 0;
  };

  useEffect(() => {
    if (elements.length > 0) {
      setTimeout(() => resetHeights(), 500);

      window.removeEventListener("resize", resetHeights);
      window.addEventListener("resize", resetHeights);
    }
  }, []);

  useEffect(() => {
    if (heights === undefined) {
      const containers = ref.current.querySelectorAll(
        ".module-distribuidor-multiple-visual__content"
      );

      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const margins = () => {
        if (windowWidth < 1280) return { bottom: 16, top: 160 };
        if (windowWidth < 1920) return { bottom: 24, top: 200 };
        return { bottom: 24, top: 400 };
      };

      const heights = [...containers].map((el) => {
        const title = el.querySelector(
          ".module-distribuidor-multiple-visual__title"
        );
        const desc = el.querySelector(
          ".module-distribuidor-multiple-visual__description"
        );
        const link = el.querySelector(
          ".module-distribuidor-multiple-visual__link"
        );
        const titleHeight = title.scrollHeight;
        const descHeight = desc ? desc.scrollHeight : 0;
        const linkHeight = link ? link.scrollHeight + margins().bottom : 0;
        return {
          container: titleHeight + descHeight + linkHeight + margins().top,
          info: descHeight + linkHeight,
        };
      });

      setHeights(heights);
    }
  }, [heights]);

  return (
    <section ref={ref} className="module module-distribuidor-multiple-visual">
      <ol
        className={`module-distribuidor-multiple-visual__list ${elements.length === 6
          ? "module-distribuidor-multiple-visual__list--two-rows"
          : ""
          }`}
      >
        {elements.map((el, index) => (
          <li
            className={`title--s module-distribuidor-multiple-visual__element ${el.description || el.link.title
              ? "module-distribuidor-multiple-visual__element--hover"
              : ""
              }`}
            style={{ minHeight: heights ? heights[index].container : 0 }}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
          >
            <div className="module-distribuidor-multiple-visual__content">
              <picture className="module-distribuidor-multiple-visual__picture">
                <img
                  className="module-distribuidor-multiple-visual__image"
                  src={el.image.url}
                  title={el.image.name}
                />
              </picture>
              <h2 className="title--m module-distribuidor-multiple-visual__title">
                {el.title}
              </h2>
              {(el.description || (el.link && el.link.caption)) && (
                <div
                  className="module-distribuidor-multiple-visual__info-container"
                  data-el-height={heights ? heights[index].info : 0}
                  style={{ minHeight: 0 }}
                >
                  {el.description && (
                    <p className="body--m module-distribuidor-multiple-visual__description">
                      {el.description}
                    </p>
                  )}
                  {el.link && el.link.caption && (
                    <Link
                      className="module-distribuidor-multiple-visual__link"
                      type="arrow"
                      path={el.link.link}
                      iconColor="icon--white"
                      title={el.link.caption}
                      children={el.link.caption}
                      target={el.link.type === 'External' ? "_blank" : ""}
                      module="distribuidorMultipleVisual"
                    />
                  )}
                </div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </section>
  );
};

export default DistribuidorMultipleVisual;
