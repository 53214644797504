import React, { useRef, useState, useEffect } from 'react';

import { Button } from 'React/components';

import './Header.scss';
import './NavSecondary.scss';
import './NavSubsection.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { useStickyHeader } from 'React/custom_hooks';

import { ReactComponent as User } from 'Icons/usuario-16.svg';
import { ReactComponent as HamburgerMenuIcon } from 'Icons/hamburgermenu.svg';
import { ReactComponent as HamburgerMenuIconTablet } from 'Icons/hamburgermenu-tablet.svg';
import { ReactComponent as Triangle } from 'Icons/triangleup.svg';
import { ReactComponent as SearchLense } from 'Icons/searchlense.svg';
import { ReactComponent as Plus } from 'Icons/plus-10.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconClose } from 'Icons/cross.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';

import IconsMap from './_iconsMap';

import { ReactComponent as Chevron_L } from 'Icons/chevron_down-18.svg';
import { ReactComponent as Chevron_M } from 'Icons/chevron_down-10.svg';

const Header = ({ isVisible, stickyHeader, menu, areaClientesLabel, areaClientesLink, contactLabel, contactLink, changeLanguageLabel, changeLanguageLink, culture, searchLabel, dropdownMenuPurpose, dropdownMenuSoluciones, dropdownMenuShareholders, labelPortada, labelCountries, labelCountriesMenu, logoLink, clientLabel, path }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [mobileIsOpen, setMobileIsOpen] = useState(false);

    const searchInput = useRef(null);
    const searchInputTestStand = useRef(null);

    const swipeTargetHeader = React.createRef();
    const desktopNav = React.createRef();
    const mobileNav = React.createRef();
    const navDrawer = React.createRef();
    const countriesRef = React.createRef();
    const searchRef = React.createRef();

    const headerRef = useRef();

    // var stockTicker = "https://ifbaspst.infobolsanet.com/ifblayout2/Layout.aspx?layout=MiniHome&client=Acciona2020";
    var stockTicker = "https://asp.infobolsanet.com/ifblayout/Layout.aspx?layout=MiniHome&client=AccionaEnergia";

    if (culture === "en") {
        stockTicker = stockTicker + "&lang=en";
    }

    function toggleMobileSubsection(event) {
        event.currentTarget.classList.toggle('active');

        document.querySelectorAll('.subsection__toggle').forEach(function (item) {
            if (item !== event.currentTarget) {
                item.classList.remove('active');
            }
        });
    }

    function toggleMobileChildSubsection(event) {
        event.currentTarget.classList.toggle('active');

        document.querySelectorAll('.subsection-child__toggle').forEach(function (item) {
            if (item !== event.currentTarget) {
                item.classList.remove('active');
            }
        });
    }

    const onHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];
        const parentLinks = [...document.querySelectorAll('.link-lvl-0')];

        parentLinks.map((item) => {
            if (item.dataset.sectionId === event.currentTarget.dataset.sectionId) {
                item.classList.add('is-active');
            } else {
                item.classList.remove('is-active');
            }
        });

        navSubsections.forEach(function (item) {
            if (item.dataset.section === event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = true;
                item.classList.add('is-visible');
                navDrawer.current.dataset.sectionActive = item.dataset.section;
                navDrawer.current.dataset.isVisible = true;
                navDrawer.current.classList.add('is-visible');
            }

            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = false;
                item.classList.remove('is-visible');
            }

            if (event.currentTarget.dataset.children === 'no') {
                navDrawer.current.dataset.isVisible = false;
                navDrawer.current.classList.remove('is-visible');
            }
        });
    };

    const notHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];
        const parentLinks = [...document.querySelectorAll('.link-lvl-0')];
        parentLinks.map((item) => {
            if (item.dataset.children === 'no') {
                item.classList.remove('is-active');
            }
        });
        navSubsections.forEach(function (item) {
            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = false;
                item.classList.remove('is-visible');
            }
        });
    };

    const toggleSubsectionVisibility = () => {
        navDrawer.current.dataset.isVisible = false;
        navDrawer.current.classList.remove('is-visible');
        const parentLinks = [...document.querySelectorAll('.link-lvl-0')];
        parentLinks.map((item) => {
            item.classList.remove('is-active');
        });
    };

    const openMenu = () => {
        setMobileIsOpen(true);
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const closeMenu = () => {
        setMobileIsOpen(false);
        document.querySelector('.app--layout').dataset.canScroll = true;
    };

    const openCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = true;
    };

    const closeCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = false;
    };

    const openSearchOverlay = () => {
        searchRef.current.dataset.isVisible = true;
    };

    const closeSearchOverlay = () => {
        searchRef.current.dataset.isVisible = false;
    };

    useEffect(() => {
        if (isVisible) searchInput.current.focus();
    }, [isVisible, mobileIsOpen]);

    const handleInput = (e) => {
        setSearchTerm(e.target.value);

        const input = searchInput.current;
        const testStand = searchInputTestStand.current;

        // measure the length of the input text and update input width
        testStand.innerText = searchTerm;
        input.style.width = `calc(2em + ${testStand.getBoundingClientRect().width}px)`;
    };

    const pushDataLayer = (event) => {

        window.dataLayer.push({
            event: "ga_event",
            event_name: "menu",
            event_category: "menu",
            event_action: event.currentTarget.attributes['data-menu-click-group'].value.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            event_label: event.currentTarget.attributes['data-menu-click-path'].value,
            event_value: undefined,
            link_text: event.currentTarget.attributes['data-menu-click-path'].value,
            link_url: event.currentTarget.attributes['data-menu-click-target'].value,
            location: event.currentTarget.attributes['data-menu-click-group'].value.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
        });

        console.log(window.dataLayer);

        if (event.currentTarget.attributes['data-changeLang'].value === 'changeLang') {

            window.dataLayer.push({
                event: "ga_event",
                event_name: "language_change",
                event_category: "language change",
                event_action: (culture === 'es' ? 'español' : 'english') + " : " + changeLanguageLabel,
                event_label: undefined,
                event_value: undefined,
                option: (culture === 'es' ? 'español' : 'english') + " : " + changeLanguageLabel
            });


        }
    }
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        var searchText = event.target.search.value;

        window.location.href = `/${culture === 'es' ? "es/resultados" : "results"}?search=${searchText}`;

    };

    const countriesList = [
        { label: culture === 'es' ? 'Australia' : 'Australia', path: 'https://www.acciona.com.au/' },
        { label: culture === 'es' ? 'Canadá' : 'Canada', path: 'https://www.acciona.ca/' },
        { label: 'EEUU', path: 'https://www.acciona.us/' },
        { label: culture === 'es' ? 'México' : 'Mexico', path: 'https://www.acciona-mx.com/' },
        { label: 'Chile', path: 'https://www.acciona.cl/' },
        { label: 'Brasil', path: 'https://www.acciona.com.br/' },
        { label: culture === 'es' ? 'Oriente Medio' : 'Middle East', path: 'https://www.acciona-me.com/' },
        { label: culture === 'es' ? 'Noruega' : 'Norway', path: 'https://www.acciona.no/' }
    ];

    useStickyHeader(headerRef, stickyHeader);

    return (
        <>
            <header ref={headerRef} className="page-header grid" id="header">
                <a className="logo-home-link" href={logoLink}>
                    <img className="logo" src={LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                </a>

                <div className="header__row header__top">
                    <p className="stock-ticker">
                        <iframe className="ticker-iframe" scrolling="no" src={stockTicker} />
                    </p>
                    {/*
                    <div className="header-right">
                        <Button type="link" className="country-selector" onClick={openCountriesOverlay}>
                            <span>{labelCountries}</span>
                            <Plus width="10px" height="10px" viewBox="0 0 10 10" />
                        </Button>
                        <span className="clientes-touch">
                            <a className={`${path.includes(areaClientesLink) ? 'is-active' : ''}`} href={areaClientesLink}>
                                <User viewBox="0 1 17 17" />

                                {clientLabel}
                            </a>
                        </span>
                    </div>*/}


                    <nav className="nav_secondary" aria-label="menú de navegación secundaria" role="navigation">
                        <a className={`${path.includes(contactLink) ? 'is-active' : ''}`}
                            href={contactLink}
                            onClick={pushDataLayer}
                            data-menu-click-group="top-right"
                            data-menu-click-path={path} 
                            data-menu-click-target={contactLink}>
                            {contactLabel}
                        </a>
                        <a href={changeLanguageLink}
                            onClick={pushDataLayer}
                            data-changeLang="changeLang"
                            data-menu-click-path={path}
                            data-menu-click-group="top-right"
                            data-menu-click-target={changeLanguageLink}>
                            {changeLanguageLabel}
                        </a>
                        {/*<a className={`${path.includes(areaClientesLink) ? 'is-active' : ''}`} href={areaClientesLink}>
                            <User viewBox="0 1 17 17" />
                            {areaClientesLabel}
                        </a>*/}
                    </nav>

                    {/*<button className="open-search-form" onClick={openSearchOverlay} aria-label="open search form">
                        <SearchLense />
                    </button>*/}


                    <HamburgerMenuIcon className="icon icon__hamburger icon__hamburger--mobile" onClick={openMenu} />
                    <HamburgerMenuIconTablet className="icon icon__hamburger  icon__hamburger--tablet" onClick={openMenu} />

                </div>

                <div className="header__row header__bottom">
                    <nav
                        ref={desktopNav}
                        className="nav__main nav__main--desktop"
                        role="navigation"
                        aria-label="Navegación principal para escritorio"
                    >
                        <ul className="nav_links">
                            {menu.map((item, idx) => {
                                return (
                                    <a
                                        key={`nav_links__link-${idx}`}
                                        className={`link link-lvl-0${(culture === 'es' ? '/es/' + path.split("/")[2] + '/' : '/' + path.split("/")[1] + '/').includes(item.url) ? ' current' : ''}`}
                                        href={item.url}
                                        onMouseOut={notHover}
                                        onMouseOver={onHover}
                                        data-children={item.subItemsList.length > 0 ? "has-children" : "no"}
                                        data-section-id={culture === 'es' ? item.url.split("/")[2] : item.url.split("/")[1]}
                                        onClick={pushDataLayer}
                                        data-menu-click-path={path}
                                        data-menu-click-group="main"
                                        data-menu-click-target={item.url}
                                    >
                                        <span>{item.name}</span>
                                        {item.subItemsList.length > 0 &&
                                            <Chevron_L />
                                        }
                                    </a>
                                );
                            })}
                        </ul>
                    </nav>
                    <div data-is-visible={mobileIsOpen} className="nav__overlay"></div>
                    <nav
                        className="nav__main nav__main--mobile"
                        data-is-visible={mobileIsOpen}
                        role="navigation"
                        aria-label="Navegación principal para móvil"
                    >
                        <svg width="18" height="18" className="close-x" onClick={closeMenu}>
                            <path d="M10 2v6h6v1h-6v6H9V9H3V8h6V2h1z" fill="#1F140F" fillRule="evenodd"></path>
                        </svg>
                        <ul className="nav_links">
                            <a className={`link link-lvl-0${(culture === 'es' ? '/es/' + path.split("/")[2] + '/' : '/' + path.split("/")[1] + '/').includes(menu[0].url) ? ' current' : ''}`} href={menu[0].url}>
                                <span>{menu[0].name}</span>
                            </a>
                            <a className={`link link-lvl-0${(culture === 'es' ? '/es/' + path.split("/")[2] + '/' : '/' + path.split("/")[1] + '/').includes(menu[1].url) ? ' current' : ''}`} href={menu[1].url}>
                                <span>{menu[1].name}</span>
                            </a>
                            <button
                                type="link"
                                onClick={toggleMobileSubsection}
                                data-is-open="false"
                                data-section={culture === 'es' ? menu[2].url.split("/")[2] : menu[2].url.split("/")[1]}
                                className={`button taglabel button--md type__base subsection__toggle${path.includes(menu[2].url) ? ' active' : ''}`}
                            >
                                {menu[2].name}
                                <Chevron_L />
                            </button>
                            {menu[2].subItemsList.length > 0 &&
                                <div
                                    data-section={culture === 'es' ? menu[2].url.split("/")[2] : menu[2].url.split("/")[1]}
                                    className="nav_sub_section lvl-1"
                                    data-subsection-visible="false"
                                >
                                    {menu[2].subItemsList.map((subItem, idx) => {
                                        if (subItem.subItemsList.length > 0) {
                                            return (
                                                <>
                                                    <button
                                                        type="link"
                                                        onClick={toggleMobileChildSubsection}
                                                        data-section={culture === 'es' ? subItem.url.split("/")[3] : subItem.url.split("/")[2]}
                                                        className={`button taglabel button--md type__base subsection-child__toggle subsection__toggle${path.includes(subItem.url) ? ' active' : ''}`}
                                                    >
                                                        <span>
                                                            {subItem.name} <Chevron_M />
                                                        </span>
                                                    </button>
                                                    <div data-section={culture === 'es' ? subItem.url.split("/")[3] : subItem.url.split("/")[2]} className="nav_sub_section lvl-2" data-subsection-visible="false">
                                                        {subItem.subItemsList.map((subItemChild, idx) => {
                                                            return (
                                                                <a className={`link link-lvl-2${path === subItemChild.url ? ' current' : ''}`} href={subItemChild.url}>
                                                                    <span>{subItemChild.name}</span>
                                                                </a>
                                                            );
                                                        })}
                                                        <a className={`link link-lvl-1${path === subItem.url ? ' current' : ''}`} href={subItem.url}>
                                                            <span>{labelPortada} {subItem.name}</span>
                                                        </a>
                                                    </div>
                                                </>
                                            );

                                        }
                                        else {
                                            return (
                                                <a className={`link link-lvl-1${path === subItem.url ? ' current' : ''}`} href={subItem.url}>
                                                    <span>{subItem.name}</span>
                                                </a>
                                            );
                                        }
                                    })}
                                    <a className={`link link-lvl-0${path === menu[2].url ? ' current' : ''}`} href={menu[2].url}>
                                        <span>{labelPortada} {menu[2].name}</span>
                                    </a>
                                </div>
                            }
                            <a className={`link link-lvl-0${path.includes(menu[3].url) ? ' current' : ''}`} href={menu[3].url}>
                                <span>{menu[3].name}</span>
                            </a>
                        </ul>
                        <div className="nav__mobile-footer">
                            <div className="nav_secondary">
                                <a href={contactLink} className={`${path.includes(contactLink) ? 'is-active ' : ''}title--xs`}>
                                    {contactLabel}
                                </a>
                                <a href={changeLanguageLink} className="title--xs">
                                    {changeLanguageLabel}
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
                <>
                    <div ref={navDrawer} className="nav nav__drawer" data-section-active="" data-is-visible="false">
                        <nav
                            data-is-visible="false"
                            data-order="1"
                            className="nav_sub_section"
                            data-section={culture === 'es' ? menu[2].url.split("/")[2] : menu[2].url.split("/")[1]}
                            role="navigation"
                            aria-label={menu[2].name}
                        >
                            <div className="drawer__header grid">
                                <p className="drawer__header--title title--m">{menu[2].featuredPhrase}</p>
                                <a href={menu[2].url}
                                    title={menu[2].name}
                                    className="section-home-link"
                                    onClick={pushDataLayer}
                                    data-menu-click-path={path}
                                    data-menu-click-group="main"
                                    data-menu-click-target={menu[2].url}>
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                {menu[2].subItemsList.map((item, idx) => {
                                    if (item.subItemsList.length > 0) {
                                        return (
                                            <div className="subsection-box">
                                                <a className={`link${path === item.url ? ' current' : ''}`} href={item.url}>
                                                    <span>{item.name}</span>
                                                </a>
                                                {item.subItemsList.length > 0 && item.subItemsList.map((item, idx) => {
                                                    return (
                                                        <a className={`link link-lvl-1${path.includes(item.url) ? ' current' : ''}`}
                                                            href={item.url}
                                                            onClick={pushDataLayer}
                                                            data-menu-click-path={path}
                                                            data-menu-click-group="main"
                                                            data-menu-click-target={item.url}>
                                                            <span>{item.name}</span>
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }
                                })}
                                <div className="subsection-box">
                                    {menu[2].subItemsList.map((item, idx) => {
                                        if (item.subItemsList.length === 0) {
                                            return (
                                                <a className={`link${path.includes(item.url) ? ' current' : ''}`}
                                                    href={item.url}
                                                    onClick={pushDataLayer}
                                                    data-menu-click-path={path}
                                                    data-menu-click-group="main"
                                                    data-menu-click-target={item.url}>
                                                    <span>{item.name}</span>
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </nav>


                    </div>
                    <div
                        className="drawer-overlay"
                        onClick={toggleSubsectionVisibility}
                        onMouseOver={toggleSubsectionVisibility}
                    ></div>
                </>
            </header>
            <>
                {/*<div className={'bottom_drawer grid countries_overlay'} ref={countriesRef} data-is-visible="false">
                    <div className="header" ref={swipeTargetHeader}>
                        <h3 className="title title--xs">
                            {labelCountriesMenu}
                            <IconClose onClick={closeCountriesOverlay} />
                        </h3>
                    </div>
                    <div className="body">
                        {countriesList.map((country, idx) => (
                            <a className="title--m" href={country.path} target='_blank' key={`country-item-${idx}`}>
                                {country.label}
                                <IconExternal width="18" height="18" viewBox="0 0 18 18" />
                            </a>
                        ))}
                    </div>
                </div>*/}

                <div data-is-visible="false" className="module grid search_overlay" ref={searchRef}>
                    <div className="header">
                        <h3 className="title title--xs">
                            <IconClose onClick={closeSearchOverlay} />
                        </h3>
                    </div>
                    <div className="body">
                        <form className="search-input-wrapper" onSubmit={handleSearchSubmit}>
                            <input onChange={handleInput} className="search-input" name="search" ref={searchInput} placeholder={searchLabel} />
                            <button className="search-submit" type="submit">
                                <SearchLense />
                            </button>
                        </form>
                        <div className="search-input-test-stand" ref={searchInputTestStand}></div>
                    </div>
                </div>
            </>
        </>
    );
};

export default Header;
