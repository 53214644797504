import React, { useState, useEffect } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { useFade } from 'React/custom_hooks';
import { ReactComponent as IconArrowRight } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-24.svg';
import './style.scss';
import { TagTitle, Link } from 'React/components';

const TrabajaConNosotrosSimple = ({ title, className, cta, frames, linkOfferDetail, culture, labelDe, labelNoResults }) => {
	
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);
  useFade(moduleRef);

  const refList = frames.map((frame) => React.createRef());

  const onHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.remove('is-hovered');
  };

  const monthNames =
    culture === 'en'
      ? [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]
      : [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ];

  var dateFormat = '';
  const getDateFormat = (d) => {
    let date = new Date(d);
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    dateFormat = `${day} ${labelDe} ${month}, ${year}`;
  };

  return (
    <div ref={moduleRef} className={`module grid jobs-simple ${className}`}>
      {title && (
        <div className="jobs-header slow--y">
          <TagTitle style="slow--y" title={title} />

          {cta && (
            <Link
              className="section-cta slow--y mobile-hidden"
              type="arrow"
              path="#ofertas-de-empleo"
            >
              {cta}
            </Link>
          )}
        </div>
      )}
      <div className="jobs-container">
        {frames.length > 0 ? (
          frames.map((frame, idx) => {
            const {
              id,
              title,
              areaId,
              division,
              countryId,
              region,
              location,
              description,
              descriptionSummary,
              date,
              url,
              subtype,
            } = frame;
            getDateFormat(date);
            return (
              <div
                className="job-description slow--y grid"
                key={`data-point-${idx}`}
                ref={refList[idx]}
              >
                <div className="job-header slow--y">
                  <a
                    href={linkOfferDetail + id}
                    data-ref-id={idx}
                    onMouseOut={notHover}
                    onMouseOver={onHover}
                  >
                    <h2 className="title--s">{title}</h2>
                  </a>
                  <div className="job-subheader">
                    <span className="taglabel">
                      <a className="job-sector" title={division} href="#">
                        {division}
                      </a>
                    </span>
                    <span className="taglabel">
                      <a href="#" className="job-sector">
                        {region}
                      </a>
                    </span>
                    <span className="taglabel">
                      <IconCalendar />
                      {dateFormat}{' '}
                    </span>
                  </div>
                </div>
                <a
                  href={linkOfferDetail + id}
                  data-ref-id={idx}
                  onMouseOut={notHover}
                  onMouseOver={onHover}
                  className="job-body body--m slow--y"
                >
                  {descriptionSummary}
                  <div
                    href={linkOfferDetail + id}
                    data-ref-id={idx}
                    onMouseOut={notHover}
                    onMouseOver={onHover}
                    className="job-cta slow--y"
                  >
                    <IconArrowRight />
                  </div>
                </a>
              </div>
            );
          })
        ) : (
          <div className="no-offers body--m slow--y">
            <p>{labelNoResults}</p>
          </div>
        )}
      </div>
      {cta && (
        <Link
          className="section-cta mobile-visible slow--y"
          type="arrow"
          path="#ofertas-de-empleo"
        >
          {cta}
        </Link>
      )}
    </div>
  );
};

export default TrabajaConNosotrosSimple;
