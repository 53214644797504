import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { Link, Wysiwyg } from 'React/components';

import './Styles.scss';

const TextWithCta = ({ text, cta, download, showLogo, showCNMVLogo, showRegisterNumber, culture }) => {
    let moduleRef = React.createRef();
    useSlowFade(moduleRef);

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    }

    return (
        <div className="grid module module-textwithcta" ref={moduleRef}>
            <div className="grid-container">
                <div className="logos">
                    {showCNMVLogo &&
                        <a className="slow--y" href={culture === 'es' ? 'https://www.cnmv.es/portal/home.aspx?lang=es' : 'https://www.cnmv.es/portal/home.aspx?lang=en'} target="_blank"><span className="logo-cnmv" /></a>
                    }
                    {showLogo &&
                        <a className="slow--y" href="https://www.registradores.org/" target="_blank"><span className="logo-registradores" /></a>
                    }
                </div>
                {text &&
                    <div className="body--m slow--y" dangerouslySetInnerHTML={{ __html: text }} />
                }
                {download && (
                    <div className="mt-3 slow--y">
                        {download.umbracoFile &&
                            <Link type="download" path={download.umbracoFile} label={download.name} target={'_blank'} bytes={download.umbracoBytes} extension={download.umbracoExtension} />
                        }
                        {download.file &&
                            <Link type="download" path={download.file} label={showRegisterNumber ? download.recordNumber : download.title} target={'_blank'} extension='PDF' />
                        }

                    </div>
                )}
                {cta &&
                    <Link type={cta.type} className="mt-4 slow--y" label={cta.caption} path={cta.link} target={cta.target} />
                }
            </div>
        </div>
    );
};

export default TextWithCta;
