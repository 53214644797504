import React from 'react';
import { ReactComponent as IconCivil } from 'Icons/civil.svg';
import { ReactComponent as IconLeaf } from 'Icons/leaf.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-viento.svg';
import { ReactComponent as IconMovilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as IconAgua } from 'Icons/water-24.svg';
import { ReactComponent as IconSocial } from 'Icons/social-24.svg';
import { ReactComponent as IconInmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as IconCapital } from 'Icons/capital-24.svg';
import { ReactComponent as IconIndustrial } from 'Icons/industrial-24.svg';
import { ReactComponent as IconOtros } from 'Icons/otrosnegocios-24.svg';
import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';
import { ReactComponent as EconomiaCircularIcon } from 'Icons/soluciones/economiacircular.svg';


import { ReactComponent as AreaTermosolarIcon } from 'Icons/Tecno/V1/24x24_Termosolar.svg';
import { ReactComponent as AreaAlmacenamientoIcon } from 'Icons/Tecno/V1/24x24_Almacenamiento.svg';
import { ReactComponent as AreaBiomasaIcon } from 'Icons/Tecno/V1/24x24_Biomasa.svg';
import { ReactComponent as AreaEolicaMarinaIcon } from 'Icons/Tecno/V1/24x24_Eolica marina.svg';
import { ReactComponent as AreaEolicaIcon } from 'Icons/Tecno/V1/24x24_Eolica.svg';
import { ReactComponent as AreaFotovoltaicaIcon } from 'Icons/Tecno/V1/24x24_Fotovoltaica.svg';
import { ReactComponent as AreaHidroelectricaIcon } from 'Icons/Tecno/V1/24x24_Hidroelectrica.svg';

const iconsMap = {
    Energia: <EnergyIcon height="18" width="18" viewBox="0 0 24 24" />,
    Termosolar: <AreaTermosolarIcon height="18" width="18" viewBox="0 0 24 24" />,
    Almacenamiento: <AreaAlmacenamientoIcon height="18" width="18" viewBox="0 0 24 24" />,
    Biomasa: <AreaBiomasaIcon height="18" width="18" viewBox="0 0 24 24" />,
    EolicaMarina: <AreaEolicaMarinaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Eolica: <AreaEolicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Fotovoltaica: <AreaFotovoltaicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Hidroelectrica: <AreaHidroelectricaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Ciudades: <CiudadesIcon height="18" width="18" viewBox="0 0 24 24" />,
    MedioAmbiente: <IconLeaf height="18" width="18" viewBox="0 0 24 24" />,
    Transporte: <MovilidadIcon height="18" width="18" viewBox="0 0 24 24" />,
    Agua: <AguaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Social: <SocialIcon height="18" width="18" viewBox="0 0 24 24" />,
    Inmobiliaria: <InmobiliariaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Economiacircular: <EconomiaCircularIcon height="18" width="18" viewBox="0 0 24 24" />,
    Capital: <CapitalIcon height="18" width="18" viewBox="0 0 24 24" />,
    Industrial: <IconIndustrial height="18" width="18" viewBox="0 0 24 24" />,
    Otros: <IconOtros height="18" width="18" viewBox="0 0 24 24" />,

    WindPower: <AreaEolicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Photovoltaic: <AreaFotovoltaicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Hydropower: <AreaHidroelectricaIcon height="18" width="18" viewBox="0 0 24 24" />,
    CSP: <AreaTermosolarIcon height="18" width="18" viewBox="0 0 24 24" />,
    Biomass: <AreaBiomasaIcon height="18" width="18" viewBox="0 0 24 24" />,



}

const iconsMapLarge = {
    Energia: <EnergyIcon />,
    Termosolar: <AreaTermosolarIcon />,
    Almacenamiento: <AreaAlmacenamientoIcon />,
    Biomasa: <AreaBiomasaIcon />,
    EolicaMarina: <AreaEolicaMarinaIcon />,
    Eolica: <AreaEolicaIcon />,
    Fotovoltaica: <AreaFotovoltaicaIcon />,
    Hidroelectrica: <AreaHidroelectricaIcon />,
    Ciudades: <CiudadesIcon />,
    MedioAmbiente: <IconLeaf />,
    Transporte: <MovilidadIcon />,
    Agua: <AguaIcon />,
    Social: <SocialIcon />,
    Inmobiliaria: <InmobiliariaIcon />,
    Capital: <CapitalIcon />,
    Industrial: <IconIndustrial />,
    Otros: <IconOtros />,

    WindPower: <AreaEolicaIcon />,
    Photovoltaic: <AreaFotovoltaicaIcon />,
    Hydropower: <AreaHidroelectricaIcon />,
    CSP: <AreaTermosolarIcon />,
    Biomass: <AreaBiomasaIcon />,


};

export { iconsMap, iconsMapLarge };