import React, { useEffect, useState } from 'react';
import { TweenMax, TimelineMax } from 'gsap/gsap-core';

import { Link, TagTitle } from 'React/components';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import IconsMap from './_iconsMap';
import { ReactComponent as IconArrowLong } from 'Icons/ArrowLong.svg';

import { useObserver } from 'React/custom_hooks';

import './style.scss';


const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};



const ModuleNews = ({ tagLine, content, moduleCta, type, culture, handleLegalLightboxClick, restyling, slider, style, tipo, emptyMessage }) => {


    useEffect(() => {
        const divData = document.getElementById('div-data');
        var message = document.getElementById("message");

        if ((style && style.length === 0) && tipo === 'CIP') {
            message.innerText = emptyMessage;
            divData.style.display = 'none';
        //} else if (tipo === 'OIR') {
        //    message.innerText = emptyMessage;
        //    divData.style.display = 'none';
        } else {
            message.innerText = "";
            divData.style.display = 'grid';
        }

    }, [style]);

    const [carousel, setCarousel] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const refSliderBar = React.createRef();

    const minSwipeDistance = 50;
    //const onTouchStart = (e) => {
    //  setTouchEnd(null)
    //  setTouchStart(e.targetTouches[0].clientX)
    //}

    //const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    //const onTouchEnd = () => {
    //  if (!touchStart || !touchEnd) return
    //  const distance = touchStart - touchEnd
    //  const isLeftSwipe = distance > minSwipeDistance
    //  const isRightSwipe = distance < -minSwipeDistance
    //  const index = Math.ceil(newsCol.length / refSliderBar.current.querySelectorAll('.module-news__slider-list-element').length);
    //  if (isLeftSwipe && carousel < newsCol.length - index) {
    //    setCarousel(carousel + index);
    //  } else if (isRightSwipe && carousel > 0) {
    //    setCarousel(carousel - index);
    //  }
    //}

    //const getPosition = (e) => {
    //  const eventDoc = (e.target && e.target.ownerDocument) || document;
    //  const doc = eventDoc.documentElement;
    //  const body = eventDoc.body;

    //  return e.clientX +
    //    (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
    //    (doc && doc.clientLeft || body && body.clientLeft || 0);
    //}

    //const onMouseDown = (e) => {
    //  setTouchEnd(null)
    //  setTouchStart(getPosition(e));
    //}

    //const onMouseUp = (e) => {
    //  const posX = getPosition(e);

    //  if (!touchStart) return
    //  const distance = touchStart - posX
    //  const isLeftSwipe = distance > minSwipeDistance
    //  const isRightSwipe = distance < -minSwipeDistance
    //  const index = Math.ceil(newsCol.length / refSliderBar.current.querySelectorAll('.module-news__slider-list-element').length);
    //  if (isLeftSwipe && carousel < newsCol.length - index) {
    //    setCarousel(carousel + index);
    //  } else if (isRightSwipe && carousel > 0) {
    //    setCarousel(carousel - index);
    //  }
    //}

    const newsCol = content.map((item, idx) => {

        if (item) {
            const refList = content.map((item) => React.createRef());

            if (typeof window !== 'undefined') {
                //const [observer, setElements, entries] = useObserver({
                //    root: null,
                //    threshold: 0.25,
                //});

                //const tl = new TimelineMax({ delay: 0, repeat: 0 });

                //useEffect(() => {
                //    // setting elements to observe
                //    const elements = document.querySelectorAll('.module-news .slow--y');
                //    TweenMax.set(elements, { opacity: 0, y: 90 });
                //    setElements(elements);
                //}, [setElements]);

                //useEffect(() => {
                //    entries.forEach((entry) => {
                //        if (entry.isIntersecting) {
                //            let lazyItem = entry.target;
                //            tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
                //            observer.unobserve(lazyItem);
                //        }
                //    });
                //}, [entries, observer, TimelineMax]);
            }

            const onHover = (event) => {
                const { refId } = event.currentTarget.dataset;
                refList[parseInt(refId)].current.classList.add('is-hovered');
            };

            const notHover = (event) => {
                const { refId } = event.currentTarget.dataset;
                refList[parseInt(refId)].current.classList.remove('is-hovered');
            };

            const maxTextLength = 100;

            if (type === 'hechos') {

                const { title, file, date, thumbnail, recordNumber, CNMVType, popUpLegal, legalPopup } = item;


                const monthNames = culture === 'es' ? ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
                ] : ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                var dateObj = new Date(date);
                var day = dateObj.getDate();
                var year = dateObj.getFullYear();
                var month = monthNames[dateObj.getMonth()];

                return (
                    <>
                        {restyling && slider ? (
                            <div style={{ width: `${100 / content.length}%` }} className={`module-news__element ${(useWindowWidth() >= 680 && carousel + 1 === idx) || (carousel === idx) ? 'module-news__element--visible' : ''}`}>
                                <div className="module-news__col">
                                    {title && (!popUpLegal && (!legalPopup || legalPopup === '[null]' || legalPopup === '[]')) && (
                                        <a href={file ? file : ''} target={file ? '_blank' : ''} className="link" title={title}>
                                            <h2 className="title--s">{title}</h2>
                                        </a>
                                    )}
                                    {title && (popUpLegal || (legalPopup && legalPopup !== '[null]' && legalPopup !== '[]')) && (
                                        <a href="#" className="link" title={title} onClick={() => handleLegalLightboxClick(true, file, legalPopup ? legalPopup : "Popup-energia")}>
                                            <h2 className="title--s">{title}</h2>
                                        </a>
                                    )}
                                    {date && year !== 1 &&
                                        <div className="tag title--small" >
                                            <span className="date-calendar">
                                                <IconCalendar />
                                                {`${day} ${month} ${year}`}
                                            </span>
                                        </div>
                                    }
                                    {recordNumber && file && (!popUpLegal && (!legalPopup || legalPopup === '[null]' || legalPopup === '[]')) &&
                                        <div className="">
                                            <Link type="download" label={culture === 'es' ? `Registro: ${recordNumber}` : `Register: ${recordNumber}`} path={file} target="_blank" />
                                        </div>
                                    }
                                    {recordNumber && file && (popUpLegal || (legalPopup && legalPopup !== '[null]' && legalPopup !== '[]')) &&
                                        <div className="">
                                            <Link type="legalPopup" label={culture === 'es' ? `Registro: ${recordNumber}` : `Register: ${recordNumber}`} onClickFunction={() => handleLegalLightboxClick(true, file, legalPopup ? legalPopup : "Popup-energia")} />
                                        </div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="module-news__col">
                                {title && (!popUpLegal && (!legalPopup || legalPopup === '[null]' || legalPopup === '[]')) && (
                                    <a href={file ? file : ''} target={file ? '_blank' : ''} className="link slow--y" title={title}>
                                        <h2 className="title--s">{title}</h2>
                                    </a>
                                )}
                                {title && (popUpLegal || (legalPopup && legalPopup !== '[null]' && legalPopup !== '[]')) && (
                                    <a href="#" className="link slow--y" title={title} onClick={() => handleLegalLightboxClick(true, file, legalPopup ? legalPopup : "Popup-energia")}>
                                        <h2 className="title--s">{title}</h2>
                                    </a>
                                )}
                                {date && year !== 1 &&
                                    <div className="tag title--small slow--y">
                                        <span className="date-calendar">
                                            <IconCalendar />
                                            {`${day} ${month} ${year}`}
                                        </span>
                                    </div>
                                }
                                {recordNumber && file && (!popUpLegal && (!legalPopup || legalPopup === '[null]' || legalPopup === '[]')) &&
                                    <div className="slow--y">
                                        <Link type="download" label={culture === 'es' ? `Registro: ${recordNumber}` : `Register: ${recordNumber}`} path={file} target="_blank" />
                                    </div>
                                }
                                {recordNumber && file && (popUpLegal || (legalPopup && legalPopup !== '[null]' && legalPopup !== '[]')) &&
                                    <div className="slow--y">
                                        <Link type="legalPopup" label={culture === 'es' ? `Registro: ${recordNumber}` : `Register: ${recordNumber}`} onClickFunction={() => handleLegalLightboxClick(true, file, legalPopup ? legalPopup : "Popup-energia")} />
                                    </div>
                                }
                            </div>
                        )}
                    </>
                );
            } else {
                const { title, soluciones, tag, date, intro, link } = item;

                return (
                    <>
                        {restyling && slider ? (
                            <div style={{ width: `${100 / content.length}%` }} className={`module-news__element ${(useWindowWidth() >= 680 && carousel + 1 === idx) || (carousel === idx) ? 'module-news__element--visible' : ''}`}>
                                <div className="module-news__col" key={`news-${idx}`} ref={refList[idx]}>
                                    {title && (
                                        <a href={link} className="link module-news__col__title-link" title={title} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                                            <h2 className="title--s">{title}</h2>
                                        </a>
                                    )}

                                    {date && <div className='container-icon-date' ><span className="date title--small">{date}</span></div>}


                                    {soluciones &&
                                        <div className="tag title--small" key={`tag-${idx}`}>
                                            {date && <span className="date">{date}</span>}
                                        </div>
                                    }
                                    {intro &&
                                        <div>
                                            <div className="body--m" dangerouslySetInnerHTML={{ __html: intro.length < maxTextLength ? intro : `${intro.substr(0, maxTextLength)}...` }}></div>
                                        </div>
                                    }
                                    {link &&
                                        <Link type="arrowLong" className="arrow module-news__block-link" path={link} />
                                    }

                                </div>
                            </div>
                        ) : (
                            <div className="module-news__col slow--y" key={`news-${idx}`} ref={refList[idx]}>
                                {title && (
                                    <a href={link} className="link module-news__col__title-link" title={title} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                                        <h2 className="title--s">{title}</h2>
                                    </a>
                                )}


                                {date && <div className='container-icon-date' ><span className="date title--small">{date}</span></div>}


                                {soluciones &&
                                    <div className="tag title--small" key={`tag-${idx}`}>
                                        {soluciones.slice(0, 2).map((solution, idx) => {
                                            return (
                                                <>
                                                    {!restyling &&
                                                        <a href={solution.url} key={`solution-tag-${idx}`} className="link icon--red" title={solution.name}>
                                                            {solution.icon && IconsMap[solution.icon]}
                                                            {solution && <span>{solution.name}</span>}
                                                        </a>
                                                    }
                                                    {idx === 0 && soluciones.length > 1 && <span>,&nbsp;</span>}
                                                </>

                                            );
                                        })}
                                        {date && <span className="date">{date}</span>}
                                    </div>
                                }
                                {intro &&
                                    <div>
                                        <div className="body--m" dangerouslySetInnerHTML={{ __html: intro.length < maxTextLength ? intro : `${intro.substr(0, maxTextLength)}...` }}></div>
                                    </div>
                                }
                                {link &&
                                    <Link type="arrowLong" className="arrow module-news__block-link" path={link} />
                                }

                            </div>
                        )}
                    </>
                );
            }
        }
    });

    return (
        <>
            <section className={`module module-news grid ${restyling ? 'module-news--restyling' : ''} ${slider ? 'module-news--slider' : ''}`}>
                {tagLine && (
                    <div className="module-news__header slow--y">
                        <TagTitle title={tagLine} />
                        <Link type="arrow" label="Ver todas las noticias" />
                    </div>
                )}

                {restyling && slider && newsCol.length > 1 && (
                    <div ref={refSliderBar} className="module-news__slider slow--y">
                        <div className="module-news__slider-bar" aria-hidden={true}>
                            <ul className="module-news__slider-list">
                                {newsCol.map((item, index) =>
                                    useWindowWidth() < 680 ? (
                                        <li className='module-news__slider-list-element' style={{ width: `${100 / newsCol.length}%` }}>
                                            <button type='button' className='module-news__slider-list-button' onClick={() => setCarousel(index)} disabled={carousel === index}>
                                                Ir al elemento {index} del slider
                                            </button>
                                        </li>
                                    ) : (index % 2 === 0 &&
                                        <li className='module-news__slider-list-element' style={{ width: `${100 / newsCol.length * 2}%` }}>
                                            <button type='button' className='module-news__slider-list-button' onClick={() => setCarousel(index)} disabled={carousel === index}>
                                                Ir al elemento {index} del slider
                                            </button>
                                        </li>
                                    )
                                )}
                            </ul>
                            <span className="module-news__slider-bar--active" aria-hidden={true} style={{ width: `${(100 / (newsCol.length % 2 === 0 ? newsCol.length : newsCol.length + 1)) * (useWindowWidth() < 680 ? 1 : 2)}%`, left: `${(100 / (newsCol.length % 2 === 0 ? newsCol.length : newsCol.length + 1)) * carousel}%` }}></span>
                        </div>
                    </div>
                )}
                <p className='message-no-content' id="message"></p>

                {restyling && slider ? (
                    <div className={`${restyling && slider ? 'slow--y' : ''}`} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                        <div className="grid-container content-wrapper" style={restyling && slider ? { left: `-${carousel * 100 * (useWindowWidth() < 680 ? 1 : 0.5)}%`, width: `${100 * newsCol.length * (useWindowWidth() < 680 ? 1 : 0.5)}%` } : {}}>{newsCol}</div>
                    </div>
                ) : (
                    <div id='div-data' className="grid-container content-wrapper">{newsCol}</div>
                )}


                {moduleCta && (
                    <div className={`cta-footer ${restyling && slider ? 'slow--y' : ''}`}>
                        <Link type="arrow" label={moduleCta.caption} path={moduleCta.link} />
                    </div>
                )}
            </section>
        </>
    );
};

export default ModuleNews;
