import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'React/components';
import { TagTitle } from 'React/components';
import IconsMap from './_iconsMap';
import { useSlowFade } from 'React/custom_hooks';
import ReactPlayer from 'react-player';

import { ReactComponent as VideoPlay } from 'Icons/video_play-white.svg';
import { ReactComponent as Cross } from 'Icons/cross-32.svg';

import './styles.scss';

const DistribuidorDestacado = ({
    href,
    logo,
    title,
    text,
    image,
    ods,
    download,
    solutions,
    tagTitle,
    anchor,
    restyling,
    videoId
}) => {
    let moduleRef = React.createRef();
    let imgRef = useRef();
    const [ video, setVideo ] = useState(undefined);
    const [ imageWidth, setImageWidth  ] = useState(0);

    useSlowFade(moduleRef);

    if (typeof window !== 'undefined') {
        useEffect(() => {
            function callback(entries, observer) {
                entries.forEach((el) => {
                    if (el.isIntersecting == true) {
                        el.target.classList.add('is-visible');
                    }
                });
            }

            // Instancing a new IntersectionObserver
            const observer = new IntersectionObserver(callback);

            // Adding a target to be observed
            observer.observe(moduleRef.current);
        });
    }

    const openVideo = (id) => {
        setVideo({ class: 'module-distribuidor-destacado__video-container--opening' });

        setTimeout(() => {
            setVideo({ id });
        }, 600);
    }

    const closeVideo = () => {
        setVideo({ ...video, class: 'module-distribuidor-destacado__video-container--closing' });

        setTimeout(() => {
            setVideo(undefined);
        }, 400);
    }

    const imgWidth = () => setImageWidth(`${imgRef.current.getBoundingClientRect().right - Math.max(imgRef.current.getBoundingClientRect().left, 0)}px`);

    useEffect(() => {
        if (imgRef.current) {
            setTimeout(() => imgWidth(), 500);
            window.removeEventListener('resize', imgWidth);
            window.addEventListener('resize', imgWidth);
        }
    }, []);

    return (
        <section ref={moduleRef} className={`module grid module-distribuidor-destacado grid-flow--left single ${restyling ? 'module-distribuidor-destacado--restyling' : ''}`}>
            <div className="grid-container--left">
                <div ref={imgRef} className="image grid-container--left" style={{ backgroundImage: `url(${image ? image.url : ''})` }}>
                    { videoId &&
                        <button type='button' onClick={() => openVideo(videoId)} className={`module-distribuidor-destacado__video-button ${imageWidth !== 0 ? 'module-distribuidor-destacado__video-button--visible' : ''}`} style={{ width: imageWidth }}>
                            <VideoPlay />
                        </button>
                    }
                    {ods && <img src={ods.url} alt={ods.alt ? ods.alt : ''} title={ods.title} className="ods" />}
                    {logo && <img src={logo.url} alt={logo.alt ? logo.alt : ''} title={logo.title} className="logo" />}
                </div>
            </div>
            <div className={`grid-container--right`}>
                {tagTitle ? <TagTitle style="slow--y" title={tagTitle} /> : null}

                {title ? <h2 className="title--l slow--y">{title}</h2> : null}
                <span className="custom-anchor" id={anchor} />
                {text ? (
                    <div className="mt-2">
                        <p className="body--m slow--y">{text}</p>
                    </div>
                ) : null}

                {solutions && (
                    <div className="soluciones-destacado slow--y">
                        <ul className="grid-container">
                            {solutions.map((solution, idx) => {
                                const { icon, menuText, _contentPath } = solution;

                                return (
                                    <li className="title--xs"><a href={_contentPath}>{IconsMap[icon]}{menuText}</a></li>
                                );
                            })}
                        </ul>
                    </div>
                )}

                {download && (
                    <div className="mt-3 slow--y">
                        <Link type="download" path={download.umbracoFile ? download.umbracoFile : download.file} label={download.name ? download.name : download.title} target={'_blank'} bytes={download.umbracoBytes} extension={download.umbracoExtension} />
                    </div>
                )}

                {href && (
                    <div className="mt-3 slow--y">
                        <Link type={href.type} path={href.link} target={href.target} label={href.caption} module="distribuidor" title={title ? title : ''} />
                    </div>
                )}
            </div>
            { video &&
                <div className={`module-distribuidor-destacado__video-container ${video && video.class ? video.class : ''}`}>
                    <div className='grid module-distribuidor-destacado__video-grid'>
                        <button type='button' className='module-distribuidor-destacado__video-close' onClick={() => closeVideo()}>
                            <span>Cerrar vídeo</span>
                            <Cross />
                        </button>
                        { video.id &&
                            <ReactPlayer
                                width="100%"
                                url={`//www.youtube.com/watch?v=${video.id}`}
                                playing={true}
                                controls
                                className='module-distribuidor-destacado__video'
                            />
                        }
                    </div>
                </div>
            }
        </section>
    );
}

export default DistribuidorDestacado;
