import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

import { useSlowFade } from "React/custom_hooks";

import { ReactComponent as IconArrowLong } from "Icons/ArrowLong.svg";
import { ReactComponent as VideoPlay } from "Icons/video_play-white.svg";
import { ReactComponent as Cross } from "Icons/cross-32.svg";
import { ReactComponent as GoLeft } from "Icons/left.svg";
import { ReactComponent as GoRight } from "Icons/right.svg";

import "./style.scss";

const CarruselMediateca = ({ title, items }) => {
  const moduleRef = React.createRef();
  const gridRef = useRef();
  const [sizes, setSizes] = useState({ margin: 0, padding: 0 });
  const [carousel, setCarousel] = useState({
    slide: 0,
    leftPercentage: 0,
    leftMargin: 0,
  });
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [lightbox, setLightbox] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [zoom, setZoom] = useState(undefined);

  useSlowFade(moduleRef);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const callback = (entries) => {
        entries.forEach((el) => {
          if (el.isIntersecting) {
            el.target.classList.add("is-visible");
          }
        });
      };

      // Instancing a new IntersectionObserver
      const observer = new IntersectionObserver(callback);

      // Adding a target to be observed
      observer.observe(moduleRef.current);
    }
  }, []);

  const moveCarouselLeft = () => {
    if (carousel.slide > 0) {
      const newSlide = carousel.slide - 1;
      setVideo(undefined);
      setCarousel({
        slide: newSlide,
        leftPercentage: (newSlide * 100) / items.length,
        leftMargin: (sizes.margin / items.length) * newSlide,
      });
    }
  };

  const moveCarouselRight = () => {
    if (carousel.slide < items.length - 1) {
      const newSlide = carousel.slide + 1;
      setVideo(undefined);
      setCarousel({
        slide: newSlide,
        leftPercentage: (newSlide * 100) / items.length,
        leftMargin: (sizes.margin / items.length) * newSlide,
      });
    }
  };

  const moveCarouselTo = (index) => {
    setVideo(undefined);
    setCarousel({
      slide: index,
      leftPercentage: (index * 100) / items.length,
      leftMargin: (sizes.margin / items.length) * index,
    });
  };

  const onImgLoad = ({ target }) => {
    const { offsetHeight, offsetWidth } = target;
    target.classList.add(
      offsetHeight > offsetWidth
        ? "module-carrusel-mediateca__image--vertical"
        : "module-carrusel-mediateca__image--horizontal"
    );
  };

  const widthChange = (mq) => {
    if (mq.matches === true) {
      setSizes({ margin: 24, padding: 55 });
    } else {
      setSizes({ margin: 12, padding: 60 });
    }
  };

  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe && carousel.slide < items.length - 1) {
      moveCarouselRight();
    } else if (isRightSwipe && carousel.slide > 0) {
      moveCarouselLeft();
    }
  };

  const getPosition = (e) => {
    const eventDoc = (e.target && e.target.ownerDocument) || document;
    const doc = eventDoc.documentElement;
    const body = eventDoc.body;

    return (
      e.clientX +
      ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
      ((doc && doc.clientLeft) || (body && body.clientLeft) || 0)
    );
  };

  const onMouseDown = (e) => {
    setTouchEnd(null);
    setTouchStart(getPosition(e));
  };

  const onMouseUp = (e) => {
    const posX = getPosition(e);

    if (!touchStart) return;
    const distance = touchStart - posX;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe && carousel.slide < items.length - 1) {
      moveCarouselRight();
    } else if (isRightSwipe && carousel.slide > 0) {
      moveCarouselLeft();
    }
  };

  const openVideo = (id) => {
    setVideo(id);
    if (!lightbox) openLightbox();
  };

  const openLightbox = () => {
    setLightbox({ class: "module-carrusel-mediateca--fullwidth-opening" });
    setTimeout(() => {
      setLightbox({ class: "module-carrusel-mediateca--fullwidth" });
    }, 420);
  };

  const closeLightbox = () => {
    setLightbox({
      class:
        "module-carrusel-mediateca--fullwidth module-carrusel-mediateca--fullwidth-closing",
    });
    setTimeout(() => {
      setLightbox({ class: "module-carrusel-mediateca--fullwidth-closing" });
    }, 500);
    setTimeout(() => {
      setVideo(undefined);
      setLightbox(undefined);
    }, 1020);
  };

  const handleResize = () => {
    if (gridRef.current.clientWidth > 680)
      setSizes({ margin: 24, padding: 55 });
    else setSizes({ margin: 12, padding: 60 });
  };

  useEffect(() => {
    if (sizes.margin === 0 && gridRef) handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [gridRef]);

  return (
    <section
      ref={moduleRef}
      className={`module module-carrusel-mediateca is-visible ${lightbox && lightbox.class ? lightbox.class : ""
        }`}
    >
      <div className="grid" ref={gridRef}>
        {lightbox && (
          <div className="module-carrusel-mediateca__close-button-container">
            <button
              type="button"
              className="module-carrusel-mediateca__close-button"
              onClick={() => closeLightbox()}
            >
              <Cross />
            </button>
          </div>
        )}
        <div className="module-carrusel-mediateca__title slow--y">
          <h2 className="title--xs">{title}</h2>
        </div>
        <ul
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          className="module-carrusel-mediateca__list"
          style={{
            width: `calc(${items.length * 100}% + ${sizes.margin * (items.length - 1)
              }px)`,
            transform: `translateX(-${carousel.leftPercentage}%) translateX(-${carousel.leftMargin}px)`,
          }}
        >
          {items.map((el, index) => (
            <li
              className={`module-carrusel-mediateca__element ${carousel.slide === index
                ? "module-carrusel-mediateca__element--active"
                : ""
                }`}
              style={{
                width: `calc((100% - ${(items.length - 1) * sizes.margin
                  }px) / ${items.length})`,
                paddingBottom: `${sizes.padding / items.length}%`,
              }}
            >
              <picture
                className={`module-carrusel-mediateca__picture ${el.videoId ? "module-carrusel-mediateca__picture--veil" : ""
                  }`}
                onClick={() =>
                  lightbox ? setZoom(el.url || el.thumbnail) : undefined
                }
              >
                <img
                  onLoad={onImgLoad}
                  src={el.url || el.thumbnail}
                  alt={el.Alt || el.title}
                  className="module-carrusel-mediateca__image"
                />
              </picture>
              {lightbox && el.videoId && video === el.videoId && (
                <ReactPlayer
                  width="80%"
                  height="140%"
                  url={`//www.youtube.com/watch?v=${video}`}
                  playing={true}
                  controls
                  className="module-carrusel-mediateca__picture"
                />
              )}
              {el.videoId && video !== el.videoId ? (
                <button
                  type="button"
                  className="module-carrusel-mediateca__fullwidth-button"
                  onClick={() => {
                    moveCarouselTo(index);
                    openVideo(el.videoId);
                  }}
                >
                  <VideoPlay />
                </button>
              ) : (
                !lightbox && (
                  <button
                    type="button"
                    className="module-carrusel-mediateca__fullwidth-button"
                    onClick={() => {
                      moveCarouselTo(index);
                      openLightbox();
                    }}
                  >
                    Abrir mediateca
                  </button>
                )
              )}
            </li>
          ))}
        </ul>
        {items.length > 1 && (
          <div className={`module-carrusel-mediateca__slider slow--y ${lightbox ? 'module-carrusel-mediateca__slider-onplayvideo' : ''}`} >
            <div
              className="module-carrusel-mediateca__slider-bar"
              aria-hidden={true}
            >
              <ul className="module-carrusel-mediateca__slider-list">
                {items.map((item, index) => (
                  <li
                    className="module-carrusel-mediateca__slider-list-element"
                    style={{ width: `${100 / items.length}%` }}
                  >
                    <button
                      type="button"
                      className="module-carrusel-mediateca__slider-list-button"
                      onClick={() => moveCarouselTo(index)}
                      disabled={carousel.slide === index}
                    >
                      Ir al elemento {index} del slider
                    </button>
                  </li>
                ))}
              </ul>
              <span
                className="module-carrusel-mediateca__slider-bar--active"
                aria-hidden={true}
                style={{
                  width: `${100 / items.length}%`,
                  left: `${(100 / items.length) * carousel.slide}%`,
                }}
              ></span>
            </div>
            {!lightbox ? (
              <>
                <button
                  type="button"
                  className={`module-carrusel-mediateca__slider-button module-carrusel-mediateca__slider-button--left ${carousel.slide === 0
                    ? "module-carrusel-mediateca__slider-button--disabled"
                    : ""
                    }`}
                  onClick={moveCarouselLeft}
                >
                  <IconArrowLong />
                </button>
                <button
                  type="button"
                  className={`module-carrusel-mediateca__slider-button ${carousel.slide === items.length - 1
                    ? "module-carrusel-mediateca__slider-button--disabled"
                    : ""
                    }`}
                  onClick={moveCarouselRight}
                >
                  <IconArrowLong />
                </button>
              </>
            ) : (
              <div className="module-carrusel-mediateca__slider-counter">
                <span className="module-carrusel-mediateca__slider-counter-cypher">
                  {("0" + (carousel.slide + 1)).slice(-2)}
                </span>
                <span className="module-carrusel-mediateca__slider-counter-cypher">
                  {("0" + items.length).slice(-2)}
                </span>
              </div>
            )}
          </div>
        )}
        {lightbox && (
          <>
            <button
              type="button"
              className={`module-carrusel-mediateca__slider-button module-carrusel-mediateca__slider-button--zoom module-carrusel-mediateca__slider-button--left ${carousel.slide === 0
                ? "module-carrusel-mediateca__slider-button--disabled"
                : ""
                }`}
              onClick={moveCarouselLeft}
            >
              <GoLeft />
            </button>
            <button
              type="button"
              className={`module-carrusel-mediateca__slider-button module-carrusel-mediateca__slider-button--zoom ${carousel.slide === items.length - 1
                ? "module-carrusel-mediateca__slider-button--disabled"
                : ""
                }`}
              onClick={moveCarouselRight}
            >
              <GoRight />
            </button>
          </>
        )}
        {zoom && (
          <picture
            className={`module-carrusel-mediateca__picture module-carrusel-mediateca__picture--zoom`}
            onClick={() => setZoom(false)}
          >
            <img
              src={zoom}
              className="module-carrusel-mediateca__image module-carrusel-mediateca__image--zoom"
            />
          </picture>
        )}
      </div>
    </section>
  );
};

export default CarruselMediateca;
