import React, { useState, useEffect } from 'react';
import { useSlowFade, useFade } from 'React/custom_hooks';
import { TagTitle, Link, ProjectsMapStatic, Wysywyg, EnlaceAgrupador, Lightbox, ContactFormGreenEnergy } from 'React/components';
import { rndKeyGen } from 'React/helpers';
import { ReactComponent as DownloadIcon } from 'Icons/download.svg';

import './styles.scss';

const BloqueTextoH1 = ({ model }) => {
    const { tagLine,
        title,
        leadText,
        leadTextColumnRight,
        leadTextColumnLeft,
        mainTextColumnLeft,
        mainTextColumnRight,
        leadSmallText,
        mainText,
        children,
        list,
        listType,
        downloads,
        moduleCta,
        groupLink,
        anchor,
        lightboxLinkText } = model;
    const leadTextClass = leadText ? 'with-lead-text' : '';

    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [isHome, setIsHome] = useState(false)

    const handleLightboxClick = () => {
        setVisibleLightbox(true);
    };

    const moduleRef = React.createRef();

    useSlowFade(moduleRef);
    useFade(moduleRef);
    useEffect(() => {
        const path = window.location.pathname;
        (path === '/es/' || path === '' || path === '/') ? setIsHome(true) : null
    });

    return (
        <div ref={moduleRef} className={`module grid bloque-texto ${leadTextClass} ${isHome ? 'isHome' : ''}`}>
            {tagLine && <TagTitle title={tagLine} />}

            <div className="title ">
                <h1 className="title--l slow">{title}</h1>
            </div>

            {leadText && (
                <div className="lead-text-container">
                    <div className="lead-text body--l slow" dangerouslySetInnerHTML={{ __html: leadText }}></div>

                    {leadSmallText && <div dangerouslySetInnerHTML={{ __html: leadSmallText }} className="lead-small-text mt-2 body--m slow"></div>}
                </div>
            )}
            <span className="custom-anchor" id={anchor} />
            {mainText && (
                <div className="main-text">
                    <div className="body--m slow main-text-paragraph" dangerouslySetInnerHTML={{ __html: mainText }}></div>
                    {children}
                </div>
            )}

            {mainTextColumnRight && (
                <div className="main-text main-text--columns grid full-width">
                    <div className="main-text-paragraph">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: leadTextColumnLeft }}></div>
                        <div className="body--m slow" dangerouslySetInnerHTML={{ __html: mainTextColumnLeft }}></div>
                    </div>
                    <div className="main-text-paragraph">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: leadTextColumnRight }}></div>
                        <div className="body--m slow" dangerouslySetInnerHTML={{ __html: mainTextColumnRight }}></div>
                    </div>
                </div>
            )}

            {leadTextColumnRight && !mainTextColumnRight && (
                <div className="main-text main-text--lead grid full-width">
                    <div className="main-text-paragraph">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: leadTextColumnLeft }}></div>
                    </div>
                    <div className="main-text-paragraph">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: leadTextColumnRight }}></div>
                    </div>
                </div>
            )}

            {moduleCta && (
                <div className="module--cta">
                    <Link className="module--cta slow" type="arrow" path={moduleCta.link} target={moduleCta.target}>
                        {moduleCta.caption}
                    </Link>
                </div>
            )}
            {lightboxLinkText &&
                <div className="module--cta" onClick={() => handleLightboxClick()}>
                    <Link className="module--cta slow" type="lightbox">
                        {lightboxLinkText}
                    </Link>
                </div>
            }

            {list && listType == 'Desordenada' && (
                <ul className="list">
                    {list.map((item, idx) => {
                        return (
                            <li key={`text-p-${idx}`} className="slow body--s main-text-paragraph">
                                <div dangerouslySetInnerHTML={{ __html: item }} />
                            </li>
                        );
                    })}
                </ul>
            )}

            {list && listType != 'Desordenada' && (
                <ol className="list list-ordered">
                    {list.map((item, idx) => {
                        return (
                            <li key={`text-p-${idx}`} className="body--s slow main-text-paragraph">
                                <span><div dangerouslySetInnerHTML={{ __html: item }} /></span>
                            </li>
                        );
                    })}
                </ol>
            )}

            {downloads && !groupLink && (
                <ul className="list list-downloads">
                    {downloads.map((item, idx) => {
                        return (
                            <li key={`text-p-${idx}`} className="link--download main-text-paragraph">
                                <Link type="download" path={item.file ? item.file : item.umbracoFile} label={item.title ? item.title : item.name} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                            </li>
                        );
                    })}
                </ul>
            )}
            {downloads && groupLink && (
                <>
                    <ul className="enlace-agrupador">

                        <li className="slow">
                            <EnlaceAgrupador links={downloads} box_label={groupLink} />
                        </li>



                    </ul>
                    {/*<EnlaceAgrupador links={downloads} box_label={groupLink} /></>*/}
                </>
            )}
        </div>
    );
};

export default BloqueTextoH1;
