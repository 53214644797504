import React, { useState, useEffect, useRef } from 'react';
import { rndKeyGen, replaceUrl } from '../helpers';
import './HeaderRestyling.scss';
import useAnalytics from '../custom_hooks/useAnalytics';
import { IconsMap, SolutionsIcons } from '../components/Icons/Icons';

const SubMenu = ({ data, resolution }) => {
        const [status, setStatus] = useState(false);
        const { trackClick } = useAnalytics();

        const toggleContainer = () => {
                setStatus(!status);
        };

        const handleAnalytics = e => {
                trackClick(e);
        };

        return (
                <li
                        className={`header-restyling__layer-submenu ${resolution !== 'laptop-up' && status
                                ? 'header-restyling__layer-submenu-novicell'
                                : ''
                                }`}
                >
                        <h2 className={`header-restyling__layer-main-title ${status
                                ? 'header-restyling__layer-main-title--open'
                                : ''
                                }`}
                        >
                                {resolution === 'laptop-up' && (
                                        <a onClick={handleAnalytics} href={data.url} title={data.title} className="header-restyling__layer-main-link" >
                                                <span>{data.title}</span>
                                                {data.subMenus.length > 1 && (<span> {IconsMap['ChevronDown']} </span>)}
                                        </a>
                                )}

                                {resolution !== 'laptop-up' &&
                                        data.subMenus.length > 0 && (
                                                <button
                                                        type="button"
                                                        className="header-restyling__layer-main-link"
                                                        onClick={
                                                                toggleContainer
                                                        }
                                                >
                                                        <span>
                                                                {data.title}
                                                        </span>
                                                        <span>
                                                                {
                                                                        IconsMap[
                                                                        'ChevronDownSmall'
                                                                        ]
                                                                }
                                                                {
                                                                        IconsMap[
                                                                        'ChevronDownBig'
                                                                        ]
                                                                }
                                                        </span>
                                                </button>
                                        )}

                                {resolution !== 'laptop-up' &&
                                        data.subMenus.length === 0 && (
                                                <a
                                                        href={data.url}
                                                        className="header-restyling__layer-main-link"
                                                >
                                                        {data.title}
                                                </a>
                                        )}
                        </h2>

                        <div
                                className={`header-restyling__layer-content ${data.columnsMenu &&
                                        data.subMenus &&
                                        data.subMenus.length > 1
                                        ? 'header-restyling__layer-content--multiple'
                                        : ''
                                        } ${data.subMenus
                                                ? 'header-restyling__layer-content--overlay'
                                                : ''
                                        }`}
                        >
                                <div
                                        className={`header-restyling__layer-content-grid`}
                                >
                                        {data.columnsMenu &&
                                                data.subMenus &&
                                                data.subMenus.map(
                                                        (list, idx) => {
                                                                list.title ===
                                                                        'Tecnologías' ||
                                                                        list.title ===
                                                                        'Technologies'
                                                                        ? (list.category =
                                                                                'technologies')
                                                                        : (list.category =
                                                                                'solutions');
                                                                const containerClass = `header-restyling__layer-container--${list.category
                                                                        ? list.category
                                                                        : ''
                                                                        }`;

                                                                return (
                                                                        <div key={rndKeyGen()} className={`header-restyling__layer-container header-restyling__layer-container--icon ${resolution !==
                                                                                'laptop-up' &&
                                                                                !status
                                                                                ? 'header-restyling__layer-container-hide-content'
                                                                                : ''
                                                                                }`}
                                                                        >
                                                                                <a href={list.url} title={list.title} className="header-restyling__layer-top-link" style={{ borderBottom: 'none' }} >
                                                                                        <span>
                                                                                                {
                                                                                                        list.text
                                                                                                }
                                                                                        </span>
                                                                                        <span> {IconsMap['IconArrowCircled']} </span>
                                                                                </a>

                                                                                {/* {TECNOLOGIAS & SOLUCIONES} */}
                                                                                <ul
                                                                                        className={`header-restyling__layer-list ${containerClass}`}
                                                                                >
                                                                                        {list.subMenus &&
                                                                                                list.subMenus.map(
                                                                                                        el => (
                                                                                                                <li
                                                                                                                        key={rndKeyGen()}
                                                                                                                >
                                                                                                                        <a
                                                                                                                                onClick={
                                                                                                                                        handleAnalytics
                                                                                                                                }
                                                                                                                                href={
                                                                                                                                        el.url
                                                                                                                                }
                                                                                                                                title={
                                                                                                                                        el.title
                                                                                                                                }
                                                                                                                                className="header-restyling__layer-link"
                                                                                                                        >
                                                                                                                                {el.icon !==
                                                                                                                                        '' && (
                                                                                                                                                <span className="header-restyling__layer-link-icon">
                                                                                                                                                        {
                                                                                                                                                                SolutionsIcons[
                                                                                                                                                                el
                                                                                                                                                                        .icon
                                                                                                                                                                ]
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                                IconsMap[
                                                                                                                                                                'IconArrowRightLong'
                                                                                                                                                                ]
                                                                                                                                                        }
                                                                                                                                                        {/* {icons[el.icon]} */}
                                                                                                                                                        {/* <IconArrowRightLong className="header-restyling__layer-link-arrow" /> */}
                                                                                                                                                </span>
                                                                                                                                        )}
                                                                                                                                <span>
                                                                                                                                        {
                                                                                                                                                el.title
                                                                                                                                        }
                                                                                                                                </span>
                                                                                                                        </a>
                                                                                                                        {el.subMenus && (
                                                                                                                                <ul className="header-restyling__layer-sublist">
                                                                                                                                        {el.subMenus.map(
                                                                                                                                                subEl => (
                                                                                                                                                        <li
                                                                                                                                                                key={rndKeyGen()}
                                                                                                                                                        >
                                                                                                                                                                <a
                                                                                                                                                                        href={
                                                                                                                                                                                subEl.url
                                                                                                                                                                        }
                                                                                                                                                                        title={
                                                                                                                                                                                subEl.title
                                                                                                                                                                        }
                                                                                                                                                                        className="header-restyling__layer-sublink"
                                                                                                                                                                >
                                                                                                                                                                        {
                                                                                                                                                                                subEl.title
                                                                                                                                                                        }
                                                                                                                                                                </a>
                                                                                                                                                        </li>
                                                                                                                                                )
                                                                                                                                        )}
                                                                                                                                </ul>
                                                                                                                        )}
                                                                                                                </li>
                                                                                                        )
                                                                                                )}
                                                                                </ul>

                                                                                {data.url &&
                                                                                        idx ===
                                                                                        data
                                                                                                .subMenus
                                                                                                .length -
                                                                                        1 && (
                                                                                                <a
                                                                                                        href={
                                                                                                                data.url
                                                                                                        }
                                                                                                        title={
                                                                                                                data.title
                                                                                                        }
                                                                                                        className="header-restyling__layer-link header-restyling__layer-link--wrapper"
                                                                                                >
                                                                                                        <span>
                                                                                                                Ir
                                                                                                                a
                                                                                                                la
                                                                                                                portada
                                                                                                                de{' '}
                                                                                                                {
                                                                                                                        data.title
                                                                                                                }
                                                                                                        </span>
                                                                                                        <span>
                                                                                                                {
                                                                                                                        IconsMap[
                                                                                                                        'IconArrowRight'
                                                                                                                        ]
                                                                                                                }
                                                                                                                {/* <IconArrowRight /> */}
                                                                                                        </span>
                                                                                                </a>
                                                                                        )}
                                                                        </div>
                                                                );
                                                        }
                                                )}

                                        {/* {SUBITEMS} */}

                                        {!data.columnsMenu &&
                                                data.subMenus &&
                                                data.subMenus.length > 0 ? (
                                                <div
                                                        className={`header-restyling__layer-container ${resolution !==
                                                                'laptop-up' &&
                                                                !status
                                                                ? 'header-restyling__layer-container-hide-content'
                                                                : ''
                                                                }`}
                                                >
                                                        <a
                                                                href={data.url}
                                                                title={
                                                                        data.title
                                                                }
                                                                className="header-restyling__layer-top-link"
                                                        >
                                                                <span>
                                                                        {
                                                                                data.text
                                                                        }
                                                                </span>
                                                                <span>
                                                                        {
                                                                                IconsMap[
                                                                                'IconArrowCircled'
                                                                                ]
                                                                        }
                                                                </span>
                                                        </a>

                                                        <ul className="header-restyling__subcontainer">
                                                                {data.subMenus &&
                                                                        data.subMenus.map(
                                                                                el => (
                                                                                        <li
                                                                                                key={rndKeyGen()}
                                                                                                className="header-restyling__subcontainer__items"
                                                                                        >
                                                                                                <a
                                                                                                        onClick={
                                                                                                                handleAnalytics
                                                                                                        }
                                                                                                        href={
                                                                                                                el.url
                                                                                                        }
                                                                                                        title={
                                                                                                                el.title
                                                                                                        }
                                                                                                        className="header-restyling__layer-link"
                                                                                                >
                                                                                                        <span>
                                                                                                                {
                                                                                                                        el.title
                                                                                                                }
                                                                                                        </span>
                                                                                                </a>
                                                                                                {el.subMenus && (
                                                                                                        <ul className="header-restyling__layer-sublist">
                                                                                                                {el.subMenus.map(
                                                                                                                        subEl => (
                                                                                                                                <li
                                                                                                                                        key={rndKeyGen()}
                                                                                                                                        className="header-restyling__layer-sublist__items"
                                                                                                                                >
                                                                                                                                        <a
                                                                                                                                                onClick={
                                                                                                                                                        handleAnalytics
                                                                                                                                                }
                                                                                                                                                href={
                                                                                                                                                        subEl.url
                                                                                                                                                }
                                                                                                                                                title={
                                                                                                                                                        subEl.title
                                                                                                                                                }
                                                                                                                                                className="header-restyling__layer-sublink"
                                                                                                                                        >
                                                                                                                                                {
                                                                                                                                                        subEl.title
                                                                                                                                                }
                                                                                                                                        </a>
                                                                                                                                </li>
                                                                                                                        )
                                                                                                                )}
                                                                                                        </ul>
                                                                                                )}
                                                                                        </li>
                                                                                )
                                                                        )}
                                                        </ul>
                                                        {data.globalLink &&
                                                                idx ===
                                                                data
                                                                        .lists
                                                                        .length -
                                                                1 && (
                                                                        <a
                                                                                href={
                                                                                        data.url
                                                                                }
                                                                                title={
                                                                                        data.title
                                                                                }
                                                                                className="header-restyling__layer-link header-restyling__layer-link--wrapper"
                                                                        >
                                                                                <span>
                                                                                        Ir
                                                                                        a
                                                                                        la
                                                                                        portada
                                                                                        de{' '}
                                                                                        {
                                                                                                data.title
                                                                                        }
                                                                                </span>
                                                                                <span>
                                                                                        {/* <IconArrowRight /> */}
                                                                                        {
                                                                                                IconsMap[
                                                                                                'IconArrowRight'
                                                                                                ]
                                                                                        }
                                                                                </span>
                                                                        </a>
                                                                )}
                                                </div>
                                        ) : null}
                                </div>
                        </div>
                </li>
        );
};

const HeaderRestyling = ({
        top,
        stockTicker,
        energyLink,
        energyLinkHome,
        home,
        subMenus,
        clients,
        suppliers,
        language,
}) => {

        const { pageVariable, scrollPercentage, trackClick } = useAnalytics();
        const [menu, setMenu] = useState({ open: false, text: 'Abrir menú' });
        const [resolution, setResolution] = useState('');
        const gridRef = useRef();

        const handleResize = () => {
                const _w = gridRef.current.clientWidth;

                if (_w >= 1024) {
                        if (resolution !== 'laptop-up') {
                                setResolution('laptop-up');
                        }
                } else if (_w >= 680) {
                        if (resolution !== 'tablet') {
                                setResolution('tablet');
                        }
                } else {
                        if (resolution !== 'phone') {
                                setResolution('phone');
                        }
                }
        };

        useEffect(() => {
                pageVariable();
                scrollPercentage();

                if (!resolution && gridRef) handleResize();
                window.addEventListener('resize', handleResize);
                return () => window.removeEventListener('resize', handleResize);
        }, []);

        const toggleMenu = () => {
                setMenu({
                        open: !menu.open,
                        text: menu.open ? 'Abrir menú' : 'Cerrar menú',
                });
        };

        const closeMenu = () => {
                setMenu({ open: false, text: 'Abrir menú' });
        };

        const handleAnalytics = e => {
                trackClick(e);
        };

        const LaptopView = () => {

                var homeTitleShort = energyLinkHome.title.split(' ').pop();
                var businessTitleShort = energyLink.title.split(' ').pop();


                return (
                        <>
                                <div data-view={resolution} className="grid header-restyling__midbar">

                                        <p onClick={handleAnalytics} className="stock-ticker" > <iframe className="ticker-iframe" scrolling="no" src={stockTicker} /> </p>
                                        <a onClick={handleAnalytics} href={clients.url} title={clients.title} className="header-restyling__clients-link" target="_blank" >{IconsMap['AreaClientes']}<span>{clients.text}</span> </a>
                                        <a onClick={handleAnalytics} href={suppliers.url} title={suppliers.title} className="header-restyling__suppliers-link" target="_blank" >{IconsMap['Proveedores']}<span>{suppliers.text}</span> </a>
                                        <a onClick={handleAnalytics} href={language.url} title={language.title} className="header-restyling__language-link" > {language.text}{' '} </a>


                                        <div className="header-restyling__container__buttons">

                                                <a onClick={handleAnalytics} href={energyLink.url} title={energyLink.title} className="header-restyling__energy-link" target="_blank">
                                                        <span className="icon-fix">
                                                                <span className="home"> {IconsMap['Empresa']} </span>
                                                                <span className="text"> {energyLink.text} </span>
                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                        </span>
                                                </a>


                                                <a onClick={handleAnalytics} href={replaceUrl(home, energyLinkHome.url)} title={energyLinkHome.title} className="header-restyling__energy-link__home" target="_blank">
                                                        <span className="icon-fix">
                                                                <span className="home"> {IconsMap['Hogar']} </span>
                                                                <span className="text"> {energyLinkHome.text}</span>
                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                        </span>
                                                </a>


                                        </div>
                                </div>
                                <div className="grid header-restyling__bottom-bar">
                                        <a href={home.url} className="header-restyling__logo" > {IconsMap['LogoFull']} </a>
                                        <ul className="header-restyling__layer-main-list">
                                                {subMenus.map(submenu => (<SubMenu data={submenu} resolution={resolution} key={rndKeyGen()} />))}
                                        </ul>
                                </div>
                        </>
                );
        };

        const PhoneView = () => {

                return (
                        <>
                                <div data-view={resolution} className="grid header-restyling__midbar">
                                        <a href={home.url} className="header-restyling__logo"> {IconsMap['LogoFull']} </a>
                                        <p className="stock-ticker"> <iframe className="ticker-iframe" scrolling="no" src={stockTicker} /> </p>
                                </div>

                                <div className="grid header-restyling__bottom-bar">

                                        <div className="header-restyling__container__buttons">

                                                <a onClick={handleAnalytics} href={energyLink.url} title={energyLink.title} className="header-restyling__energy-link" target="_blank">
                                                        <span className="icon-fix">
                                                                <span className="home"> {IconsMap['Empresa']} </span>
                                                                <span className="text"> {energyLink.textShort} </span>
                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                        </span>
                                                </a>


                                                <a onClick={handleAnalytics} href={home.url === '/' ? 'https://hogares.acciona-energia.com/en' : energyLinkHome.url} title={energyLinkHome.title} className="header-restyling__energy-link__home" target="_blank">
                                                        <span className="icon-fix">
                                                                <span className="home"> {IconsMap['Hogar']} </span>
                                                                <span className="text"> {energyLinkHome.textShort}</span>
                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                        </span>
                                                </a>

                                                <button className="header-restyling__menu-button" onClick={toggleMenu} > <span>{menu.text}</span> </button>
                                        </div>
                                </div>




                                <div className="grid header-restyling__layer">
                                        <nav className="header-restyling__layer-nav">
                                                <button
                                                        type="button"
                                                        className="header-restyling__close-layer"
                                                        onClick={closeMenu}
                                                >
                                                        <span>Cerrar menú</span>
                                                </button>
                                                <ul className="header-restyling__layer-main-list">
                                                        {subMenus.map(
                                                                submenu => (
                                                                        <SubMenu
                                                                                data={
                                                                                        submenu
                                                                                }
                                                                                resolution={
                                                                                        resolution
                                                                                }
                                                                                key={rndKeyGen()}
                                                                                reference={
                                                                                        gridRef
                                                                                }
                                                                        />
                                                                )
                                                        )}
                                                </ul>
                                        </nav>
                                        <div className="header-restyling__layer-bottom">
                                                <div className="header-restyling__layer-bottom-buttons">
                                                        <div className='header-restyling__layer-bottom-buttons_clients-language'>
                                                                <a href={clients.url} title={clients.title} className="header-restyling__clients-link_layer" target="_blank" > {IconsMap['AreaClientes']} <span> {clients.textShort} </span> </a>
                                                                {/* <span className='separator' /> */}
                                                                <a href={suppliers.url} title={suppliers.title} className="header-restyling__suppliers-link_layer" target="_blank" > {IconsMap['Proveedores']} <span> {suppliers.textShort} </span> </a>
                                                                <span className='separator_m5' />
                                                                <a href={language.url} title={language.title} className="header-restyling__language-link_layer" > {language.text} </a>
                                                        </div>

                                                        <div className="header-restyling__container__buttons">
                                                                <a onClick={handleAnalytics} href={energyLink.url} title={energyLink.title} className="header-restyling__energy-link" target="_blank">
                                                                        <span className="icon-fix">
                                                                                <span className="home"> {IconsMap['Empresa']} </span>
                                                                                <span className="text"> {energyLink.textShort} </span>
                                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                                        </span>
                                                                </a>

                                                                <a onClick={handleAnalytics} href={home.url === '/' ? 'https://hogares.acciona-energia.com/en' : energyLinkHome.url} title={energyLinkHome.title} className="header-restyling__energy-link__home" target="_blank">
                                                                        <span className="icon-fix">
                                                                                <span className="home"> {IconsMap['Hogar']} </span>
                                                                                <span className="text"> {energyLinkHome.textShort}</span>
                                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                                        </span>
                                                                </a>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </>
                );
        };

        const TabletView = () => {

                return (
                        <>
                                <div data-view={resolution} className="grid header-restyling__midbar">
                                        <p onClick={handleAnalytics} className="stock-ticker" > <iframe className="ticker-iframe" scrolling="no" src={stockTicker} /> </p>

                                        <a onClick={handleAnalytics} href={language.url} title={language.title} className="header-restyling__language-link" >
                                                {language.text}
                                        </a>

                                        <div className="header-restyling__container__buttons">
                                                <a onClick={handleAnalytics} href={energyLink.url} title={energyLink.title} className="header-restyling__energy-link" target="_blank">
                                                        <span className="icon-fix">
                                                                <span className="home"> {IconsMap['Empresa']} </span>
                                                                <span className="text"> {energyLink.textShort} </span>
                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                        </span>
                                                </a>

                                                <a onClick={handleAnalytics} href={replaceUrl(home, energyLinkHome.url)} title={energyLinkHome.title} className="header-restyling__energy-link__home" target="_blank">
                                                        <span className="icon-fix">
                                                                <span className="home"> {IconsMap['Hogar']} </span>
                                                                <span className="text"> {energyLinkHome.textShort}</span>
                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                        </span>
                                                </a>
                                        </div>
                                </div>

                                <div className="grid header-restyling__bottom-bar">
                                        <a href={home.url} className="header-restyling__logo" > {IconsMap['LogoFull']} </a>
                                        <button className="header-restyling__menu-button" onClick={toggleMenu} > <span>{menu.text}</span> </button>
                                </div>

                                <div className="grid header-restyling__layer">
                                        <nav className="header-restyling__layer-nav">
                                                <button type="button" className="header-restyling__close-layer" onClick={closeMenu} > <span>Cerrar menú</span> </button>
                                                <ul className="header-restyling__layer-main-list">
                                                        {subMenus.map(
                                                                submenu => (<SubMenu data={submenu} resolution={resolution} key={rndKeyGen()} reference={gridRef} />)
                                                        )}
                                                </ul>
                                        </nav>

                                        <div className="header-restyling__layer-bottom">
                                                <div className="header-restyling__layer-bottom-buttons">
                                                        <a href={clients.url} title={clients.title} className="header-restyling__clients-link" target="_blank" >
                                                                {IconsMap['Social']}
                                                                <span> {clients.text} </span>
                                                        </a>

                                                        <a href={language.url} title={language.title} className="header-restyling__language-link">{language.text}</a>
                                                </div>
                                                <a href={energyLink.url} title={energyLink.title} className="header-restyling__energy-link" target="_blank" >
                                                        <span> {energyLink.text}</span>
                                                        {IconsMap['IconArrowRight']}
                                                </a>
                                        </div>

                                        <div className="grid header-restyling__layer">
                                                <nav className="header-restyling__layer-nav">
                                                        <button type="button" className="header-restyling__close-layer" onClick={closeMenu} >
                                                                <span> Cerrar menú </span>
                                                        </button>

                                                        <ul className="header-restyling__layer-main-list">
                                                                {subMenus.map(
                                                                        submenu => (
                                                                                <SubMenu
                                                                                        data={
                                                                                                submenu
                                                                                        }
                                                                                        resolution={
                                                                                                resolution
                                                                                        }
                                                                                        key={rndKeyGen()}
                                                                                        reference={
                                                                                                gridRef
                                                                                        }
                                                                                />
                                                                        )
                                                                )}
                                                        </ul>
                                                </nav>
                                                <div className="header-restyling__layer-bottom" >

                                                        <div className='header-restyling__layer-bottom-buttons_clients-language'>
                                                                <a href={clients.url} title={clients.title} className="header-restyling__clients-link_layer" target="_blank" >{IconsMap['AreaClientes']}<span> {clients.text} </span></a>
                                                                <a href={suppliers.url} title={suppliers.title} className="header-restyling__clients-link_layer" target="_blank" >{IconsMap['Proveedores']}<span> {suppliers.text} </span></a>
                                                                <span className='separator' />
                                                                <a href={language.url} title={language.title} className="header-restyling__language-link_layer" > {language.text} </a>
                                                        </div>

                                                        <div className="header-restyling__container__buttons">
                                                                <a onClick={handleAnalytics} href={energyLink.url} title={energyLink.title} className="header-restyling__energy-link" target="_blank">
                                                                        <span className="icon-fix">
                                                                                <span className="home"> {IconsMap['Empresa']} </span>
                                                                                <span className="text"> {energyLink.textShort} </span>
                                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                                        </span>
                                                                </a>

                                                                <a onClick={handleAnalytics} href={home.url === '/' ? 'https://hogares.acciona-energia.com/en' : energyLinkHome.url} title={energyLinkHome.title} className="header-restyling__energy-link__home" target="_blank">
                                                                        <span className="icon-fix">
                                                                                <span className="home"> {IconsMap['Hogar']} </span>
                                                                                <span className="text"> {energyLinkHome.textShort}</span>
                                                                                <span className="arrow"> {IconsMap['IconArrowRight']} </span>
                                                                        </span>
                                                                </a>
                                                        </div>

                                                </div>
                                        </div>
                                </div>
                        </>
                );
        };

        const resolvedView =
                resolution === 'laptop-up' ? (
                        <LaptopView />
                ) : resolution === 'tablet' ? (
                        <TabletView />
                ) : (
                        <PhoneView />
                );

        return (
                <header
                        id="header" className={`module header-restyling ${menu.open ? 'header-restyling--open' : ''}`}                >
                        <div ref={gridRef} className="grid header-restyling__topbar" >
                                <p className="header-restyling__topbar-text">
                                        {top.text}
                                </p>
                                <a
                                        href={top.link.url}
                                        title={top.link.title}
                                        className="header-restyling__topbar-link"
                                        target="_blank"
                                >
                                        <span>{top.link.text}</span>
                                        {/* <IconExternal /> */}
                                        {IconsMap['IconExternal']}
                                </a>
                        </div>

                        {resolvedView}
                </header>
        );
};

export default HeaderRestyling;
