import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, ModuleNews, Paginator } from 'React/components';
import { findGetParameter } from 'React/helpers';

const NewsList = ({ culture, urlNews }) => {
    const [allNewsListState, setAllNewsListState] = useState([]);
    const [newsListState, setNewsListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [yearFilterState, setYearFilterState] = useState('');
    const [monthFilterState, setMonthFilterState] = useState('');
    const [solutionFilterState, setSolutionFilterState] = useState('');
    const [actualPagesList, setActualPagesList] = useState([]);
    const [yearsComboState, setYearsComboState] = useState([]);
    const [monthsComboState, setMonthsComboState] = useState([]);

    const itemsPerPage = 9;

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const defaultYearsCombo = ["2021", "2020", "2019", "2018", "2017", "2016", "2015"];

    const defaultMonthsCombo = culture === 'es' ? ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"] :
        ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

    const solutionsCombo = culture === 'es' ? [{ name: "Corporativo", value: "Corporativo" }, { name: "Energía", value: "Energia" },
    { name: "Transporte", value: "Transporte" }, { name: "Agua", value: "Agua" }, { name: "Sociales", value: "Social" }, { name: "Ciudades", value: "Ciudades" },
    { name: "Inmobiliaria", value: "Inmobiliaria" }, { name: "Financieras", value: "Capital" }, { name: "Otros negocios", value: "Otros Negocios" }] :
        [{ name: "Corporate", value: "Corporativo" }, { name: "Energy", value: "Energia" }, { name: "Transport", value: "Transporte" },
        { name: "Water", value: "Agua" }, { name: "Social", value: "Social" }, { name: "Cities", value: "Ciudades" },
        { name: "Real Estate", value: "Inmobiliaria" }, { name: "Financial", value: "Capital" }, { name: "Other businesses", value: "Otros Negocios" }];

    const recalculatePages = (page) => {
        const sidePages = 7;

        var nextList = [];
        var befList = [];
        nextList = pagesListState.slice(page - 1, page + sidePages);
        befList = pagesListState.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);

        if (actualPagesList.length === 0 && page !== 1) {

            setActualPagesList(nextList);
        }

        else {

            if (page === 1) {
                setActualPagesList(nextList);
            }
            else if (page === pagesListState[pagesListState.length - 1].num) {
                setActualPagesList(befList);
            }
            else if (actualPagesList.length > 0 && page === actualPagesList[actualPagesList.length - 1].num) {
                setActualPagesList(nextList);
            }
            else if (page === pagesListState[pagesListState.length - 1].num || page === actualPagesList[0].num) {
                setActualPagesList(befList);
            }
            else if (actualPagesList.length > 0 && page < actualPagesList[0].num) {
                befList = pagesListState.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
                setActualPagesList(befList);
            }
            else if (page > pagesListState[pagesListState.length - 1].num) {
                nextList = pagesListState.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
                setActualPagesList(nextList);
            }
        }
    };

    const getNewsList = (route, culture, page, year, month, solution) => {
        var url = `/api/CmsController/GetNewsList?pressRoomRoute=${route}&language=${culture}&pageIndex=${page}&includeFeaturedItems=true${year ? '&year=' + year : ''}${month ? '&month=' + month : ''}${solution ? '&solution=' + solution : ''}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    var responseNewsListPaginated = JSON.parse(xhrPages.responseText);

                    var pagesList = [];
                    var listSize = Math.ceil(responseNewsListPaginated.noteList.length / itemsPerPage);

                    for (var i = 0; i !== listSize; i++) {
                        var p = { num: i + 1 };
                        pagesList.push(p);
                    }

                    const sidePages = 7;

                    var nextList = [];
                    var befList = [];
                    nextList = responseNewsListPaginated.pagePagination.slice(page - 1, page + sidePages);
                    befList = responseNewsListPaginated.pagePagination.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);


                    if (actualPagesList.length === 0 && page !== 1) {

                        setActualPagesList(nextList);
                    }

                    else {

                        if (page === 1) {
                            setActualPagesList(nextList);
                        }
                        else if (page === responseNewsListPaginated.pagePagination[responseNewsListPaginated.pagePagination.length - 1].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page === actualPagesList[actualPagesList.length - 1].num) {
                            setActualPagesList(nextList);
                        }
                        else if (page === responseNewsListPaginated.pagePagination[responseNewsListPaginated.pagePagination.length - 1].num || page === actualPagesList[0].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page < actualPagesList[0].num) {
                            befList = responseNewsListPaginated.pagePagination.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
                            setActualPagesList(befList);
                        }
                        else if (page > responseNewsListPaginated.pagePagination[responseNewsListPaginated.pagePagination.length - 1].num) {
                            nextList = responseNewsListPaginated.pagePagination.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
                            setActualPagesList(nextList);
                        }
                    }

                    //var paginatedList = paginate(responseNewsListPaginated.noteList, itemsPerPage, page);

                    if (responseNewsListPaginated.noteList.length < itemsPerPage) {
                        var ej1 = {
                            title: "",
                            link: "",
                            intro: "",
                            divisiones: "",
                            soluciones: [],
                            date: "",
                            photo: "",
                            featuredNote: false
                        };

                        var size = itemsPerPage - responseNewsListPaginated.noteList.length;
                        for (var i = 0; i < size; i++) {
                            responseNewsListPaginated.noteList.push(ej1);
                        }

                    }

                    setNewsListState(responseNewsListPaginated.noteList);
                    setPagesListState(responseNewsListPaginated.pagePagination);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);


    };

    const getAvailableDates = (route, culture) => {
        var url = `/api/CmsController/GetAvailableDatesNews?pressRoomRoute=${route}&language=${culture}`;
        $.getJSON(url, function (data) {
            var years = [];
            var months = [];
            // console.log(data);
            $.each(data, function (key, availableDate) {
                // console.log(availableDate);
                years.push(availableDate.year);
                months[availableDate.year] = availableDate.months;
            });
            setYearsComboState(years);
            setMonthsComboState(months);
            // console.log(years);
            // console.log(months);
        }).fail(function () {
            setYearsComboState(defaultYearsCombo);
            var months = [];
            $.each(defaultYearsCombo, function (key, year) {
                months[year] = defaultMonthsCombo;
            });
            setMonthsComboState(months);
        });
    }

    const changePage = (page) => {
        setActualPageState(page);
        getNewsList(urlNews, culture, page, yearFilterState, monthFilterState, solutionFilterState);
        document.getElementById("scrollTo").scrollIntoView();
    };

    const nextPage = () => {


        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);

            getNewsList(urlNews, culture, actualPageState + 1, yearFilterState, monthFilterState, solutionFilterState);

            document.getElementById("scrollTo").scrollIntoView();

        }

        if (window !== null) {
            var url = window.location.href;
            var urlPageNumber = url.match('#(.+)');
            if (urlPageNumber !== null && urlPageNumber.length > 0) {

                var urlNumber = parseInt(urlPageNumber[1]) !== pagesListState.length ? urlPageNumber[1] : parseInt(urlPageNumber[1] - 1);
                var newurl = url.replace(urlNumber, (actualPageState + 1).toString());
                window.location.href = newurl;
            }
            else {
                window.location.href = url + '#' + (actualPageState + 1);
            }
        }


    };

    const prevPage = () => {

        document.getElementById("scrollTo").scrollIntoView();

        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);

            getNewsList(urlNews, culture, actualPageState - 1, yearFilterState, monthFilterState, solutionFilterState);


            document.getElementById("scrollTo").scrollIntoView();
        }

        if (window !== null) {
            var url = window.location.href;
            var urlPageNumber = url.match('#(.+)');
            if (urlPageNumber !== null && urlPageNumber.length > 0) {

                var urlNumber = urlPageNumber[0] !== '#1' ? urlPageNumber[0] : '#2';
                var newurl = url.replace(urlNumber, '#' + (actualPageState - 1).toString());

                window.location.href = newurl;
            }
            else {
                window.location.href = url + '#' + (actualPageState - 1);
            }
        }
    };

    const setYearFilter = (event) => {
        setYearFilterState(event.target.value);
        setActualPageState(1);
        var month = event.target.value ? monthFilterState : "";
        setMonthFilterState(month);
        getNewsList(urlNews, culture, 1, event.target.value, month, solutionFilterState);
    };

    const setMonthFilter = (event) => {
        setMonthFilterState(event.target.value);
        setActualPageState(1);
        getNewsList(urlNews, culture, 1, yearFilterState, event.target.value, solutionFilterState);
    };

    const setSolutionFilter = (event) => {
        setSolutionFilterState(event.target.value);
        setActualPageState(1);
        getNewsList(urlNews, culture, 1, yearFilterState, monthFilterState, event.target.value);
    };

    useEffect(() => {
        var solution = findGetParameter("solution");
        var month = findGetParameter("month");
        var year = findGetParameter("year");
        getAvailableDates(urlNews, culture);

        setSolutionFilterState(solution);
        setMonthFilterState(month);
        setYearFilterState(year);

        if (window !== null) {
            var urlPageNumber = window.location.href.match('#(.+)');
            var pageNumber = urlPageNumber ? urlPageNumber[1] : '1';

            setActualPageState(parseInt(pageNumber));
            getNewsList(urlNews, culture, parseInt(pageNumber), year, month, solution);
        }
        else {
            getNewsList(urlNews, culture, 1, year, month, solution);
        }

    }, []);

    return (
        <div className=''>
            <BuscadorFiltros type='noticias' yearFilter={setYearFilter} monthFilter={setMonthFilter} solutionFilter={setSolutionFilter}
                yearsCombo={yearsComboState} monthsCombo={monthsComboState[yearFilterState]} solutionsCombo={solutionsCombo} culture={culture} yearSelected={yearFilterState} />
            <span className="custom-anchor-small" id="scrollTo" />
            <ModuleNews content={newsListState} culture={culture} restyling={true} />
            {pagesListState.length > 1 &&
                <Paginator pagesList={pagesListState} actualPage={actualPageState} changePage={changePage}
                    nextPage={nextPage} prevPage={prevPage} actualPagesList={actualPagesList} />
            }
        </div>
    );
};

export default NewsList;