// button.component.js
import React, { useEffect } from 'react';
import { Link } from 'React/components';
import { ButtonCta } from 'React/components';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-24.svg';
import { ReactComponent as IconArrowRight } from 'Icons/iconarrowright.svg';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';
import IconsMap from './_iconsMap';
import useAnalytics from '../../custom_hooks/useAnalytics';

import './Styles.scss';
import { useFade } from 'React/custom_hooks';

const Breadcrumbs = ({
    labelNoResults,
    search,
    cta,
    cargo,
    icon,
    download,
    downloadText,
    className,
    type,
    descriptiveText,
    suscribe,
    labelSolicitarEmpleo,
    sector,
    jobDate,
    jobPlace,
    pageTitle,
    firstLevel,
    secondLevel,
    thirdLevel,
    fourthLevel,
    fithLevel,
    backLink,
    anchors,
    url,
    culture,
    legend,
    h1,
    labelDe,
    jobId,
    button,
    logo,
    restyling,
}) => {

    let moduleRef = React.createRef();

    const monthNames = culture === 'en' ? ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ] : ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    var dateFormat = '';
    const getDateFormat = (d) => {
        let date = new Date(d);
        let day = date.getDate();
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();

        dateFormat = `${day} ${labelDe} ${month}, ${year}`;
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        var searchText = event.target.search.value;

        window.location.href = `/${culture === 'es' ? "es/resultados" : "results"}?search=${searchText}`;
    };

    useFade(moduleRef);


    const { trackClick } = useAnalytics();

    const handleAnalytics = (e) => {
        trackClick(e);
    }

    useEffect(() => {

        const headerNav = document.querySelector('.breadcrumbs--fixed');
        const appWrapper = document.querySelector('.app--layout');

        const shareSocial = document.querySelector('.module-sharesocial');
        const appScrollTop = appWrapper.scrollTop;

        appWrapper.addEventListener('scroll', function () {
            if (headerNav != null) {
                const headerNavHeight = headerNav.outerHeight;
                let headerNavOffset = headerNav.offsetTop;
                let elHeight = headerNavOffset;


                if (document.querySelector('.module-wysiwyg') != null) {
                    let wysiwygPos = document.querySelector('#main > .module-wysiwyg').getBoundingClientRect();

                    if (wysiwygPos.top < 0) {
                        headerNav.classList.add('is-fixed');
                    }

                    if (wysiwygPos.top > 0) {
                        headerNav.classList.remove('is-fixed');
                    }
                }

                if (shareSocial !== null && headerNav !== undefined) {
                    if (appWrapper.scrollTop > shareSocial.offsetTop - 600) {
                        headerNav.classList.add('is-done');
                    } else {
                        headerNav.classList.remove('is-done');
                    }
                }
            }
        });

        const headerNavFooter = document.querySelector('.breadcrumbs--fixed-footer');

        if (headerNavFooter != null) {
            const headerNavOffset = headerNavFooter.offsetTop;
            let elHeight = headerNavOffset;
        }

        appWrapper.addEventListener('scroll', function () {
            let mainHeight = document.getElementById('main').getBoundingClientRect().height;
            let headerHeight = document.getElementById('header').getBoundingClientRect().height;

            if (document.getElementById('breadcrumbs') != null) {
                let winScroll = appWrapper.scrollTop || appWrapper.scrollTop;
                let breadcrumbsBottom = document.getElementById('breadcrumbs').getBoundingClientRect().bottom;

                if (breadcrumbsBottom < '-100') {
                    headerNavFooter.classList.add('is-fixed');
                } else {
                    headerNavFooter.classList.remove('is-fixed');
                }

                if (winScroll >= mainHeight) {
                    headerNavFooter.classList.add('is-done');
                } else {
                    headerNavFooter.classList.remove('is-done');
                }

                if (breadcrumbsBottom > '-100') {
                    headerNavFooter.classList.add('is-sticky');
                } else {
                    headerNavFooter.classList.remove('is-sticky');
                }

                let scrolled = (winScroll / mainHeight) * 100;
                document.getElementById('progress-bar').style.width = scrolled + '%';
            }

            if (shareSocial !== null) {
                if (appWrapper.scrollTop > shareSocial.offsetTop - 600) {
                    headerNav.classList.add('is-done');
                } else {
                    headerNav.classList.remove('is-done');
                }
            }
        });
    });

    const applyJob = (event) => {

        var url = window.location.href;
        var urlObj = new URL(url);
        var jobId = urlObj.searchParams.get("id");

        var title = event.currentTarget.attributes['data-title'].value
        //var jobId = event.currentTarget.attributes['data-jobid'].value

        var dataObject = {
            event: "ga_event",
            event_name: "job_offers_start",
            event_category: "job offers",
            event_action: "start",
            event_label: jobId + " : " + title.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            event_value: undefined,
            option: jobId,
            title: title.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()

        }
        window.dataLayer.push('37', dataObject);
    }

    if (type === 'fixed--empleo') {


        getDateFormat(jobDate);
        return (
            <div ref={moduleRef} className={`module grid breadcrumbs breadcrumbs--fixed ${restyling ? 'breadcrumbs--restyling' : ''}`}>
                <div className="breadcrumbs__container">
                    <div className="breadcrumbs__nav">
                        <nav>
                            {firstLevel && (
                                <a className="taglabel" href={firstLevel.link}>
                                    {firstLevel.name}
                                </a>
                            )}
                            {secondLevel && (
                                <a className="taglabel" href={secondLevel.link}>
                                    {secondLevel.name}
                                </a>
                            )}
                            {thirdLevel && (
                                <a className="taglabel" href={thirdLevel.link}>
                                    {thirdLevel.name}
                                </a>
                            )}
                            {fourthLevel && (
                                <a className="taglabel" href={fourthLevel.link}>
                                    {fourthLevel.name}
                                </a>
                            )}
                            {fithLevel && (
                                <a className="taglabel" href={fithLevel.link}>
                                    {fithLevel.name}
                                </a>
                            )}
                        </nav>
                        {backLink && <Link type="back" label={culture === 'es' ? "Volver" : "Back"} path={backLink} />}
                    </div>
                    <div className="header-cta">
                        <div className="header-cta__subheader">
                            <h1 className={!restyling ? 'title--m' : 'title--s'}>{h1 ? h1 : pageTitle}</h1>
                            {(sector || jobPlace || dateFormat) && (
                                <div className="job-subheader">
                                    {sector && <span className="taglabel">
                                        <a className="job-sector" title={sector} href="#">
                                            {icon && <IconEnergy />}
                                            {sector}
                                        </a>
                                    </span>}
                                    {jobPlace && (
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                    )}
                                    <span className="taglabel">
                                        <IconCalendar />
                                        {dateFormat}{' '}
                                    </span>
                                </div>
                            )}
                            {cargo && <div className="taglabel mt-2 cargo">{cargo}</div>}
                        </div>

                        {anchors && (
                            <div className="anchors">
                                {anchors.map((anchor, idx) => {
                                    return (
                                        <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                            {anchor.caption}
                                        </a>
                                    );
                                })}
                            </div>
                        )}
                        {labelSolicitarEmpleo &&
                            /*<ButtonCta path={url} label={labelSolicitarEmpleo} target="_blank" data-jobId={getJobIdFromUrl} dataTitle={pageTitle} />*/
                            <a className="btn-cta" href={url} target="_blank" data-jobid={jobId} data-title={pageTitle} onClick={applyJob}>
                                {labelSolicitarEmpleo}
                            </a>
                        }
                    </div>
                </div>
            </div>
        );
    } else if (type === 'fixed') {
        return (
            <>
                <div ref={moduleRef} id="breadcrumbs" className={`module grid breadcrumbs breadcrumbs--fixed ${className} ${restyling ? 'breadcrumbs--restyling' : ''}`}>
                    <div className="breadcrumbs__container">
                        <div className="breadcrumbs__nav">
                            <nav>
                                {firstLevel && (
                                    <a className="taglabel" href={firstLevel.link}>
                                        {firstLevel.name}
                                    </a>
                                )}
                                {secondLevel && (
                                    <a className="taglabel" href={secondLevel.link}>
                                        {secondLevel.name}
                                    </a>
                                )}
                                {thirdLevel && (
                                    <a className="taglabel" href={thirdLevel.link}>
                                        {thirdLevel.name}
                                    </a>
                                )}
                                {fourthLevel && (
                                    <a className="taglabel" href={fourthLevel.link}>
                                        {fourthLevel.name}
                                    </a>
                                )}
                                {fithLevel && (
                                    <a className="taglabel" href={fithLevel.link}>
                                        {fithLevel.name}
                                    </a>
                                )}
                            </nav>
                            {backLink && <Link type="back" label={culture === 'es' ? "Volver" : "Back"} path={backLink} />}
                        </div>
                        <div className="header-cta">
                            <div className="header-cta__subheader">
                                <h1 className={!restyling ? 'title--m' : 'title--s'}>{h1 ? h1 : pageTitle}</h1>
                                {(sector || jobPlace || dateFormat) && (
                                    <div className="job-subheader">
                                        {sector && <span className="taglabel">
                                            <a className="job-sector" title={sector} href="#">
                                                {icon && <IconEnergy />}
                                                {sector}
                                            </a>
                                        </span>}
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                        <span className="taglabel">
                                            <IconCalendar />
                                            {jobDate}{' '}
                                        </span>
                                    </div>
                                )}
                            </div>

                            {anchors && (
                                <div className="anchors">
                                    {anchors.map((anchor, idx) => {
                                        return (
                                            <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                                {anchor.caption}
                                            </a>
                                        );
                                    })}
                                    {cta && !restyling && (
                                        <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />

                                    )}
                                    {cta && restyling && (
                                        <a href={cta.url} className='breadcrumbs__button' target={cta.target ? cta.target : ''}>
                                            <span>{cta.name}</span>
                                            <IconArrowRight />
                                        </a>
                                    )}
                                </div>
                            )}
                            {download && !restyling && (
                                <div>
                                    <ButtonCta label={downloadText ? downloadText : ''} path={download.umbracoFile ? download.umbracoFile : download.file} target="_blank" />
                                </div>
                            )}
                            {download && restyling && (
                                <a href={download.umbracoFile ? download.umbracoFile : download.file} className='breadcrumbs__button' target={cta.target ? cta.target : ''}>
                                    <span>{downloadText ? downloadText : ''}</span>
                                    <IconArrowRight />
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="progress-bar" id="progress-bar-desktop"></div>
                    {logo &&
                        <div className='breadcrumbs__logo-container'>
                            <img src={logo.url} alt={logo.alt} className='breadcrumbs__logo' />
                        </div>
                    }
                </div>
                {!restyling ?
                    <div
                        id="breadcrumbs-footer"
                        className={`module grid breadcrumbs breadcrumbs--fixed breadcrumbs--fixed-footer ${className} ${restyling ? 'breadcrumbs--restyling' : ''}`}
                    >
                        <div className="breadcrumbs__container">
                            <div className="breadcrumbs__nav">
                                <nav>
                                    {firstLevel && (
                                        <a className="taglabel" href={firstLevel.link}>
                                            {firstLevel.name}
                                        </a>
                                    )}
                                    {secondLevel && (
                                        <a className="taglabel" href={secondLevel.link}>
                                            {secondLevel.name}
                                        </a>
                                    )}
                                    {thirdLevel && (
                                        <a className="taglabel" href={thirdLevel.link}>
                                            {thirdLevel.name}
                                        </a>
                                    )}
                                    {fourthLevel && (
                                        <a className="taglabel" href={fourthLevel.link}>
                                            {fourthLevel.name}
                                        </a>
                                    )}
                                    {fithLevel && (
                                        <a className="taglabel" href={fithLevel.link}>
                                            {fithLevel.name}
                                        </a>
                                    )}
                                </nav>
                                {backLink && !button && <Link type="back" label={culture === 'es' ? "Volver" : "Back"} path={backLink} />}
                            </div>
                            <div className="header-cta">
                                <div className="header-cta__subheader">
                                    {logo &&
                                        <div className='breadcrumbs__logo-container'>
                                            <img src={logo.url} alt={logo.alt} className='breadcrumbs__logo' />
                                        </div>
                                    }
                                    <p className={!restyling ? 'title--m' : 'title--s'}>{h1 ? h1 : pageTitle}</p>
                                    {(sector || jobPlace || dateFormat) && (
                                        <div className="job-subheader">
                                            {sector && <span className="taglabel">
                                                <a className="job-sector" title={sector} href="#">
                                                    {icon && <IconEnergy />}
                                                    {sector}
                                                </a>
                                            </span>}
                                            {jobPlace && (
                                                <span className="taglabel">
                                                    <a href="#" className="job-sector">
                                                        {jobPlace}
                                                    </a>
                                                </span>
                                            )}
                                            <span className="taglabel">
                                                <IconCalendar />
                                                {jobDate}{' '}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {anchors && !cta && (
                                    <div className="anchors">
                                        {anchors.map((anchor, idx) => {
                                            return (
                                                <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                                    {anchor.caption}
                                                </a>
                                            );
                                        })}
                                    </div>
                                )}
                                {anchors && cta && (
                                    <div className="anchors">
                                        {anchors.map((anchor, idx) => {
                                            return (
                                                <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                                    {anchor.caption}
                                                </a>
                                            );
                                        })}
                                        {cta && !restyling && (
                                            <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />
                                        )}
                                        {cta && restyling && (
                                            <a href={cta.url} className='breadcrumbs__button' target={cta.target ? cta.target : ''}>
                                                <span>{cta.name}</span>
                                                <IconArrowRight />
                                            </a>
                                        )}
                                    </div>
                                )}
                                {cta && !anchors && !restyling && (
                                    <div>
                                        <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />
                                    </div>
                                )}
                                {cta && !anchors && restyling && (
                                    <a href={cta.url} className='breadcrumbs__button' target={cta.target ? cta.target : ''}>
                                        <span>{cta.name}</span>
                                        <IconArrowRight />
                                    </a>
                                )}
                                {download && !restyling && (
                                    <div>
                                        <ButtonCta label={downloadText ? downloadText : ''} path={download.umbracoFile ? download.umbracoFile : download.file} target="_blank" />
                                    </div>
                                )}
                                {download && restyling && (
                                    <a href={download.umbracoFile ? download.umbracoFile : download.file} className='breadcrumbs__button'>
                                        <span>{downloadText ? downloadText : ''}</span>
                                        <IconArrowRight />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="progress-bar" id="progress-bar"></div>
                    </div>
                    :
                    <div
                        id="breadcrumbs-footer"
                        className={`module breadcrumbs breadcrumbs--fixed breadcrumbs--fixed-footer ${className} ${restyling ? 'breadcrumbs--restyling' : ''}`}
                    >
                        <div className='grid'>
                            <div className="breadcrumbs__container">
                                <div className="breadcrumbs__nav">
                                    <nav>
                                        {firstLevel && (
                                            <a className="taglabel" href={firstLevel.link}>
                                                {firstLevel.name}
                                            </a>
                                        )}
                                        {secondLevel && (
                                            <a className="taglabel" href={secondLevel.link}>
                                                {secondLevel.name}
                                            </a>
                                        )}
                                        {thirdLevel && (
                                            <a className="taglabel" href={thirdLevel.link}>
                                                {thirdLevel.name}
                                            </a>
                                        )}
                                        {fourthLevel && (
                                            <a className="taglabel" href={fourthLevel.link}>
                                                {fourthLevel.name}
                                            </a>
                                        )}
                                        {fithLevel && (
                                            <a className="taglabel" href={fithLevel.link}>
                                                {fithLevel.name}
                                            </a>
                                        )}
                                    </nav>
                                    {backLink && !button && <Link type="back" label={culture === 'es' ? "Volver" : "Back"} path={backLink} />}
                                </div>
                                <div className="header-cta">
                                    <div className="header-cta__subheader">
                                        {logo &&
                                            <div className='breadcrumbs__logo-container'>
                                                <img src={logo.url} alt={logo.alt} className='breadcrumbs__logo' />
                                            </div>
                                        }
                                        <p className={!restyling ? 'title--m' : 'title--s'}>{h1 ? h1 : pageTitle}</p>
                                        {(sector || jobPlace || dateFormat) && (
                                            <div className="job-subheader">
                                                {sector && <span className="taglabel">
                                                    <a className="job-sector" title={sector} href="#">
                                                        {icon && <IconEnergy />}
                                                        {sector}
                                                    </a>
                                                </span>}
                                                {jobPlace && (
                                                    <span className="taglabel">
                                                        <a href="#" className="job-sector">
                                                            {jobPlace}
                                                        </a>
                                                    </span>
                                                )}
                                                <span className="taglabel">
                                                    <IconCalendar />
                                                    {jobDate}{' '}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {anchors && !cta && (
                                        <div className="anchors">
                                            {anchors.map((anchor, idx) => {
                                                return (
                                                    <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                                        {anchor.caption}
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {anchors && cta && (
                                        <div className="anchors">
                                            {anchors.map((anchor, idx) => {
                                                return (
                                                    <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                                        {anchor.caption}
                                                    </a>
                                                );
                                            })}
                                            {cta && !restyling && (
                                                <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />
                                            )}
                                            {cta && restyling && (
                                                <a href={cta.url} className='breadcrumbs__button' target={cta.target ? cta.target : ''}>
                                                    <span>{cta.name}</span>
                                                    <IconArrowRight />
                                                </a>
                                            )}
                                        </div>
                                    )}
                                    {cta && !anchors && !restyling && (
                                        <div>
                                            <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />
                                        </div>
                                    )}
                                    {cta && !anchors && restyling && (
                                        <a href={cta.url} className='breadcrumbs__button' target={cta.target ? cta.target : ''}>
                                            <span>{cta.name}</span>
                                            <IconArrowRight />
                                        </a>
                                    )}
                                    {download && !restyling && (
                                        <div>
                                            <ButtonCta label={downloadText ? downloadText : ''} path={download.umbracoFile ? download.umbracoFile : download.file} target="_blank" />
                                        </div>
                                    )}
                                    {download && restyling && (
                                        <a href={download.umbracoFile ? download.umbracoFile : download.file} className='breadcrumbs__button'>
                                            <span>{downloadText ? downloadText : ''}</span>
                                            <IconArrowRight />
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="progress-bar" id="progress-bar"></div>
                        </div>
                    </div>
                }
            </>
        );
    }
    if (type === 'detalle-noticia') {
        return (
            <div ref={moduleRef} className={`module grid breadcrumbs ${restyling ? 'breadcrumbs--restyling' : ''}`}>
                <div className="breadcrumbs__container">
                    <div className="breadcrumbs__nav">
                        <nav>
                            {firstLevel && (
                                <a className="taglabel" href={firstLevel.link}>
                                    {firstLevel.name}
                                </a>
                            )}
                            {secondLevel && (
                                <a className="taglabel" href={secondLevel.link}>
                                    {secondLevel.name}
                                </a>
                            )}
                            {thirdLevel && (
                                <a className="taglabel" href={thirdLevel.link}>
                                    {thirdLevel.name}
                                </a>
                            )}
                            {fourthLevel && (
                                <a className="taglabel" href={fourthLevel.link}>
                                    {fourthLevel.name}
                                </a>
                            )}
                            {fithLevel && (
                                <a className="taglabel" href={fithLevel.link}>
                                    {fithLevel.name}
                                </a>
                            )}
                        </nav>
                        {backLink && <Link type="back" label={culture === 'es' ? "Volver" : "Back"} path={backLink} />}
                    </div>
                    {pageTitle && (
                        <div className="header-cta">
                            <div className="header-cta__subheader">
                                <h1 className="title--m">{h1 ? h1 : pageTitle}</h1>
                                <div className="job-subheader">
                                    {/*sector &&
                                        <div className="related-solutions">
                                            {sector.map((solution, idx) => {
                                                return (
                                                    <div className="align-withIcon">
                                                        {solution.icon && IconsMap[solution.icon]}
                                                        <span className="taglabel">
                                                            <a className="job-sector" title={solution.name} href={solution.url} key={`sector-key-${idx}`}>

                                                                {solution.name}
                                                            </a>
                                                            {(idx === 0 || idx !== sector.length - 1) && sector.length > 1 && <span>,&nbsp;</span>}
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    */}
                                    {jobPlace && (
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                    )}
                                    <div className="align-withIcon">
                                        <IconCalendar />
                                        <span className="taglabel">

                                            {jobDate}{' '}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {descriptiveText && (
                    <div className="intro grid-container">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: descriptiveText }}></div>
                    </div>
                )}
                {suscribe && (
                    <div className="mt-3 mobile-visible">
                        <Link type="subscribe" label="subscribirse" />
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div ref={moduleRef} className={`module grid breadcrumbs ${type === 'Solutions-Technologies' ? 'breadcrumbs--restyling-techandsol' : ''} ${restyling ? 'breadcrumbs--restyling' : ''} `}>
                <div className="breadcrumbs__container slow">
                    <div className="breadcrumbs__nav slow">
                        <nav>
                            {firstLevel && (
                                <a className="taglabel" href={firstLevel.link}>
                                    {firstLevel.name}
                                </a>
                            )}
                            {secondLevel && (
                                <a className="taglabel" href={secondLevel.link}>
                                    {secondLevel.name}
                                </a>
                            )}
                            {thirdLevel && (
                                <a className="taglabel" href={thirdLevel.link}>
                                    {thirdLevel.name}
                                </a>
                            )}
                            {fourthLevel && (
                                <a className="taglabel" href={fourthLevel.link}>
                                    {fourthLevel.name}
                                </a>
                            )}
                            {fithLevel && (
                                <a className="taglabel" href={fithLevel.link}>
                                    {fithLevel.name}
                                </a>
                            )}
                        </nav>
                        {backLink && <Link type="back" label={culture === 'es' ? "Volver" : "Back"} path={backLink} />}
                    </div>
                    <h1 className="title--s slow">
                        {h1 ? h1 : pageTitle}
                        {suscribe && (
                            <div className="mobile-hidden slow">
                                <Link type="subscribe" label="subscribirse" />
                            </div>
                        )}
                    </h1>
                    {sector && (
                        <div className="header-cta">
                            <div className="header-cta__subheader">
                                <div className="job-subheader">
                                    <span className="taglabel">
                                        <a className="job-sector" title={sector} href="#">
                                            {icon && <IconEnergy />}
                                            {sector}
                                        </a>
                                    </span>
                                    {jobPlace && (
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                    )}
                                    <span className="taglabel">
                                        <IconCalendar />
                                        {jobDate}{' '}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {descriptiveText && (
                    <div className="intro grid-container slow">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: descriptiveText }}></div>
                    </div>
                )}
                {suscribe && (
                    <div className="mt-3 mobile-visible">
                        <Link type="subscribe" label="subscribirse" />
                    </div>
                )}
                {search && (
                    <div className="input-wrapper grid-container slow">
                        <div className="input-btn">
                            <form onSubmit={handleSearchSubmit}>
                                <input type="text" name="search" className="search" defaultValue={search} />
                                <button className="search-btn" type="submit">
                                    <IconSearch />
                                </button>
                            </form>
                        </div>
                    </div>
                )}
                {labelNoResults && (
                    <div className="intro grid-container">
                        <p className="body--l slow--y">{labelNoResults}</p>
                    </div>
                )}
                {/*legend && (
                    <p className="legend link slow">
                        {legend}
                        <IconDireccion />
                    </p>
                )*/}
            </div>
        );
    }
};

export default Breadcrumbs;
