import React, { useState } from 'react';
import { CheckboxGroup, Checkbox, RadioButton, TextInput, SelectInput, Button, Lightbox, Wysiwyg, } from 'React/components';
import { ReactComponent as CrossBig } from 'Icons/cross-32.svg';
import './styles.scss';
import { isForbiddenCountry, isInvestorCountry } from 'React/helpers';
import { countryListES, countryList } from './CountriesList';
import {
    description1ES, description1EN, countryResidenceES, countryResidenceEN, physicallyPresentES, physicallyPresentEN,
    description2ES, description2EN, physicallyResidentRadioButtonES, physicallyResidentRadioButtonEN, iHaveReadES,
    iHaveReadEN, forbiddenES, forbiddenEN, titleES, titleEN, investorTextES, investorTextEN, investorLegalTextES, investorLegalTextEN
} from './Translations';

// firstStep prop is used for internal validation only
const PopUpLegalInvestor = ({ firstStep = 'initial', culture }) => {
    const [formStep, setFormStep] = useState(firstStep);

    const [visibleLightbox, setVisibleLightbox] = useState(true);

    const [countryResidenceError, setCountryResidenceError] = useState('');
    const [actualCountryResidenceError, setActualCountryResidenceError] = useState('');
    const [residentError, setResidentError] = useState('');
    const [legalPermissionError, setLegalPermissionError] = useState('');
    const [investorError, setInvestorError] = useState('');
    const [blockAcceptButton, setBlockAcceptButton] = useState(true);

    const backToHome = () => {
        culture === 'es' ? window.location = "/es/" : window.location = "/";
    };

    const handleSubmit1 = (e) => {
        e.preventDefault();

        var validationErrors = false;

        var countryResidence = e.target.countryResidence.value;
        var actualCountryResidence = e.target.actualCountryResidence.value;

        if (countryResidence) {
            setCountryResidenceError("");
        }
        else {
            validationErrors = true;
            setCountryResidenceError(culture === 'es' ? "Debe seleccionar un país" : " You must select a country");
        }

        if (actualCountryResidence) {
            setActualCountryResidenceError("");
        }
        else {
            validationErrors = true;
            setActualCountryResidenceError(culture === 'es' ? "Debe seleccionar un país" : " You must select a country");
        }

        if (!validationErrors) {
            if (isForbiddenCountry(countryResidence) || isForbiddenCountry(actualCountryResidence)) {
                setFormStep("forbidden");
            }
            else {
                //if (isInvestorCountry(countryResidence) || isInvestorCountry(actualCountryResidence)) {
                //    setIsInvestor(true);
                //}

                setFormStep("accept");
            }
        }
    };

    const handleSubmit2 = (e) => {
        e.preventDefault();

        var validationErrors = false;

        var resident = e.target.resident.value;
        var legalPermission = e.target.legalPermission.value;
        var investor = e.target.investor ? e.target.investor.value : '';

        if (resident) {
            setResidentError("");
        }
        else {
            validationErrors = true;
            setResidentError(culture === 'es' ? "Debe seleccionar una opción" : "You must select an option ");
        }

        if (legalPermission) {
            setLegalPermissionError("");
        }
        else {
            validationErrors = true;
            setLegalPermissionError(culture === 'es' ? "Debe seleccionar una opción" : "You must select an option ");
        }

        if (investor) {
            setInvestorError("");
        }
        else {
            validationErrors = true;
            setInvestorError(culture === 'es' ? "Debe seleccionar una opción" : "You must select an option ");
        }

        if (!validationErrors) {
            if (resident.toLowerCase() === "no" || legalPermission.toLowerCase() === "no") {
                setFormStep("forbidden");
            }
            else if (investor.toLowerCase() === "yes") {
                setFormStep("investor");
            }
            else {
                closePopup();
            }
        }
    };

    const closePopup = () => {
        setVisibleLightbox(false);
        document.querySelector('.app--layout').dataset.canScroll = true;
    }

    const openDownloadLegalFile = () => {
        window.open("https://mediacdn.acciona.com/media/b4ydly22/non-connected-analysts-research-reports-guidelines.pdf", "_blank", 'location=yes,scrollbars=yes,status=yes');
        setBlockAcceptButton(false);
    }

    return (
        <Lightbox isVisible={visibleLightbox} hideCloseButton>
            {formStep === 'initial' &&
                <form className="module grid contact_form one-column" onSubmit={handleSubmit1}>
                    <div className="form_header">
                        <legend className="form_title title--l">{culture === 'es' ? titleES : titleEN}</legend>
                        <div className="form_desciption body--m" dangerouslySetInnerHTML={{ __html: culture === 'es' ? description1ES : description1EN }} />
                    </div>
                    <fieldset className="form_inputs">
                        <p className="form_section_title body--l"></p>
                        <SelectInput label={culture === 'es' ? countryResidenceES : countryResidenceEN}
                            name="countryResidence"
                            options={culture === 'es' ? countryListES : countryList}
                            defaultValue={culture === 'es' ? "Selecciona un país" : "Select a country"}
                            errorMsg={countryResidenceError} />
                        <p className="form_section_title body--l"></p>
                        <SelectInput label={culture === 'es' ? physicallyPresentES : physicallyPresentEN}
                            name="actualCountryResidence"
                            options={culture === 'es' ? countryListES : countryList}
                            defaultValue={culture === 'es' ? "Selecciona un país" : "Select a country"}
                            errorMsg={actualCountryResidenceError} />
                        <p className="form_section_title body--l"></p>
                        {typeof window !== 'undefined' && window.location.pathname !== '/es/' && window.location.pathname !== '/' &&
                            <div className="form_controls_wrapper">
                                <Button
                                    type="submit"
                                    className="form_submit"
                                    label={culture === 'es' ? "Aceptar" : "Accept"}
                                    size="button--lg"
                                    style_type="red"
                                />
                                <Button className="form_close" label={culture === 'es' ? "Salir" : "Exit"} size="button--lg" onClick={() => backToHome()} />
                            </div>
                        }
                        {typeof window !== 'undefined' && (window.location.pathname === '/es/' || window.location.pathname === '/') &&
                            <div className="form_controls_wrapper" style={{ maxWidth: '176px' }}>
                                <Button
                                    type="submit"
                                    className="form_submit"
                                    label={culture === 'es' ? "Aceptar" : "Accept"}
                                    size="button--lg"
                                    style_type="red"
                                />
                            </div>
                        }
                    </fieldset>
                </form>
            }
            {formStep === 'accept' &&
                <form className="module grid contact_form one-column" onSubmit={handleSubmit2}>
                    <div className="form_header">
                        <legend className="form_title title--l">{culture === 'es' ? titleES : titleEN}</legend>
                        <div className="form_desciption body--m" dangerouslySetInnerHTML={{ __html: culture === 'es' ? description2ES : description2EN }} />
                    </div>
                    <fieldset className="form_inputs">
                        <RadioButton
                            label={culture === 'es' ? physicallyResidentRadioButtonES : physicallyResidentRadioButtonEN}
                            name="resident"
                            errorMsg={residentError}
                            options={[{ value: 'yes', label: culture === 'es' ? 'sí' : 'yes' }, { value: 'no', label: 'no' }]}
                            required
                        />
                        <RadioButton
                            label={culture === 'es' ? iHaveReadES : iHaveReadEN}
                            name="legalPermission"
                            errorMsg={legalPermissionError}
                            options={[{ value: 'yes', label: culture === 'es' ? 'sí' : 'yes' }, { value: 'no', label: 'no' }]}
                            required
                        />
                        <RadioButton
                            label={culture === 'es' ? investorTextES : investorTextEN}
                            name="investor"
                            errorMsg={investorError}
                            options={[{ value: 'yes', label: culture === 'es' ? 'sí' : 'yes' }, { value: 'no', label: 'no' }]}
                            required
                        />
                        <p className="form_section_title body--l"></p>
                        <div className="form_controls_wrapper">
                            <Button
                                type="submit"
                                className="form_submit"
                                label={culture === 'es' ? "Aceptar" : "Accept"}
                                size="button--lg"
                                style_type="red"
                            />
                            <Button className="form_close" label={culture === 'es' ? "Salir" : "Exit"} size="button--lg" onClick={() => backToHome()} />
                        </div>
                    </fieldset>
                </form>
            }
            {formStep === 'investor' &&
                <form className="module grid contact_form one-column"
                    onSubmit={(e) => {
                        e.preventDefault();
                        closePopup();
                    }}
                >
                    <div className="form_header">
                        <legend className="form_title title--l">Memorandum for Non-Connected Analysts relating to the preparation and distribution of Company Research Reports by Non-Connected Analysts</legend>
                        <div className="form_desciption body--m">This memorandum dated [6 May] 2021 (the “Memorandum”) addresses the procedures and legal considerations relating to the preparation, dissemination and use of research reports  about Corporación Acciona Energías Renovables, S.A. Unipersonal, and its subsidiaries (the “Company”) by the analysts who have been invited to attend the capital markets day presentation of the Company on 7 May 2021 (the “Non-Connected Analysts”).</div>
                    </div>
                    <p className="must-download-link"><a href="#" onClick={openDownloadLegalFile}>To accept you must open and read this PDF</a></p>
                    <p className="form_section_title body--l"></p>
                    <div className="form_controls_wrapper c-button-form">
                        <Button
                            type="submit"
                            className="form_submit"
                            label={culture === 'es' ? "Aceptar" : "Accept"}
                            size="button--lg"
                            style_type="red"
                            disabled={blockAcceptButton}
                        />
                    </div>
                </form>
            }
            {formStep === 'forbidden' &&
                <div className="form_feedback">
                    <div className="feedback-msg">
                        <div className="icon-container">
                            <div className="centerIcon"><CrossBig className="validation-fail forbidden" width="48" height="48" /></div>
                        </div>
                        <h3 className="body--l">{culture === 'es' ? titleES : titleEN}</h3>
                        <h4 className="body--m">{culture === 'es' ? forbiddenES : forbiddenEN}</h4>
                        <div className="form_controls_wrapper">
                            <br /><br />
                            <Button className="form_close" label={culture === 'es' ? "Salir" : "Exit"} size="button--lg" onClick={() => backToHome()} style_type="red" width="100px" />
                        </div>
                    </div>
                </div>
            }
        </Lightbox>
    );
};

export default PopUpLegalInvestor;
