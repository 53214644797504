// button.component.js
import React, { useState } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import './Table.scss';

import { rndKeyGen, replaceAll } from 'React/helpers';
import { Link, Lightbox, Wysiwyg, TagTitle, EnlaceAgrupador } from 'React/components';

const Table = ({
    isCargos,
    tableMargin,
    text,
    bulletsTitle,
    bullets,
    headerDownloads,
    groupLink,
    singleDownload,
    tagTitle,
    tableType,
    cta,
    className = '',
    logo,
    subModules,
    headers,
    totals,
    footerDownload,
    anchor
}) => {
    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');

    let moduleRef = React.createRef();
    useSlowFade(moduleRef);

    const tableHeaders = headers ? headers.map((header, idx) => (
        <th className="taglabel" key={`header-${idx}`}>
            {header === 'null' ? '' : header}
        </th>
    )) : null;

    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

    const classReduced = tableMargin === 'reducido' ? 'reduced-spacing'
        : '';

    if (text) {
        text = replaceAll(text, "https://accionacorp.blob.core.windows.net", "https://mediacdn.acciona.com");
        if (text.includes('src="/media/')) text = replaceAll(text, 'src="/media/', 'src="https://mediacdn.acciona.com/media/');
        if (text.includes('href="/media/')) text = replaceAll(text, 'href="/media/', 'href="https://mediacdn.acciona.com/media/');
    }

    if (isCargos) {
        return (
            <div ref={moduleRef} className={`module grid module-table ${classReduced} ${className}`}>
                <div className="grid-container">
                    {tagTitle && <TagTitle style="slow--y" title={tagTitle} />}
                    <span className="custom-anchor" id={anchor} />
                    <div className="table-container slow--y">
                        <table>
                            <thead>
                                <tr>
                                    {tableHeaders}
                                </tr>
                            </thead>
                            <tbody>
                                {subModules.map(({ subModules }, idx) => {

                                    return (
                                        <tr key={`row-${idx}`}>
                                            {subModules.map((cell, idx) => {
                                                const { _documentType, text, className, download, position, positionCta, lightboxText, content } = cell;
                                                return (
                                                    <td className={`body--s ${className}`} key={`cell-${idx}`}>
                                                        {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
                                                        {download && !download.umbracoFile && <Link type="download" label={download.recordNumber ? download.recordNumber : download.title} path={download.file} />}
                                                        {download && download.umbracoFile && <Link type="download" label={download.name} path={download.umbracoFile} bytes={download.umbracoBytes} extension={download.umbracoExtension} />}
                                                        {positionCta && <Link type="arrow" className="cargo" label={positionCta.caption} path={positionCta.link} />}
                                                        {content && lightboxText && <span onClick={() => handleLightboxClick(content)}><Link label={lightboxText} type='lightbox' /></span>}
                                                        {!content && lightboxText && <span className="link link--icon icon--red link--lightbox">{lightboxText}</span>}
                                                        {position && <p className="body--s">{position}</p>}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 logo-container slow--y">
                        {cta && <Link type="arrow" label={cta.caption} path={cta.link} />}
                        {footerDownload && footerDownload.umbracoFile && <Link type="download" label={footerDownload.name ? footerDownload.name : ''} path={footerDownload.umbracoFile} bytes={footerDownload.umbracoBytes} extension={footerDownload.umbracoExtension} />}
                        {footerDownload && !footerDownload.umbracoFile && <Link type="download" label={footerDownload.recordNumber ? footerDownload.recordNumber : footerDownload.title} path={footerDownload.file} />}
                        {logo && (
                            <div className="logo">
                                <img src={logo.url} alt={logo.alt} title={logo.title} />
                            </div>
                        )}
                    </div>
                    {text && (
                        <div className="text-container slow--y">
                            <div className="body--s" dangerouslySetInnerHTML={{ __html: text }}></div>
                        </div>
                    )}
                </div>
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLightbox}
                    closeMethod={() => setVisibleLightbox(null)}
                >
                    <Wysiwyg html={lightboxContent} />
                </Lightbox>
            </div>
        );
    } else {
        return (
            <div ref={moduleRef} className={`module grid module-table ${classReduced} ${className}`}>
                <div className="grid-container" >
                    {tagTitle && <TagTitle style="slow--y" title={tagTitle} />}
                    {headerDownloads && !groupLink && (
                        <div className="header-downloads slow--y">
                            {headerDownloads.map((download, idx) => {
                                if (download.umbracoFile) {
                                    return (
                                        <Link type="download" label={download.name ? download.name : ''} path={download.umbracoFile} bytes={download.umbracoBytes} extension={download.umbracoExtension} />
                                    );
                                }
                                else {
                                    return (
                                        <Link type="download" label={download.recordNumber ? download.recordNumber : download.title} path={download.file} bytes={download.umbracoBytes} extension={download.umbracoExtension} />
                                    );
                                }
                            })}
                        </div>
                    )}
                    {headerDownloads && groupLink &&(
                        <div className="header-downloads slow--y">
                            <div><EnlaceAgrupador links={headerDownloads} box_label={groupLink} /></div>
                        </div>
                    )}
                    <span className="custom-anchor" id={anchor} />
                    <div className="table-container slow--y">
                        <table>
                            <thead>
                                <tr>{tableHeaders}</tr>
                            </thead>
                            <tbody>
                                {subModules.map(({ subModules }, idx) => {
                                    return (
                                        <tr key={`row-${idx}`}>
                                            {subModules.map((cell, idx) => {
                                                const { _documentType, text, className, download } = cell;
                                                return (
                                                    <td className={`body--s ${className}`} key={`cell-${idx}`}>
                                                        {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
                                                        {download && download.umbracoFile && <Link type="download" label={download.name ? download.name : ''} path={download.umbracoFile} bytes={download.umbracoBytes} extension={download.umbracoExtension} />}
                                                        {download && !download.umbracoFile && <Link type="download" label={download.recordNumber ? download.recordNumber : download.title} path={download.file} />}

                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                            {totals && totals.length > 0 && (
                                <tfoot>
                                    <tr>
                                        <td className="body--l">Total</td>
                                        {totals.map((total, idx) => {
                                            return (
                                                <td key={`total-${idx}`} className="body--l">{total}</td>
                                            );
                                        })}
                                    </tr>
                                </tfoot>
                            )}
                        </table>
                    </div>
                    {text && (
                        <div className="text-container slow--y">
                            <div className="body--s" dangerouslySetInnerHTML={{ __html: text }}></div>
                        </div>
                    )}
                    {bullets && bullets.length > 0 && (
                        <div className="bullets-container">
                            {bulletsTitle &&
                                <h2 className="body--l slow--y">{bulletsTitle}</h2>
                            }

                            <ul className="list">
                                {bullets.map((bullet, idx) => {
                                    return (
                                        <li className="body--s slow--y" key={`table-bullet-${idx}`}>{bullet}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {cta && <div className="mt-4 slow--y"><Link label={cta.caption} path={cta.link} type={cta.type} /></div>}
                </div>

            </div>
        );
    }
};

export default Table;
