import React, { useState, useEffect } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import './styles.scss';
import { TagTitle, InputRangeSlider, Lightbox, Wysiwyg, Link, Button } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { useSliderSwipe, useWindowSize } from 'React/custom_hooks';
import { ReactComponent as CloseIcon } from 'Icons/cross.svg';
import { rndKeyGen } from 'React/helpers';
import ReactMediumImg from 'react-medium-zoom';

const LogosSlider = ({ module_title, module_description, module_cta, subModules, isLightbox, anchor }) => {

    const [activeFrame, setActiveFrame] = useState(1);
    const thisModule = React.createRef();
    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');

    useSlowFade(thisModule);

    // onresize render hook
    const size = useWindowSize();

    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const handleLightboxClick = (popUpContent) => {
        if (popUpContent !== '') {
            setLightboxContent(popUpContent);
            setVisibleLightbox(true);
        }
    };

    // slider functionality
    const calculateFrames = (logosCount) => {
        const arr = [];

        var getLength = function getLength() {

            if (size.width <= 680) {
                return logosCount;
            }

            else if (size.width > 680 && size.width <= 1024) {
                if (logosCount % 2 !== 0) {
                    return parseInt(logosCount / 2) + 1
                }
                else if (logosCount % 2 === 0) {
                    return logosCount / 2
                }
            }

            else if (size.width > 1024) {
                if (logosCount <= 4) return 1;
                if (logosCount % 4 !== 0) return parseInt(logosCount / 4) + 1;
                if (logosCount % 4 === 0) return parseInt(logosCount) / 4;
            }
        };

        for (var i = 0; i < getLength(); i++) {
            arr.push('x');
        }

        return arr;
    };

    const frames = calculateFrames(subModules.length);


    const frameLogoSets = subModules.reduce((acc, logo, idx) => {

        let logosPerFrame;

        if (size.width <= 680) {
            logosPerFrame = 1
        }
        else if (size.width < 1025) {
            logosPerFrame = 2
        }

        else if (size.width > 1024) {
            logosPerFrame = 3
        };

        const chunkIdx = Math.floor(idx / logosPerFrame);

        if (!acc[chunkIdx]) {
            acc[chunkIdx] = [];
        }

        acc[chunkIdx].push(logo);

        return acc;
    }, []);

    const sliderWidth = (framesCount) => {
        if (size.width < 1024) {
            return `${100 * framesCount}vw`;
        }
        else if (size.width >= 1024) {
            return `${100}%`;
        }
    };

    const sliderOffset = () => {
        if (size.width < 1024) return `translateX(${100 * (activeFrame - 1) * -1}vw)`;

        return `translateX(${100 * (activeFrame - 1) * -1}%)`;
    };

    const frameWidth = (framesCount) => {
        if (size.width < 1024) {
            return "100vw"
        }

        else if (size.width >= 1024) {
            return "100%"
        }
    };

    const framesContainerStyle = {
        width: sliderWidth(frames.length),
        transform: sliderOffset(frames.length),
    };

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < frames.length) {
            setActiveFrame(activeFrame + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(activeFrame - 1);
        }
    };


    const [modalIsVisible, setModalVisibility] = useState(false);

    const showLightbox = (frameId = 1) => {
        setModalVisibility(true);
        setActiveFrame(frameId);
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const hideLightbox = () => {
        setModalVisibility(false);
        setActiveFrame(1);
        document.querySelector('.app--layout').dataset.canScroll = true;
    };

    useSliderSwipe(thisModule, setActiveFrame, frames.length);

    const framesLength = frames.length;

    return (
        <div className="module grid logos-slider with-slider" data-active-frame={activeFrame} ref={thisModule}>
            {frames.length > 1 && (
                <>
                    <button
                        className="ghost-frame-button ghost-frame-button--left"
                        onMouseOver={prevHover}
                        onMouseOut={removeHover}
                        onClick={prevFrame}
                    >
                        Slider anterior
                    </button>
                    <button
                        className="ghost-frame-button ghost-frame-button--right"
                        onMouseOver={nextHover}
                        onMouseOut={removeHover}
                        onClick={nextFrame}
                    >
                        Slider siguiente
                    </button>
                </>
            )}
            <div className="slow--y">
                <div className="header grid">
                    {module_title &&
                        <TagTitle title={module_title} />
                    }
                    {module_cta && size.width >= 1024 && (
                        <Link className="module-cta" path={module_cta.link} type={module_cta.type} label={module_cta.caption} target={module_cta.target} />
                    )}
                    {module_description &&
                        <div className="module-body body--m" dangerouslySetInnerHTML={{ __html: module_description }}></div>
                    }
                </div>

                <div className="frames-container" style={framesContainerStyle}>
                    {frames.map((frame, idx) => {
                        return (
                            <div
                                className="frame grid"
                                data-frame-id={idx + 1}
                                key={`logo-frame-${idx}`}
                                style={{ width: frameWidth(subModules.length) }}
                            >
                                {frameLogoSets && frameLogoSets[idx].map((logo, idx) => {
                                    const { image, alt_text, description, cta, isLightbox, popUpContent, textLinkLightbox } = logo;

                                    return (
                                        <div className="logo-container" key={`logo-container-${idx}`}>
                                            <img className="logo-image" src={image ? image.url : ''} alt={image ? image.alt : ''} title={image ? image.title : ''} />
                                            {description && <p className="description body--m">{description}</p>}
                                            {/*  {isLightbox && popUpContent ?
                                                <span onClick={() => handleLightboxClick(popUpContent)}>
                                                    <Link className="logo-cta iconplus-link" type='lightbox' label={textLinkLightbox ? textLinkLightbox : ''} />
                                                </span>
                                                : <Link className="logo-cta iconplus-link" 
                                                        path={cta ? cta.link : ''} 
                                                        type={cta ? cta.type : ''} 
                                                        label={cta ? cta.caption : ''} 
                                                        target={cta ? cta.target : ''} />
                                            } */}

                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <span className="custom-anchor-small" id={anchor} />

                </div>


                <InputRangeSlider
                    changeHandler={setActiveFrame}
                    framesCount={framesLength}
                    activeFrame={parseInt(activeFrame)}
                    noIndicator={true}
                    inputRangeSliderId={"range-input-slider"}
                    isParentLogoSlider={true}
                />

                {subModules.length > 4 &&
                    <div className="slider-controls slow--y">
                        <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                            <IconArrowRightLong />
                        </button>
                        <button ref={nextButton} className="next-button" onClick={nextFrame}>
                            <IconArrowRightLong />
                        </button>
                    </div>
                }
                {module_cta && size.width < 1024 && (
                    <Link className="module-cta slow--y" path={module_cta.link} type={module_cta.type} label={module_cta.caption} target={module_cta.target} />
                )}
            </div>
            <Lightbox
                key={rndKeyGen()}
                isVisible={visibleLightbox}
                closeMethod={() => setVisibleLightbox(null)}
            >
                <Wysiwyg html={lightboxContent} />
            </Lightbox>
        </div>
    );
};

export default LogosSlider;
