import React, { useState, useEffect } from 'react';

import { Link } from 'React/components';
import { ReactComponent as IconArrowLong } from 'Icons/ArrowLong.svg';
import LogoFull from 'Images/logos/logo_full.svg';
import { IconsSocialMedia } from '../components/Icons/Icons';
import { rndKeyGen } from '../helpers';

import './FooterRestyling.scss';

let carouselTimeout;


const FooterRestyling = ({
  isSimpleFooter,
  altTextLogo,
  image,
  items,
  links,
  copyright,
  rrss,
  ethicChannel,
}) => {

  const [array, setArray] = useState(undefined);
  const [carousel, setCarousel] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);


  const moveCarouselLeft = () => {
    if (carousel > 0) setCarousel(carousel - 1);
  }

  const moveCarouselRight = () => {
    if (carousel < items.length - 1) setCarousel(carousel + 1);
  }

  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }


  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && carousel < items.length - 1) {
      setCarousel(carousel + 1);
    } else if (isRightSwipe && carousel > 0) {
      setCarousel(carousel - 1);
    }
  }

  const getPosition = (e) => {

    const eventDoc = (e.target && e.target.ownerDocument) || document;
    const doc = eventDoc.documentElement;
    const body = eventDoc.body;

    return e.clientX +
      (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
      (doc && doc.clientLeft || body && body.clientLeft || 0);

  }

  const onMouseDown = (e) => {
    setTouchEnd(null)
    setTouchStart(getPosition(e));
  }

  const onMouseUp = (e) => {
    const posX = getPosition(e);

    if (!touchStart) return
    const distance = touchStart - posX
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && carousel < items.length - 1) {
      setCarousel(carousel + 1);
    } else if (isRightSwipe && carousel > 0) {
      setCarousel(carousel - 1);
    }
  }

  const shuffle = ({ array }) => {
    let currentIndex = array.length, randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  useEffect(() => {
    if (!array && items) {
      setArray(shuffle({ array: items }))
    }
  }, [items]);

  useEffect(() => {
    clearTimeout(carouselTimeout);
    carouselTimeout = setTimeout(() => {
      const next = carousel + 1 > items.length - 1 ? 0 : carousel + 1;
      setCarousel(next);
    }, 4500);
  }, [carousel]);


  return (
      <footer className='module footer-restyling'>
          {(!isSimpleFooter) &&
              <div className='footer-restyling__top'>
                  <picture className='footer-restyling__picture'>
                      <img src={image.src} alt={image.alt} className='footer-restyling__image' />
                  </picture>
                  <div className='grid footer-restyling__top-grid'>
                      <div className='footer-restyling__top-content'>
                          <div className='footer-restyling__list-container'>
                              <ul className='footer-restyling__list' style={{ left: `-${carousel * 100}%`, width: `${items.length * 100}%` }} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                                  {array && array.map((el, index) =>
                                      <li className={`footer-restyling__list-element ${carousel === index ? 'footer-restyling__list-element--active' : ''}`} style={{ width: `${100 / items.length}%` }}>
                                          <h2 className='title--l footer-restyling__title'>{el.title}</h2>
                                          {el.links && el.links.map((link) =>
                                              <Link className="footer-restyling__list-link" type='arrow' path={link.url} iconColor='icon--white' title={link.title} alt={link.title} children={link.text} />
                                          )}
                                      </li>
                                  )}
                              </ul>
                          </div>
                          {items.length > 1 &&
                              <div className="footer-restyling__slider">
                                  <div className="footer-restyling__slider-bar" aria-hidden={true}>
                                      <ul className="footer-restyling__slider-list">
                                          {items.map((item, index) =>
                                              <li key={rndKeyGen()} className='footer-restyling__slider-list-element' style={{ width: `${100 / items.length}%` }}>
                                                  <button type='button' className='footer-restyling__slider-list-button' onClick={() => setCarousel(index)} disabled={carousel === index}>
                                                      Ir al elemento {index} del slider
                                                  </button>
                                              </li>
                                          )}
                                      </ul>
                                      <span className="footer-restyling__slider-bar--active" aria-hidden={true} style={{ width: `${100 / items.length}%`, left: `${(100 / items.length) * carousel}%` }}></span>
                                  </div>
                                  <button type='button' className={`footer-restyling__slider-button footer-restyling__slider-button--left ${carousel === 0 ? 'footer-restyling__slider-button--disabled' : ''}`} onClick={moveCarouselLeft}><IconArrowLong /></button>
                                  <button type='button' className={`footer-restyling__slider-button ${carousel === items.length - 1 ? 'footer-restyling__slider-button--disabled' : ''}`} onClick={moveCarouselRight}><IconArrowLong /></button>
                              </div>
                          }
                      </div>
                  </div>
              </div>
          }
      <div className='footer-restyling__bottom'>
        <div className='grid footer-restyling__bottom-grid'>
          <Link key={rndKeyGen()} path="/" className="footer-restyling__logo">
                      <img className="footer-restyling__logo-image" src={LogoFull} alt={altTextLogo} title={altTextLogo} />
          </Link>
          <div className='footer-restyling__links'>
            <ul className='footer-restyling__links-list'>
              {links.map((link, index) =>
                <li key={rndKeyGen()} className='footer-restyling__links-element'>
                      <a href={link.url} title={link.title} alt={link.title} className='link footer-restyling__links-anchor'>{link.text}</a>
                </li>
              )}
              {ethicChannel &&
                <li key={rndKeyGen()} className='footer-restyling__links-element'>
                  {ethicChannel.links && ethicChannel.links.map((link, index) =>
                      <a href={link.url} title={link.title} alt={link.title} className='link footer-restyling__links-anchor' target="_blank">{link.text}</a>
                  )}
                </li>
              }
            </ul>
          </div>
          <p className='taglabel footer-restyling__copyright'>{copyright}</p>
          <ul className='footer-restyling__rrss'>
            {rrss.map((link, index) =>
              <li key={rndKeyGen()} className='footer-restyling__rrss-element'>
                    <a className="footer-restyling__rrss-anchor" href={link.url} title={link.title} aria-label={link.title} target="_blank">
                        {IconsSocialMedia[link.icon]}
                    </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default FooterRestyling;
