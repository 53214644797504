import React, { useState, useEffect } from 'react';

import './styles.scss';

import { TagTitle, Lightbox, Link } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';
import { ReactComponent as IconPlus } from 'Icons/iconplus.svg';

import { useSlowFade } from 'React/custom_hooks';

const EquipoDirectivo = ({ title, subModules, labelSeeMoreCountries, labelCountries, legend, secondLegend, isLastComponenet }) => {
    const [activeAudio, setActiveAudio] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [activeCountries, setActiveCountries] = useState(null);

    const showLightbox = (countries) => {
        setActiveCountries(countries[0]);
        setModalIsVisible(true);
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const hideLightbox = () => {
        setModalIsVisible(false);
        document.querySelector('.app--layout').dataset.canScroll = true;
    };


    // slider functionality
    const thisModule = React.createRef();

    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    useSlowFade(thisModule);

    // audio controls
    const audioElementRefs = subModules.map((element) => React.createRef());
    const audioStatsRefs = subModules.map((element) => React.createRef());

    const togglePlay = (frameId) => {
        setActiveAudio((oldState) => (oldState === frameId ? null : frameId));
    };

    useEffect(() => {
        audioElementRefs.forEach((ref) => {
            if (ref.current != null) {
                ref.current.pause();
            }
        });

        if (activeAudio !== null) {
            audioElementRefs[activeAudio - 1].current.play();
        }
    }, [activeAudio]);

    // audio stats
    const formatedAudioTime = (timeFloat) => {
        if (timeFloat === Infinity) return '<i>∞</i>';

        const seconds = parseInt(timeFloat);
        if (seconds < 60) return `0’${seconds}’’`;

        if (seconds >= 60) return `${parseInt(seconds / 60)}’${seconds % 60}’’`;

        return timeFloat;
    };

    useEffect(() => {
        audioElementRefs.forEach((audioEl, idx) => {
            if (audioStatsRefs[idx].current != null) {
                const statsElement = audioStatsRefs[idx].current;
                const [oldTime, oldDuration] = statsElement.innerText.split('/');

                audioEl.current.addEventListener('loadedmetadata', (event) => {
                    const { duration } = event.target;

                    statsElement.innerHTML = `${oldTime} / ${formatedAudioTime(duration)}`;
                });

                audioEl.current.addEventListener('timeupdate', (event) => {
                    const { currentTime, duration } = event.target;

                    statsElement.innerHTML = `${formatedAudioTime(currentTime)} / ${formatedAudioTime(duration)}`;
                });
            }
        });
        return () => {
            audioElementRefs.forEach((audioEl) => {
                audioEl.current.removeEventListener('loadedmetadata');
                audioEl.current.removeEventListener('timeupdate');
            });
        };
    }, []);

    return (
        <div className="module grid equipo-directivo" ref={thisModule}>
            <div className="slow--y equipo-directivo-container grid full-width">
                {title && <TagTitle title={title} />}

                {subModules.map((frame, idx) => {
                    const { title, subtitle, subModules, isDirector, link, countriesText } = frame;
                    return (
                        <div className="frame full-width" key={`tecno-frame-${idx}`}>
                            <h2 className="title--s">{title}</h2>
                            {link &&
                                <h3 className="name link">
                                    <a href={link.link} target={link.target}>{subtitle}</a>
                                    {isDirector &&
                                        <div className="icon-container">
                                            <IconDireccion className="hover-info" />
                                            <div className="info">
                                                {legend}
                                            </div>
                                        </div>
                                    }
                                </h3>
                            }
                            {!link &&
                                <h3 className="name link">
                                    {subtitle}
                                    {isDirector &&
                                        <div className="icon-container">
                                            <IconDireccion className="hover-info" />
                                            <div className="info">
                                                {legend}
                                            </div>
                                        </div>
                                    }
                                </h3>
                            }
                            {/*link &&
                                <div>
                                    <Link type="arrow" label={link.caption} path={link.link} />
                                </div>
                            */}
                            {subModules && subModules.filter((module) => module._documentType === 'ModuleManagementTeamDivisions').length > 0 &&
                                <div className="inner-content">
                                    <ul>
                                        {subModules.filter((module) => module._documentType === 'ModuleManagementTeamDivisions').map((frame, idx) => {
                                            return (
                                                <li className="body--s" key={`key-divisions-${idx}`}>
                                                    <strong>{frame.title}:</strong> {frame.subtitle}
                                                    {frame.isDirector &&
                                                        <div className="icon-container">
                                                            <IconDireccion className="hover-info" />
                                                            <div className="info">
                                                                {legend}
                                                            </div>
                                                        </div>
                                                    }
                                                    {frame.subModules && frame.subModules.length > 0 &&
                                                        <ul className="sub-division">
                                                            {frame.subModules.map((item, idx) => {
                                                                const { title, subtitle, isDirector } = item;
                                                                return (
                                                                    <li className="body--s" key={`key-sub-divisions-${idx}`}>
                                                                        <strong>{title}:</strong> {subtitle}
                                                                        {isDirector &&
                                                                            <div className="icon-container">
                                                                                <IconDireccion className="hover-info" />
                                                                                <div className="info">
                                                                                    {legend}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    }
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            }
                            {subModules.filter((module) => module._documentType === 'ModuleManagementTeamCountries').length > 0 &&
                                <div className="frame-content">
                                    <a href="#" onClick={() => showLightbox(subModules.filter((module) => module._documentType === 'ModuleManagementTeamCountries'))} className="link link--icon icon--red link--lightbox">
                                        <span>
                                            {countriesText ? countriesText : labelSeeMoreCountries}
                                            <IconPlus />
                                        </span>
                                    </a>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
            {modalIsVisible && (
                <Lightbox isVisible={modalIsVisible} closeMethod={hideLightbox}>
                    {activeCountries &&
                        <div className="countries_equipo grid full-width">
                            <div className="grid-container">
                                <div className="header title--l">
                                    <span>{labelCountries}</span>
                                </div>
                                <div className="body">
                                    {activeCountries.subModules.map((country, idx) => (
                                        <div path={country.link ? country.link.link : ''} key={`country-item-${idx}`}>
                                            <div className="title--s">{country.title}</div>
                                            <p className="name link">
                                                {country.subtitle}
                                                {country.isDirector &&
                                                    <div className="icon-container">
                                                        <IconDireccion className="hover-info" />
                                                        <div className="info">
                                                            {legend}
                                                        </div>
                                                    </div>
                                                }
                                            </p>
                                            {country.link &&
                                                <div>
                                                    <Link type="arrow" label={country.link.caption} path={country.link.link} />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                {activeCountries.legend &&
                                    <p className="legend link slow--y">
                                        <IconDireccion />
                                        {activeCountries.legend}
                                    </p>
                                }
                            </div>
                        </div>

                    }
                </Lightbox>
            )}
            {secondLegend && isLastComponenet && (
                <p className="legend link " style={{ marginTop: '10px', whiteSpace: 'nowrap', fontSize: '10px' }}>
                    {secondLegend}
                </p>
            )}
        </div>
    );
};

export default EquipoDirectivo;
