import React, { useEffect, useState, useRef } from 'react';

import { useSlowFade } from 'React/custom_hooks';

import IconsList from './IconsList';
import ModuleContent from './ModuleContent';

import './style.scss';
import { rndKeyGen, handleResize } from 'React/helpers';



const showPicture = ({ technologies, index }) => {
  if (technologies)
    return technologies.subModules.length + index + 1
  return index + 1
}

const contentPosition = ({ technologies, solutions }) => {
  if (technologies && !solutions)
    return 'module-solutions-technologies__contents--right';
  if (!technologies && solutions)
    return 'module-solutions-technologies__contents--left'
  return '';
}

const calculateHeights = ({ setMinHeight }) => {
  let _minH = 0;
  [...document.querySelectorAll('.module-solutions-technologies__wrapper')].map((el) => _minH = Math.max(_minH, el.scrollHeight));
  setMinHeight(_minH);
}

const updateContent = ({ index, catIndex, type, contents, setContents, solutions, technologies }) => {
  if (type === 'mouseout' || type === 'closeclick')
    setContents([true, ...Array.from({ length: technologies ? technologies.subModules.length : 0 }), ...Array.from({ length: solutions ? solutions.subModules.length : 0 })]);
  else
    setContents(contents.map((el, i) => (i === index + catIndex + 1) ? true : false));
};

const setObserver = ({ moduleRef }) => {
  const callback = (entries) => {
    entries.forEach((el) => {
      if (el.isIntersecting) {
        el.target.classList.add('is-visible');
      }
    });
  }

  // Instancing a new IntersectionObserver
  const observer = new IntersectionObserver(callback);

  // Adding a target to be observed
  observer.observe(moduleRef.current);
}

const initStates = ({ technologies, solutions }) => (
  [
    ...(solutions?.subModules || []),
    ...(technologies?.subModules || [])
  ]
    .map(() => ({ hover: false, clicked: false }))
);

const initContents = ({ technologies, solutions }) => (
  [
    true,
    ...Array.from({ length: technologies ? technologies.subModules.length : 0 }),
    ...Array.from({ length: solutions ? solutions.subModules.length : 0 })
  ]
);

const Pictures = ({ imageUrl, imageAlt, contents, technologies, solutions }) => (
  <>
    <picture key={rndKeyGen()} className={`module-solutions-technologies__picture ${contents[0] ? 'module-solutions-technologies__picture--visible' : ''}`}>
      <img src={imageUrl} alt={imageAlt} className='module-solutions-technologies__image' />
    </picture>
    {technologies && technologies.subModules.map((mod, index) =>
      <picture key={rndKeyGen()} className={`module-solutions-technologies__picture ${contents[index + 1] ? 'module-solutions-technologies__picture--visible' : ''}`}>
        <img src={mod.image.url} alt={mod.image.alt} className='module-solutions-technologies__image' />
      </picture>
    )}
    {solutions && solutions.subModules.map((mod, index) =>
      <picture key={rndKeyGen()} className={`module-solutions-technologies__picture ${contents[showPicture({ technologies, index })] ? 'module-solutions-technologies__picture--visible' : ''}`}>
        <img src={mod.image.url} alt={mod.image.alt} className='module-solutions-technologies__image' />
      </picture>
    )}
  </>
);

const Contents = ({ title, description, links, contents, technologies, solutions, minHeight }) => (


  <div className={`module-solutions-technologies__contents ${contentPosition({ technologies, solutions })}`} style={{ height: `${minHeight}px` }}>
    <ModuleContent
      key={rndKeyGen()}
      classes='module-solutions-technologies__content--init'
      title={title}
      description={description}
      links={links}
      visible={contents[0]}
    />
    {technologies && technologies.subModules.map((mod, index) =>
      <ModuleContent
        key={rndKeyGen()}
        title={mod.title}
        description={mod.description}
        links={mod.links}
        category={mod.category}
        icon={mod.icon}
        cyphers={mod.cyphers}
        visible={contents[index + 1]}
        size={technologies.subModules.length === 10 ? 'small' : undefined}
      />
    )}
    {solutions && solutions.subModules.map((mod, index) =>
      <ModuleContent
        key={rndKeyGen()}
        title={mod.title}
        description={mod.description}
        links={mod.links}
        category={mod.category}
        icon={mod.icon}
        cyphers={mod.cyphers}
        visible={contents[technologies ? technologies.subModules.length + index + 1 : index + 1]}
        size={solutions.subModules.length === 10 ? 'small' : undefined}
      />
    )}
  </div>
);

const SolucionesTecnologias = ({
  topTitle,
  isTech,
  title,
  description,
  links,
  imageUrl,
  imageAlt,
  technologies,
  solutions,
  solutionsTitle,
  technologiesTitle
}) => {
  const moduleRef = React.createRef();
  const [touch, setTouch] = useState('no-touch');
  const [states, setStates] = useState(initStates({ technologies, solutions }));
  const [contents, setContents] = useState(initContents({ technologies, solutions }));
  const [minHeight, setMinHeight] = useState(0);

  useSlowFade(moduleRef);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setObserver({ moduleRef });
    }
  }, []);

  useEffect(() => {
    if ('ontouchstart' in document) {
      setTouch('');
    }

    if ([...document.querySelectorAll('.module-solutions-technologies__wrapper')].length > 0) {
      setTimeout(() => calculateHeights({ setMinHeight }), 500);

      window.removeEventListener('resize', () => calculateHeights({ setMinHeight }));
      window.addEventListener('resize', () => calculateHeights({ setMinHeight }));
    }
  }, []);


  return (
    <section ref={moduleRef} className={`module-solutions-technologies ${touch}`}>
      <div className='grid'>
        {topTitle &&
          <div className='grid-container grid full-width module-solutions-technologies__wrapper module-solutions-technologies__wrapper--title-wrapper'>
            <h2 className='title--xs module-solutions-technologies__top-title'>{topTitle}</h2>
          </div>
        }
      </div>
      <div className={`module-solutions-technologies__container ${isTech && 'internal'}`}>
        <Pictures
          imageUrl={imageUrl}
          imageAlt={imageAlt}
          contents={contents}
          technologies={technologies}
          solutions={solutions}
        />
        <Contents
          title={title}
          description={description}
          links={links}
          contents={contents}
          technologies={technologies}
          solutions={solutions}
          minHeight={minHeight}
        />
        {technologies && <h4 className={`title--xs module-solutions-technologies__category-title module-solutions-technologies__category-title--technologies ${!solutions ? 'module-solutions-technologies__category-title--only' : ''}`}>{technologiesTitle}</h4>}
        {technologies && <IconsList classes={!solutions ? 'module-solutions-technologies__category-list--only' : ''} catIndex={0} category={technologies} update={updateContent} contents={contents} setContents={setContents} technologies={technologies} solutions={solutions} states={states} setStates={setStates} position='left' size={solutions && solutions.subModules.length === 10 ? 'small' : undefined} touch={touch} />}
        {solutions && <h4 className={`title--xs module-solutions-technologies__category-title module-solutions-technologies__category-title--solutions ${!technologies ? 'module-solutions-technologies__category-title--only' : ''}`}>{solutionsTitle}</h4>}
        {solutions && <IconsList classes={!technologies ? 'module-solutions-technologies__category-list--only' : ''} catIndex={technologies ? technologies.subModules.length : 0} category={solutions} update={updateContent} contents={contents} setContents={setContents} technologies={technologies} solutions={solutions} states={states} setStates={setStates} position='right' size={technologies && technologies.subModules.length === 10 ? 'small' : undefined} touch={touch} />}
      </div>
    </section>
  )
};

export default SolucionesTecnologias;
