import React from 'react';
import { Link } from 'React/components';

import { rndKeyGen } from 'React/helpers';

import IconsMap from './_IconsMap'

const ModuleContent = ({
  classes,
  category,
  icon,
  title,
  description,
  links,
  cyphers,
  visible,
}) => (
  <>
    <div className={`module-solutions-technologies__content ${classes ? classes : ''} ${visible ? 'module-solutions-technologies__content--visible' : ''}`} aria-hidden={!visible}>
      <div className={`grid module-solutions-technologies__wrapper ${visible ? 'module-solutions-technologies__wrapper--visible' : ''}`}>
        <div className='module-solutions-technologies__title-container'>
          {category && (
            <p className='module-solutions-technologies__title-container-subtitle'>
              <span className='module-solutions-technologies__title-container-icon'>{IconsMap[icon]}</span>
              <span className='title--xs module-solutions-technologies__title-container-category'>{category}</span>
            </p>
          )}
          {/* <h1 style={{ backgroundColor: 'red' }}>{icon}</h1> */}
          <h3 className='title--l module-solutions-technologies__title-container-text'>{title}</h3>
        </div>
        <p className='body--m module-solutions-technologies__description'>{description}</p>
        <ul className='module-solutions-technologies__links-list'>
          {links.map((link) => (
            <li key={rndKeyGen()} className='module-solutions-technologies__links-element'>
              <Link className="module-solutions-technologies__link" type='arrow' path={link.link} iconColor='icon--white' title={link.caption} children={link.linkText} />
            </li>
          ))}
        </ul>
        <div className='module-solutions-technologies__cyphers' aria-hidden={cyphers ? false : true}>
          {cyphers && (
            <ul className='module-solutions-technologies__cyphers-list'>
              {cyphers.map((cypher) =>
                <li key={rndKeyGen()} className='module-solutions-technologies__cyphers-element'>
                  <p className='cifras--m module-solutions-technologies__cyphers-paragraph'>
                    <span className='module-solutions-technologies__cyphers-cypher'>{cypher.cypher}</span>
                    {cypher.caption && <small className='module-solutions-technologies__cyphers-caption'>{cypher.caption}</small>}
                  </p>
                  <span className='title--xs module-solutions-technologies__cyphers-title'>{cypher.title}</span>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  </>
)

export default ModuleContent;