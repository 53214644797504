const handleResize = (gridRef) => {
    const _w = gridRef.current.clientWidth;
    if (_w > 680 && _w <= 1023)
        return 'tablet';
    else if (_w >= 1024)
        return 'laptop-up';
    else
        return 'phone'
}

const rndKeyGen = () =>
    Math.random()
        .toString(36)
        .substring(2, 15) +
    Math.random()
        .toString(36)
        .substring(2, 15);

const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const validateCIF = (cif) => {
    const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    var match = cif.match(CIF_REGEX);
    if (!match) return false;
    var letter = match[1],
        number = match[2],
        control = match[3];

    var even_sum = 0;
    var odd_sum = 0;
    var n;

    for (var i = 0; i < number.length; i++) {
        n = parseInt(number[i], 10);

        // Odd positions (Even index equals to odd position. i=0 equals first position)
        if (i % 2 === 0) {
            // Odd positions are multiplied first.
            n *= 2;

            // If the multiplication is bigger than 10 we need to adjust
            odd_sum += n < 10 ? n : n - 9;

            // Even positions
            // Just sum them
        } else {
            even_sum += n;
        }
    }

    var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1));
    var control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

    // Control must be a digit
    if (letter.match(/[ABEH]/)) {
        return control == control_digit;

        // Control must be a letter
    } else if (letter.match(/[KPQS]/)) {
        return control == control_letter;

        // Can be either
    } else {
        return control == control_digit || control == control_letter;
    }
};

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

function findGetParameter(parameterName) {
    var result = '',
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

function setCookie(name, value, minutes) {
    var expires = "";
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = "expires=" + date.toUTCString();
    }
    document.cookie = [name + "=" + encodeURIComponent(JSON.stringify(value)) + ";" + expires + ";path=/"].join('');
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

export { rndKeyGen, validateEmail, validateCIF, bytesToSize, replaceAll, findGetParameter, setCookie, getCookie, handleResize };


export const getTooltipWidth = (title, subtitle) => {

    if (title && subtitle) {
        const sum = title.length + subtitle.length;
        if (sum >= 23) {
            return "info tooltip-less-width";
        }
        else {
            return "info";
        }
    }

    else {
        return "info";
    }
}

export const isForbiddenCountryEnergy = country => {
    if (country.toLowerCase() === "estados unidos" || country.toLowerCase() === "united states of america" ||
        country.toLowerCase() === "canadá" || country.toLowerCase() === "canada" ||
        country.toLowerCase() === "australia" ||
        country.toLowerCase() === "sudáfrica" || country.toLowerCase() === "south africa" ||
        country.toLowerCase() === "japón" || country.toLowerCase() === "japan"
    ) {
        return true;
    }

    return false;
}

export const isForbiddenCountry = country => {
    if (country.toLowerCase() === "canadá" || country.toLowerCase() === "canada" ||
        country.toLowerCase() === "australia" ||
        country.toLowerCase() === "sudáfrica" || country.toLowerCase() === "south africa" ||
        country.toLowerCase() === "japón" || country.toLowerCase() === "japan"
    ) {
        return true;
    }

    return false;
}

export const isInvestorCountry = country => {
    if (country.toLowerCase() === "estados unidos" || country.toLowerCase() === "united states of america") {
        return true;
    }

    return false;
}

export function getSiteLanguage() {
    let siteLanguage;

    if (document.documentElement.lang.startsWith('es')) {
        siteLanguage = 'español';
    } else if (document.documentElement.lang.startsWith('en')) {
        siteLanguage = 'english';
    } else if (document.documentElement.lang.startsWith('no')) {
        siteLanguage = 'noruego';
    } else if (document.documentElement.lang.startsWith('pt')) {
        siteLanguage = 'portugués';
    } else {
        siteLanguage = 'otro';
    }

    return siteLanguage;
}

export function getSolutionsAndTechnologiesLink(caption) {

    const data = [
        {
            "link": "/es/tecnologias-y-soluciones/tecnologias/eolica/",
            "caption": "Eolica"
        },
        {
            "link": "/es/tecnologias-y-soluciones/tecnologias/eolica-marina/",
            "caption": "Eolica marina"
        },
        {
            "link": "/es/tecnologias-y-soluciones/tecnologias/fotovoltaica/",
            "caption": "Fotovoltaica"
        },
        {
            "link": "/es/tecnologias-y-soluciones/tecnologias/hidroelectrica/",
            "caption": "Hidroelectrica"
        },
        {
            "link": "/es/tecnologias-y-soluciones/tecnologias/biomasa/",
            "caption": "Biomasa"
        },
        {
            "link": "/es/tecnologias-y-soluciones/tecnologias/termosolar/",
            "caption": "Termosolar"
        },
        {
            "link": "/es/tecnologias-y-soluciones/tecnologias/almacenamiento/",
            "caption": "Almacenamiento"
        },
        {
            "link": "/technologies-and-solutions/technologies/wind-power/",
            "caption": "Wind Power"
        },
        {
            "link": "/technologies-and-solutions/technologies/offshore-wind/",
            "caption": "Offshore wind"
        },
        {
            "link": "/technologies-and-solutions/technologies/photovoltaic/",
            "caption": "Photovoltaic"
        },
        {
            "link": "/technologies-and-solutions/technologies/hydroelectric/",
            "caption": "Hydroelectric"
        },
        {
            "link": "/technologies-and-solutions/technologies/biomass/",
            "caption": "Biomass"
        },
        {
            "link": "/technologies-and-solutions/technologies/thermal-solar-csp/",
            "caption": "Thermal Solar CSP"
        },
        {
            "link": "/technologies-and-solutions/technologies/storage/",
            "caption": "Storage"
        },
        {
            "link": "/technologies-and-solutions/solutions/renewable-energy-supply/",
            "caption": "Renewable energy supply"
        },
        {
            "link": "/technologies-and-solutions/solutions/energy-services/",
            "caption": "Energy services"
        },
        {
            "link": "/technologies-and-solutions/solutions/self-consumption/",
            "caption": "Self consumption"
        },
        {
            "link": "/technologies-and-solutions/solutions/green-hydrogen/",
            "caption": "Green hydrogen"
        },
        {
            "link": "/technologies-and-solutions/solutions/electric-mobility/",
            "caption": "Electric mobility"
        },
        {
            "link": "/es/tecnologias-y-soluciones/soluciones/suministro-energia-renovable/",
            "caption": "Suministro de energía renovable"
        },
        {
            "link": "/es/tecnologias-y-soluciones/soluciones/servicios-energeticos/",
            "caption": "Servicios energeticos"
        },
        {
            "link": "/es/tecnologias-y-soluciones/soluciones/autoconsumo/",
            "caption": "Autoconsumo"
        },
        {
            "link": "/es/tecnologias-y-soluciones/soluciones/hidrogeno-verde/",
            "caption": "Hidrogeno verde"
        },
        {
            "link": "/es/tecnologias-y-soluciones/soluciones/movilidad-electrica/",
            "caption": "Movilidad electrica"
        }
    ];

    if (caption !== undefined && caption !== null) {
        const link = data.find(item => item.caption.toLowerCase() === caption.toLowerCase());
        return link ? link.link : '';
    } else {
        return '/'
    }
}

export function removeAccents(str) {

    if (str != null) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().replace(' ', '');
    }
}


export function getSolutionTraslate(caption) {


    var tech = removeAccents(caption);

    const data = {
        'Eolica': 'Wind power',
        'Fotovoltaica': 'Photovoltaic',
        'Termosolar': 'Thermal Solar',
        'Biomasa': 'Biomass',
        'Hidroelectrica': 'Hydroelectric',
        'EolicaMarina': 'Offshore wind',
        'Almacenamiento': 'Storage',
    };

    return data[tech] || '';
}

export function formatDate(culture, dateString) {
    const dateParts = dateString.split(' ');
    const day = parseInt(dateParts[0]);
    const month = dateParts[1];
    const year = parseInt(dateParts[2]);

    const monthNames = culture === 'es' ? ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ] : ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthIndex = monthNames.indexOf(month);

    const formattedDate = new Date(year, monthIndex, day);

    return formattedDate;
}
export function replaceUrl(condition, url) {

    if (condition.url === '/') { //inglés
        return url.replace('https://hogares.acciona-energia.com', 'https://hogares.acciona-energia.com/en');

    } else {
        return url
    }

}




