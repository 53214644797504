import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useSlowFade } from 'React/custom_hooks';

import './style.scss';

import { TagTitle, Link, EnlaceAgrupador, InputRangeSlider } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { rndKeyGen } from 'React/helpers';

// icons
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import { iconsMap, iconsMapLarge } from './_iconsMap';

const Proyectos = ({ type, tagTitle, cta, subModules, anchor, labelPotenciaTotal, labelEstado, labelInstalacion, labelConstruccion, energyTechIcon }) => {


    const swipableComponent = React.createRef();
    const [activeFrame, setActiveFrame] = useState(1);

    const prevButton = React.createRef();
    const nextButton = React.createRef();
    let moduleRef = React.createRef();
    let ghostRight = React.createRef();
    let ghostLeft = React.createRef();

    useEffect(() => {
        var nodes = document.querySelectorAll('.component_proyectos');
        var last = nodes[nodes.length - 1];
        last.classList.add('last-item');

        if (subModules.length > 1 && parseInt(activeFrame) === subModules.length && ghostRight.current != null) {
            ghostRight.current.classList.remove('ghost-frame-button');
        } else {
            if (ghostRight.current != null) {
                ghostRight.current.classList.add('ghost-frame-button');
            }
        }

        if (parseInt(activeFrame) <= 1 && ghostLeft.current != null) {
            ghostLeft.current.classList.remove('ghost-frame-button');
        } else {
            if (ghostLeft.current != null) {
                ghostLeft.current.classList.add('ghost-frame-button');
            }
        }
    });

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < subModules.length) {
            setActiveFrame(parseInt(activeFrame) + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(parseInt(activeFrame) - 1);
        }
    };

    useSlowFade(moduleRef);
    useSliderSwipe(swipableComponent, setActiveFrame, subModules.length);
    if (typeof window != 'undefined') {
        return (
            <div ref={moduleRef} className="module grid component_proyectos">

                {tagTitle && (
                    <div className="proyecto-header grid-container slow--y">
                        <TagTitle title={tagTitle} />
                        {cta && <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />}
                    </div>
                )}

                {subModules.length > 1 && (
                    <>
                        <button
                            ref={ghostLeft}
                            className="ghost-frame-button ghost-frame-button--left"
                            onMouseOver={prevHover}
                            onMouseOut={removeHover}
                            onClick={prevFrame}
                        >
                            Slider anterior
                        </button>
                        <button
                            ref={ghostRight}
                            className="ghost-frame-button ghost-frame-button--right"
                            onMouseOver={nextHover}
                            onMouseOut={removeHover}
                            onClick={nextFrame}
                        >
                            Slider siguiente
                        </button>
                    </>
                )}

                <div className="main-container" data-active-frame={activeFrame} ref={swipableComponent}>
                    <span className="custom-anchor-small" id={anchor} />
                    <div className="backgrounds-slider" style={{ width: `${100 * subModules.length}vw` }}>
                        {subModules.map(({ image, project }, frame, idx) => {
                            const projectImage = project ? project.headerImage : null;
                            const img = projectImage ? projectImage : image;
                            const imageUrl = useWindowWidth() >= 768 ? img : img;

                            return <div className="bg-frame" key={rndKeyGen()} style={{ backgroundImage: `url(${imageUrl ? imageUrl.url : ''})` }}></div>;
                        })}
                    </div>

                    <div className="content-overlay grid" >
                        {subModules
                            .filter((frame, idx) => idx === activeFrame - 1)
                            .map((frame, idx) => {
                                if (frame) {
                                    const { title, solution, region, country, _contentPath, isInstalation } = frame;
                                    return (
                                        <div className="content-footer" key={`content-footer-${idx}`}>

                                            <div className="project-details slow--y">
                                                {title && !solution && (
                                                    <h4 className="title--m slow--y">
                                                        <a href={!isInstalation ? _contentPath : ''} className="slow--y">{title}</a>
                                                    </h4>
                                                )}

                                                <div className='component_proyectos__wrapper'>
                                                    <div className='component_proyectos__logo-container-footer'>
                                                        <img src="../../static/icons/soluciones/agua.svg" className='component_proyectos__logo' />
                                                    </div>
                                                    <p className="project-type taglabel slow--y">
                                                        {solution &&
                                                            <a href={solution.url} className="slow--y">
                                                                {iconsMap[solution.icon]}
                                                                <span>{solution.name}</span>
                                                            </a>
                                                        }
                                                        {country && (
                                                            <span className="location slow--y">
                                                                <a href="">{region ? region + ', ' : ''}{country}</a>
                                                            </span>
                                                        )}
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                    );
                                }

                                else if (frame.solution) {
                                    const { menuText, icon, _contentPath } = frame.solution;

                                    return (
                                        <div className="content-footer" key={`content-footer-${idx}`}>
                                            <div className="project-details slow--y">
                                                {menuText && !icon && (
                                                    <h4 className="title--m slow--y">
                                                        <a href={_contentPath} className="slow--y">
                                                            {menuText}
                                                        </a>
                                                    </h4>
                                                )}
                                                {icon && menuText && (
                                                    <h4 className="title--m slow--y">
                                                        {iconsMapLarge[icon]}
                                                        <a href={_contentPath}>{menuText}</a>
                                                    </h4>
                                                )}
                                                <p className="project-type taglabel slow--y">
                                                    <a href={_contentPath} className="slow--y">
                                                        {IconsMap[icon]}
                                                        <span>{menuText}</span>
                                                    </a>
                                                </p>
                                            </div>

                                            {_contentPath && !frame.downloads && (
                                                <Link path={_contentPath} className={'link internal'}>
                                                    <ArrowRight viewBox="0 0 94 94" />
                                                </Link>
                                            )}

                                            {frame.downloads && <EnlaceAgrupador links={frame.downloads} box_type="round-button" />}
                                        </div>
                                    );
                                }
                            })}

                        {subModules
                            .filter((frame, idx) => idx === activeFrame - 1)
                            .map((frame, idx) => {
                                const { cipher, unit, title, description, slogan, _contentPath, downloads, isInstalation, logo } = frame;

                                return (
                                    <div className="content-main" key={rndKeyGen()}>
                                        <div className='content-main--top'>

                                            <div className="element_cta">
                                                {_contentPath /* && !downloads */ && (
                                                    <Link path={_contentPath} className={'link internal'}>
                                                        <ArrowRight viewBox="0 0 94 94" />
                                                    </Link>
                                                )}
                                                {/* {frame.downloads && <EnlaceAgrupador links={downloads} box_type="round-button" />} */}
                                            </div>
                                        </div>

                                        {/* SLIDER */}
                                        <InputRangeSlider
                                            framesCount={subModules.length}
                                            activeFrame={parseInt(activeFrame)}
                                            changeHandler={setActiveFrame}
                                            noIndicator={true}
                                            whiteThumb={true}
                                        />

                                        <div className='content-main--solution'>
                                            <div className='element'>
                                                <ul className='solution-info'>
                                                    <li className='solution-info__element'>
                                                        <span className='title--xs solution-info__title'>{labelPotenciaTotal}</span>
                                                        <span className='body--m solution-info__value'>{cipher} {unit}</span>
                                                    </li>
                                                    <li className='solution-info__element'>
                                                        <span className='title--xs solution-info__title'>{labelEstado}</span>
                                                        <span className='body--m solution-info__value'>{isInstalation ? labelInstalacion : labelConstruccion}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* FLECHAS <- -> */}
                                        {subModules.length > 1 && (
                                            <div className='arrows'>
                                                <div className="slider-controls slow--y">
                                                    <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                                                        <IconArrowRightLong />
                                                    </button>
                                                    <button ref={nextButton} className="next-button" onClick={nextFrame}>
                                                        <IconArrowRightLong />
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        <div className='novicell'>
                                            <div className="left slow--y">
                                                {cipher && (
                                                    <>
                                                        <p className="cifras--l">
                                                            {cipher} {unit && <small>{unit}</small>}
                                                        </p>
                                                        {description && <p className="title--xs">{description}</p>}
                                                    </>
                                                )}
                                                {!cipher && frame.project && frame.project.cipherUnit && !slogan && (
                                                    <>
                                                        <p className="cifras--l">
                                                            {frame.project.cipherUnit} {frame.project.cipherMeasure && <small>{frame.project.cipherMeasure}</small>}
                                                        </p>
                                                        {frame.project.cipherDescription && <p className="title--xs">{frame.project.cipherDescription}</p>}
                                                    </>
                                                )}

                                                {slogan && (
                                                    <>
                                                        <h3 className="title--m slow--y">{slogan}</h3>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {!logo ?
                                            <div className='component_proyectos__logo-container'>
                                                <img src="../../static/icons/soluciones/agua.svg" className='component_proyectos__logo' />
                                            </div>
                                            :
                                            <div className='component_proyectos__logo-container'>
                                                <img src={logo.url} alt={logo.alt} className='component_proyectos__logo' />
                                            </div>
                                        }

                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (<div className="module grid component_proyectos">
            {tagTitle && (
                <div className="proyecto-header grid-container slow--y">
                    <TagTitle title={tagTitle} />
                    {cta && <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />}
                </div>
            )}
            <span className="custom-anchor-small" id={anchor} />
        </div>);
    }
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default Proyectos;

