import React, { useEffect, useRef, useState } from 'react';
import { useHeroParallax } from 'React/custom_hooks';
import IconsMap from './_iconsMap';
import { removeAccents } from '../../helpers'

import './styles.scss';

const HeroImage = ({
    className,
    media,
    countryProject,
    titleProject,
    cifraProject,
    unitProject,
    descriptionProject,
    projectSolution,
    otherProjectSolution,
    title,
    body,
    cta,
    hasVeil,
    combined,
    restyling,
    logo,
    energyTechIcon,
    areaName
}) => {

    const [culture, setCulture] = useState('');

    useEffect(() => {

        const currentUrl = window.location.pathname;
        if (currentUrl.includes('/es/')) {
            setCulture('es');
        } else {
            setCulture('en');
        }
    }, []);


    const imgContainer = React.createRef();

    let app = useRef(null);


    useHeroParallax('.component__hero-image');

    //const tl = gsap.timeline();

    useEffect(() => {
        if (app != null) {
            let img = new Image();
            img.src = `${media.url}`;

            // controlamos con esto que la imagen est� cargada y entonces la desvelamos
            img.onload = function () {
                app.classList.add('is-loaded');
            };

            if (img.complete) img.onload();
        }
    }), [];


    return (
        <>
            <div
                ref={(el) => (app = el)} className={`module grid full-width component__hero-image ${className} is-loaded ${restyling ? 'component__hero-image--restyling' : ''}`} data-combined={combined}>
                {media && media.url && (
                    <div ref={imgContainer} className="backgrounds full-width" style={{ backgroundImage: `url(${media.url})` }}>
                        <div className={`img-container ${hasVeil ? '' : 'has-veil'}`}></div>
                    </div>
                )}

                {media && media.videoId && <div className="video-container"></div>}

                <div className="content-blocks">

                    {title && (
                        <h2 className="title title--xl">
                            {title}
                        </h2>
                    )}

                    {body && (
                        <div className="body body--l" dangerouslySetInnerHTML={{ __html: body }}></div>
                    )}

                    {cifraProject && (
                        <h2 className="cifras--xl">
                            {cifraProject}
                            <span className="cifras--m">{unitProject}</span>
                        </h2>
                    )}

                    {descriptionProject && <p className="title--xs">{descriptionProject}</p>}
                    {!restyling && <div className="separator"></div>}

                    {titleProject && (
                        <div className="project-detail-footer">
                            <h2 className="title--xl">{titleProject}</h2>
                            <div className="project-detail-footer__desc">
                                <span className="category-project taglabel" >
                                    <>
                                        {IconsMap[energyTechIcon ? removeAccents(energyTechIcon) : IconsMap[removeAccents(areaName?.replace(" ", ""))] ? removeAccents(areaName.replace(" ", "")) : projectSolution.icon]}
                                        {areaName ? areaName : projectSolution.name}
                                    </>
                                </span>

                                {countryProject && <span className="country-project taglabel">{countryProject}</span>}

                                {otherProjectSolution && otherProjectSolution.length > 0 && (
                                    <>
                                        {otherProjectSolution.map((item, idx) => {
                                            return (
                                                <>
                                                    <span className="country-project taglabel" key={`other-solution-${idx}`}>
                                                        {IconsMap[item.icon]}{item.name}
                                                    </span>
                                                </>
                                            );
                                        })}
                                    </>

                                )}
                            </div>
                        </div>
                    )}
                </div>
                {logo &&
                    <div className='component__hero-image__logo-container'>
                        <img src={logo.url} alt={logo.alt} className='component__hero-image__logo' />
                    </div>
                }
            </div>
        </>
    );
};

export default HeroImage;
