import React from 'react';

//Tecnologias
import { ReactComponent as IconEolica } from 'Icons/V3/AE_Ic_Tecnologías_Eólica.svg';
import { ReactComponent as IconFotovoltaica } from 'Icons/V3/AE_Ic_Tecnologías_Fotovoltaica.svg';
import { ReactComponent as IconTermosolar } from 'Icons/V3/AE_Ic_Tecnologías_Termosolar.svg';
import { ReactComponent as IconBiomasa } from 'Icons/V3/AE_Ic_Tecnologías_Biomasa.svg';
import { ReactComponent as IconHidroelectrica } from 'Icons/V3/AE_Ic_Tecnologías_Hidroeléctrica.svg';
import { ReactComponent as IconMarina } from 'Icons/V3/AE_Ic_Tecnologías_Eólica marina.svg';
import { ReactComponent as IconAlmacenamiento } from 'Icons/V3/AE_Ic_Tecnologías_Almacenamiento.svg';

//Soluciones
import { ReactComponent as IconSuministroEnergiaRenovable } from 'Icons/V3/AE_Ic_Soluciones_Suministro energía renovable.svg';
import { ReactComponent as IconHidrogenoVerde } from 'Icons/V3/AE_Ic_Soluciones_Hidrógeno verde.svg';
import { ReactComponent as IconServiciosEnergeticos } from 'Icons/V3/AE_Ic_Soluciones_Servicios -energéticos.svg';
import { ReactComponent as IconMovilidadElectrica } from 'Icons/V3/AE_Ic_Soluciones_Movilidad eléctrica.svg';
import { ReactComponent as IconAutoconsumo } from 'Icons/V3/AE_Ic_Soluciones_Autoconsumo.svg';

import { ReactComponent as CrossIcon } from 'Icons/iconcross.svg';
import { ReactComponent as ArrowIcon } from 'Icons/iconarrowright.svg';


export default {
    Autoconsumo: <IconAutoconsumo height="24" width="24" viewBox="0 0 24 24" />,
    HidrogenoVerde: <IconHidrogenoVerde height="24" width="24" viewBox="0 0 24 24" />,
    MovilidadElectrica: <IconMovilidadElectrica height="24" width="24" viewBox="0 0 24 24" />,
    ServiciosEnergeticos: <IconServiciosEnergeticos height="24" width="24" viewBox="0 0 24 24" />,
    SuministroEnergiaRenovable: <IconSuministroEnergiaRenovable height="24" width="24" viewBox="0 0 24 24" />,
    Almacenamiento: <IconAlmacenamiento height="24" width="24" viewBox="0 0 24 24" />,
    Biomasa: <IconBiomasa height="24" width="24" viewBox="0 0 24 24" />,
    Eolica: <IconEolica height="24" width="24" viewBox="0 0 24 24" />,
    Marina: <IconMarina height="24" width="24" viewBox="0 0 24 24" />,
    Fotovoltaica: <IconFotovoltaica height="24" width="24" viewBox="0 0 24 24" />,
    Hidroelectrica: <IconHidroelectrica height="24" width="24" viewBox="0 0 24 24" />,
    Termosolar: <IconTermosolar height="24" width="24" viewBox="0 0 24 24" />,
    Cross: <CrossIcon height="16" width="16" viewBox="1 0 16 16" />,
    Arrow: <ArrowIcon height="17" width="16" viewBox="0 0 17 16" />,
};
