import React, { useState, useEffect } from 'react';
import { useSlowFade, useFade } from 'React/custom_hooks';
import {
  TagTitle,
  Link,
  ProjectsMapStatic,
  Wysiwyg,
  EnlaceAgrupador,
  Lightbox,
  ContactFormGreenEnergy,
} from 'React/components';
import { rndKeyGen } from 'React/helpers';
import { ReactComponent as DownloadIcon } from 'Icons/download.svg';

import './styles.scss';

const BloqueTexto = ({ model, formTranslation, labelSeeOnGoogleMaps }) => {
  const {
    project,
    tagLine,
    title,
    leadText,
    leadTextColumnRight,
    leadTextColumnLeft,
    mainTextColumnLeft,
    mainTextColumnRight,
    leadSmallText,
    mainText,
    children,
    list,
    listType,
    downloads,
    moduleCta,
    groupLink,
    anchor,
    lightboxLinkText,
    form,
    ods,
    culture,
  } = model;

  const leadTextClass = leadText ? 'with-lead-text' : '';

  const [visibleLightbox, setVisibleLightbox] = useState(false);
  const [visibleODSLightbox, setVisibleODSLightbox] = useState(null);
  const [isHome, setIsHome] = useState(false);

  const goalsImagesUrlPrefix =
    culture === 'es'
      ? '/static/development_goals_images/Goal_'
      : '/static/development_goals_images_en/Goal_';

  const handleLightboxClick = () => {
    setVisibleLightbox(true);
  };

  const goalsArray = ods
    ? ods.reduce((acc, el, idx) => {
        acc[el.number] = el;
        return acc;
      }, [])
    : null;

  const goalsWithLightbox = goalsArray
    ? goalsArray.filter((goal) => goal.content !== '')
    : null;

  const handleGoalClick = (number) => {
    const clickedGoal = goalsArray[number];
    if (clickedGoal && clickedGoal.content !== '') {
      setVisibleODSLightbox(number);
    }
  };

  const moduleRef = React.createRef();

  useSlowFade(moduleRef);
  useFade(moduleRef);

  useEffect(() => {
    const pathname = window.location.pathname;
    pathname === '/es/' || pathname === '' || pathname === '/'
      ? setIsHome(true)
      : null;
  }, []);

  return (
    <>
      <div
        ref={moduleRef}
        className={`module grid bloque-texto ${leadTextClass} ${
          isHome ? 'isHome' : ''
        }`}
      >
        {tagLine && <TagTitle title={tagLine} />}

        <div className="title ">
          <h2 className="title--l slow">{title}</h2>
          {project && project.latitude !== '' && (
            <ProjectsMapStatic
              projectsList={[
                {
                  country: project.country,
                  lat: !isNaN(parseFloat(project.latitude))
                    ? parseFloat(project.latitude)
                    : null,
                  lng: !isNaN(parseFloat(project.longitude))
                    ? parseFloat(project.longitude)
                    : null,
                },
              ]}
              labelSeeOnGoogleMaps={labelSeeOnGoogleMaps}
              culture={culture}
            />
          )}
        </div>
        {leadText && (
          <div className="lead-text-container">
            {ods && (
              <div className="goals-buttons">
                {ods.map((goal, idx) => {
                  if (goal && goal.content === '') {
                    // external link
                    if (goal.cta && goal.cta.type === 'External') {
                      return (
                        <div
                          className="goal-button"
                          key={rndKeyGen()}
                          data-is-active={goal !== undefined}
                        >
                          <Link path={goal.cta.link} target={goal.cta.target}>
                            <img
                              src={goalsImagesUrlPrefix + goal.number + '.png'}
                              key={rndKeyGen()}
                            />
                          </Link>
                        </div>
                      );
                    }
                    // internal link
                    if (goal.cta && goal.cta.type === 'Internal') {
                      return (
                        <div
                          className="goal-button"
                          key={rndKeyGen()}
                          data-is-active={goal !== undefined}
                        >
                          <Link path={goal.cta.link} target={goal.cta.target}>
                            <img
                              src={goalsImagesUrlPrefix + goal.number + '.png'}
                              key={rndKeyGen()}
                            />
                          </Link>
                        </div>
                      );
                    }
                  }
                  // lightbox
                  return (
                    <div
                      className="goal-button"
                      key={rndKeyGen()}
                      data-is-active={goal !== undefined}
                    >
                      <img
                        data-is-active={goal !== undefined}
                        onClick={() => handleGoalClick(goal.number)}
                        src={goalsImagesUrlPrefix + goal.number + '.png'}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {goalsWithLightbox &&
              goalsWithLightbox.map((goal, idx) => {
                return (
                  <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleODSLightbox === goal.number}
                    closeMethod={() => setVisibleODSLightbox(null)}
                  >
                    <Wysiwyg html={goal.content} links={goal.popUpLinks} />
                  </Lightbox>
                );
              })}

            <div
              className="lead-text body--l slow"
              dangerouslySetInnerHTML={{ __html: leadText }}
            ></div>

            {leadSmallText && (
              <div
                dangerouslySetInnerHTML={{ __html: leadSmallText }}
                className="lead-small-text mt-2 body--m slow"
              ></div>
            )}
          </div>
        )}
        <span className="custom-anchor" id={anchor} />
        {mainText && (
          <div className="main-text">
            <div
              className="body--m slow main-text-paragraph"
              dangerouslySetInnerHTML={{ __html: mainText }}
            ></div>
            {children}
          </div>
        )}

        {mainTextColumnRight && (
          <div className="main-text main-text--columns grid full-width">
            <div className="main-text-paragraph">
              <div
                className="body--l slow"
                dangerouslySetInnerHTML={{ __html: leadTextColumnLeft }}
              ></div>
              <div
                className="body--m slow"
                dangerouslySetInnerHTML={{ __html: mainTextColumnLeft }}
              ></div>
            </div>
            <div className="main-text-paragraph">
              <div
                className="body--l slow"
                dangerouslySetInnerHTML={{ __html: leadTextColumnRight }}
              ></div>
              <div
                className="body--m slow"
                dangerouslySetInnerHTML={{ __html: mainTextColumnRight }}
              ></div>
            </div>
          </div>
        )}

        {leadTextColumnRight && !mainTextColumnRight && (
          <div className="main-text main-text--lead grid full-width">
            <div className="main-text-paragraph">
              <div
                className="body--l slow"
                dangerouslySetInnerHTML={{ __html: leadTextColumnLeft }}
              ></div>
            </div>
            <div className="main-text-paragraph">
              <div
                className="body--l slow"
                dangerouslySetInnerHTML={{ __html: leadTextColumnRight }}
              ></div>
            </div>
          </div>
        )}

        {moduleCta && leadTextColumnRight ? (
          <div className="module--cta container">
            <Link
              className="module--cta slow"
              type="arrow"
              path={moduleCta.link}
              target={moduleCta.target}
            >
              {moduleCta.caption}
            </Link>
          </div>
        ) : (
          moduleCta && (
            <div className="module--cta">
              <Link
                className="module--cta slow"
                type="arrow"
                path={moduleCta.link}
                target={moduleCta.target}
              >
                {moduleCta.caption}
              </Link>
            </div>
          )
        )}

        {lightboxLinkText && (
          <div className="module--cta" onClick={() => handleLightboxClick()}>
            <Link className="module--cta slow" type="lightbox">
              {lightboxLinkText}
            </Link>
          </div>
        )}

        {list && listType == 'Desordenada' && (
          <ul className="list">
            {list.map((item, idx) => {
              return (
                <li
                  key={`text-p-${idx}`}
                  className="slow body--s main-text-paragraph"
                >
                  <div dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              );
            })}
          </ul>
        )}

        {list && listType != 'Desordenada' && (
          <ol className="list list-ordered">
            {list.map((item, idx) => {
              return (
                <li
                  key={`text-p-${idx}`}
                  className="body--s slow main-text-paragraph"
                >
                  <span>
                    <div dangerouslySetInnerHTML={{ __html: item }} />
                  </span>
                </li>
              );
            })}
          </ol>
        )}

        {downloads && !groupLink && (
          <ul className="list list-downloads">
            {downloads.map((item, idx) => {
              return (
                <li
                  key={`text-p-${idx}`}
                  className="link--download main-text-paragraph"
                >
                  <Link
                    type="download"
                    path={item.file ? item.file : item.umbracoFile}
                    label={item.title ? item.title : item.name}
                    bytes={item.umbracoBytes}
                    extension={item.umbracoExtension}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {downloads && groupLink && (
          <>
            <ul className="enlace-agrupador">
              <li className="slow">
                <EnlaceAgrupador links={downloads} box_label={groupLink} />
              </li>
            </ul>
            {/*<EnlaceAgrupador links={downloads} box_label={groupLink} /></>*/}
          </>
        )}
        {form && (
          <Lightbox
            key={rndKeyGen()}
            isVisible={visibleLightbox}
            closeMethod={() => setVisibleLightbox(null)}
          >
            {form.type === 'GreenEnergy' && (
              <ContactFormGreenEnergy
                title={form.title}
                description={form.description}
                translations={formTranslation}
                closeLightbox={setVisibleLightbox}
                popup={form.popup}
                culture={culture}
              />
            )}
          </Lightbox>
        )}
      </div>
    </>
  );
};

export default BloqueTexto;
