import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useSlowFade } from 'React/custom_hooks';

import './style.scss';
import { TagTitle, Link, EnlaceAgrupador, InputRangeSlider } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { rndKeyGen, removeAccents } from 'React/helpers';

// icons
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import { iconsMap, iconsMapLarge } from './_iconsMap';

const ProyectosDestacados = ({ model, projects, restyling, logo }) => {

    var culture = model._contentPath.includes("/es/") ? 'es' : '';
    var labelEstado = model.labelEstado;
    var labelTotalPower = model.labelPotenciaTotal;
    var labelInstalacion = model.labelInstalacion;
    var labelConstruccion = model.labelConstruccion;

    const { type, tagTitle, solution, cta, subModules, anchor, isLanding } = model;
    const featuredProjects = projects;
    const swipableComponent = React.createRef();
    const [activeFrame, setActiveFrame] = useState(1);

    const prevButton = React.createRef();
    const nextButton = React.createRef();
    let moduleRef = React.createRef();
    let ghostRight = React.createRef();
    let ghostLeft = React.createRef();

    const accionaDomain = isLanding ? "https://www.acciona.com" : "";

    useEffect(() => {
        var nodes = document.querySelectorAll('.component_proyectos');
        var last = nodes[nodes.length - 1];
        last.classList.add('last-item');

        if (featuredProjects.length > 1 && parseInt(activeFrame) === featuredProjects.length && ghostRight.current != null) {
            ghostRight.current.classList.remove('ghost-frame-button');
        } else {
            if (ghostRight.current != null) {
                ghostRight.current.classList.add('ghost-frame-button');
            }
        }

        if (parseInt(activeFrame) <= 1 && ghostLeft.current != null) {
            ghostLeft.current.classList.remove('ghost-frame-button');
        } else {
            if (ghostLeft.current != null) {
                ghostLeft.current.classList.add('ghost-frame-button');
            }
        }
    });

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < featuredProjects.length) {
            setActiveFrame(parseInt(activeFrame) + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(parseInt(activeFrame) - 1);
        }
    };

    useSlowFade(moduleRef);
    useSliderSwipe(swipableComponent, setActiveFrame, featuredProjects.length);


    if (typeof window != 'undefined') {
        return (
            <div ref={moduleRef} className={`module grid component_proyectos ${restyling ? 'component_proyectos--restyling' : ''}`}>
                {tagTitle && (
                    <div className="proyecto-header grid-container slow--y oneline-container ">
                        <TagTitle title={tagTitle} />
                        {cta && <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />}
                    </div>
                )}

                {featuredProjects.length > 1 && (
                    <>
                        <button
                            ref={ghostLeft}
                            className="ghost-frame-button ghost-frame-button--left"
                            onMouseOver={prevHover}
                            onMouseOut={removeHover}
                            onClick={prevFrame}
                        >
                            Slider anterior
                        </button>
                        <button
                            ref={ghostRight}
                            className="ghost-frame-button ghost-frame-button--right"
                            onMouseOver={nextHover}
                            onMouseOut={removeHover}
                            onClick={nextFrame}
                        >
                            Slider siguiente
                        </button>
                    </>
                )}
                <div className="main-container" data-active-frame={activeFrame} ref={swipableComponent}>
                    <span className="custom-anchor-small" id={anchor} />
                    <div className="backgrounds-slider" style={{ width: `${100 * featuredProjects.length}vw` }}>
                        {featuredProjects.map(({ headerImage }, frame, idx) => {
                            const projectImage = headerImage ? headerImage : null;
                            const img = projectImage ? projectImage : { url: '' };
                            const imageUrl = useWindowWidth() >= 768 ? img : img;

                            return <div className="bg-frame" key={rndKeyGen()} style={{ backgroundImage: `url(${imageUrl ? imageUrl.url : ''})` }}></div>;
                        })}
                    </div>

                    <div className="content-overlay grid">

                        {featuredProjects
                            .filter((frame, idx) => idx === activeFrame - 1)
                            .map((frame, idx) => {
                                if (frame) {
                                    const { title, solution, region, country, _contentPath, isInstalation, areaName, energyTechIcon } = frame;

                                    return (

                                        <div className="content-footer" key={`content-footer-${idx}`}>
                                            <div className='icono-container'>
                                                {solution &&
                                                    <span>
                                                        {iconsMap[energyTechIcon ? removeAccents(energyTechIcon) : iconsMap[removeAccents(areaName?.replace(" ", ""))] ? removeAccents(areaName.replace(" ", "")) : solution.icon]}
                                                    </span>
                                                }
                                            </div>


                                            <div className="project-details slow--y">

                                                {solution && title && (
                                                    <h4 className="title--m slow--y ">


                                                        {_contentPath && !frame.downloads && (
                                                            <Link path={(culture === 'es' && !_contentPath.includes('/es/')) ? accionaDomain + '/' + culture + _contentPath : accionaDomain + _contentPath} target={isLanding ? "_blank" : ""}>
                                                                {title}
                                                            </Link>
                                                        )}

                                                        {/* <a href={_contentPath ? accionaDomain + '/' + culture + _contentPath : ''} target={isLanding ? "_blank" : ""}>{title}</a> */}
                                                    </h4>
                                                )}
                                                <div className='project-type taglabel container-title slow--y'>
                                                    {solution &&
                                                        <>
                                                            {iconsMap[energyTechIcon ? removeAccents(energyTechIcon) : iconsMap[removeAccents(areaName?.replace(" ", ""))] ? removeAccents(areaName.replace(" ", "")) : solution.icon]}
                                                            {/* <span>{culture === 'es' ? energyTechIcon ? energyTechIcon : areaName ? areaName : solution.name : areaName ? areaName : solution.name}</span> */}

                                                            <span>{areaName || solution.name}</span>
                                                        </>

                                                    }
                                                    {country && (
                                                        <>
                                                            <span className='separator-pipe'></span>
                                                            {region ? region + ', ' : ''}
                                                            {country}
                                                        </>
                                                    )}


                                                </div>

                                            </div>
                                            {_contentPath && !frame.downloads && (
                                                <Link path={(culture === 'es' && !_contentPath.includes('/es/')) ? accionaDomain + '/' + culture + _contentPath : accionaDomain + _contentPath} className={'link internal position-link'} target={isLanding ? "_blank" : ""}>
                                                    <ArrowRight viewBox="0 0 94 94" />
                                                </Link>
                                            )}

                                        </div>
                                    );
                                }
                            })}
                        <InputRangeSlider
                            framesCount={featuredProjects.length}
                            activeFrame={parseInt(activeFrame)}
                            changeHandler={setActiveFrame}
                            noIndicator={true}
                            whiteThumb={true}
                        />

                        {featuredProjects.length > 1 && !restyling && (
                            <div className="slider-controls slow--y">
                                <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                                    <IconArrowRightLong />
                                </button>
                                <button ref={nextButton} className="next-button" onClick={nextFrame}>
                                    <IconArrowRightLong />
                                </button>
                            </div>
                        )}



                        {featuredProjects
                            .filter((frame, idx) => idx === activeFrame - 1)
                            .map((frame, idx) => {
                                if (frame) {

                                    const { totalPower, isInstalation } = frame;

                                    return (
                                        <div className='component_proyectos_info'>
                                            <ul className='component_proyectos__info slow--y'>
                                                <li className='component_proyectos__info-element'>
                                                    {totalPower && totalPower !== '' ? (
                                                        <>
                                                            <span className='title--xs component_proyectos__info-title'>{model.labelPotenciaTotal}</span>
                                                            <span className='component_proyectos__info-value'>{totalPower}</span>
                                                        </>
                                                    ) : null}
                                                </li>
                                                <li className='component_proyectos__info-element'>
                                                    {isInstalation ? (
                                                        <>
                                                            <span className='title--xs component_proyectos__info-title'>{model.labelEstado}</span>
                                                            <span className='component_proyectos__info-value'>{isInstalation ? model.labelInstalacion : model.labelConstruccion} </span>
                                                        </>
                                                    ) : null}
                                                </li>
                                            </ul>
                                        </div>

                                    )
                                }
                            }
                            )
                        }



                        {featuredProjects
                            .filter((frame, idx) => idx === activeFrame - 1)
                            .map((frame, idx) => {
                                const { cipherMeasure, caption, eslogan,
                                    cipher, unit, title, description, slogan, _contentPath, downloads, isInstalation, logo, areaName } = frame;
                                return (
                                    <div className={`content-main ${!cipher ? 'content-main--empty' : ''}`} key={rndKeyGen()}>

                                        <div className="right slow--y">
                                            {cipher && (
                                                <>
                                                    <p className="cifras--l">{cipher}</p>
                                                    <p className="title--xs oneline">{caption && <small >{caption}</small>}</p>
                                                </>
                                            )}

                                            {eslogan && (
                                                <h3 className="title--m slow--y">{eslogan}</h3>
                                            )}

                                        </div>
                                    </div>
                                );
                            })}


                    </div>
                </div>

                {cta && restyling && <Link className="project-cta--bottom slow--y" type="arrow" label={cta.caption} path={cta.link} target={cta.target} />}


            </div>
        );
    }
    else {
        return (<div className="module grid component_proyectos">
            {tagTitle && (
                <div className="proyecto-header grid-container slow--y oneline-container">
                    <TagTitle title={tagTitle} />
                    {cta && <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />}
                </div>
            )}
            {/* <span className="custom-anchor-small" id={anchor} /> */}
        </div>);
    }
};


const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};
export default ProyectosDestacados;
