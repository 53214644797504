import React, { useState, useEffect, useRef } from 'react';

import './Footer.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { Link } from 'React/components';
import { useSlowFade } from 'React/custom_hooks';

import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';
import { ReactComponent as InstagramIcon } from 'Icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'Icons/youtube.svg';

const FooterLandingOwnDomain = ({ footer, linkedinLink, twitterLink, facebookLink, instagramLink, youtubeLink, homeLink, urlPrivacyPolicy, labelPrivacyPolicy }) => {
    let moduleRef = React.createRef();

    const { contentPage, solutions, legalPages } = footer;

    useSlowFade(moduleRef);

    return (
        <footer ref={moduleRef} className="page-footer slow--y" id="page-footer">
            <div className="nav-footer grid slow--y">
                <div className="grid-container nav-footer-container">
                    <Link className="slow--y logo-home-link" path={homeLink}>
                        <img className="logo" src={LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                    <div className="nav-footer-links slow--y">
                        <div>
                            <a target="_blank" href={urlPrivacyPolicy} className="taglabel">
                                {labelPrivacyPolicy}
                            </a>
                            {legalPages.map((item, idx) => {
                                var absoluteUrl = "https://www.acciona.com" + item.url;
                                return (
                                    <a href={absoluteUrl} className="taglabel">
                                        {item.name}
                                    </a>
                                );
                            })}
                        </div>
                        <span className="copyright-notice">Copyright © 2021 ACCIONA</span>
                    </div>
                    {/*<div className="nav-footer-social slow--y">
                        {typeof window !== 'undefined' && useWindowWidth() < 1280 ? (
                            <>
                                <a href={linkedinLink} target="_blank">
                                    <LinkedinIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={youtubeLink} target="_blank">
                                    <YoutubeIcon width="20" height="20" viewBox="0 0 16 11" />
                                </a>
                                <a href={twitterLink} target="_blank">
                                    <TwitterIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={instagramLink} target="_blank">
                                    <InstagramIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={facebookLink} target="_blank">
                                    <FacebookIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                            </>
                        ) : (
                                <>
                                    <a href={instagramLink} target="_blank">
                                        <LinkedinIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href={youtubeLink} target="_blank">
                                        <YoutubeIcon width="16" height="16" viewBox="0 0 16 11" />
                                    </a>
                                    <a href={twitterLink} target="_blank">
                                        <TwitterIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href={instagramLink} target="_blank">
                                        <InstagramIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href={facebookLink} target="_blank">
                                        <FacebookIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                </>
                            )}
                    </div>*/}
                </div>
            </div>
        </footer>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

const subscriptionFormProps = {
    action: '/subscription-API-endpoint',
    newsletterType: 'generic',
    title: 'Suscríbete a nuestra newsletter',
    descr:
        'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.',
    checkboxLabel:
        'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.',
    confirmationMsg: '¡Gracias! Nos pondremos lo antes posible en contacto contigo',
};

export default FooterLandingOwnDomain;
