import React from 'react';

import { ReactComponent as Energy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as Ciudades } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as Movilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as Agua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as Social } from 'Icons/soluciones/social.svg';
import { ReactComponent as Capital } from 'Icons/soluciones/capital.svg';
import { ReactComponent as Inmobiliaria } from 'Icons/soluciones/inmobiliaria.svg';
import { ReactComponent as AreaTermosolarIcon } from 'Icons/Tecno/V1/24x24_Termosolar.svg';
import { ReactComponent as AreaAlmacenamientoIcon } from 'Icons/Tecno/V1/24x24_Almacenamiento.svg';
import { ReactComponent as AreaBiomasaIcon } from 'Icons/Tecno/V1/24x24_Biomasa.svg';
import { ReactComponent as AreaEolicaMarinaIcon } from 'Icons/Tecno/V1/24x24_Eolica marina.svg';
import { ReactComponent as AreaEolicaIcon } from 'Icons/Tecno/V1/24x24_Eolica.svg';
import { ReactComponent as AreaFotovoltaicaIcon } from 'Icons/Tecno/V1/24x24_Fotovoltaica.svg';
import { ReactComponent as AreaHidroelectricaIcon } from 'Icons/Tecno/V1/24x24_Hidroelectrica.svg';

const iconsMap = {
    Energia: <Energy width="18" height="18" viewBox="0 0 24 24" />,
    Termosolar: <AreaTermosolarIcon height="18" width="18" viewBox="0 0 24 24" />,
    Almacenamiento: <AreaAlmacenamientoIcon height="18" width="18" viewBox="0 0 24 24" />,
    Biomasa: <AreaBiomasaIcon height="18" width="18" viewBox="0 0 24 24" />,
    EolicaMarina: <AreaEolicaMarinaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Eolica: <AreaEolicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Fotovoltaica: <AreaFotovoltaicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Hidroelectrica: <AreaHidroelectricaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Transporte: <Movilidad width="18" height="18" viewBox="0 0 24 24" />,
    Agua: <Agua width="18" height="18" viewBox="0 0 24 24" />,
    Social: <Social width="18" height="18" viewBox="0 0 24 24" />,
    Ciudades: <Ciudades width="18" height="18" viewBox="0 0 24 24" />,
    Inmobiliaria: <Inmobiliaria width="18" height="18" viewBox="0 0 24 24" />,
    Capital: <Capital width="18" height="18" viewBox="0 0 24 24" />,
    EnergiaDefault: '/static/images/project_default/proyectos_930x600_energia.svg',
    CiudadesDefault: '/static/images/project_default/proyectos_930x600_ciudades.svg',
    TransporteDefault: '/static/images/project_default/proyectos_930x600_transportes.svg',
    AguaDefault: '/static/images/project_default/proyectos_930x600_agua.svg',
    SocialDefault: '/static/images/project_default/proyectos_930x600_social.svg',
    CapitalDefault: '/static/images/project_default/proyectos_930x600_financieras.svg',
    InmobiliariaDefault: '/static/images/project_default/proyectos_930x600_inmobiliaria.svg',



    WindPower: <AreaEolicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Photovoltaic: <AreaFotovoltaicaIcon height="18" width="18" viewBox="0 0 24 24" />,
    Hydropower: <AreaHidroelectricaIcon height="18" width="18" viewBox="0 0 24 24" />,
    CSP: <AreaTermosolarIcon height="18" width="18" viewBox="0 0 24 24" />,
    Biomass: <AreaBiomasaIcon height="18" width="18" viewBox="0 0 24 24" />,
};

const iconsMapLarge = {
    Energia: <Energy width="24" height="24" viewBox="0 0 24 24" />,
    Termosolar: <AreaTermosolarIcon width="24" height="24" viewBox="0 0 24 24" />,
    Almacenamiento: <AreaAlmacenamientoIcon width="24" height="24" viewBox="0 0 24 24" />,
    Biomasa: <AreaBiomasaIcon width="24" height="24" viewBox="0 0 24 24" />,
    EolicaMarina: <AreaEolicaMarinaIcon width="24" height="24" viewBox="0 0 24 24" />,
    Eolica: <AreaEolicaIcon width="24" height="24" viewBox="0 0 24 24" />,
    Fotovoltaica: <AreaFotovoltaicaIcon width="24" height="24" viewBox="0 0 24 24" />,
    Hidroelectrica: <AreaHidroelectricaIcon width="24" height="24" viewBox="0 0 24 24" />,
    Transporte: <Movilidad width="24" height="24" viewBox="0 0 24 24" />,
    Agua: <Agua width="24" height="24" viewBox="0 0 24 24" />,
    Social: <Social width="24" height="24" viewBox="0 0 24 24" />,
    Ciudades: <Ciudades width="24" height="24" viewBox="0 0 24 24" />,
    Inmobiliaria: <Inmobiliaria width="24" height="24" viewBox="0 0 24 24" />,
    Capital: <Capital width="24" height="24" viewBox="0 0 24 24" />,
    WindPower: <AreaEolicaIcon height="24" width="24" viewBox="0 0 24 24" />,
    Photovoltaic: <AreaFotovoltaicaIcon height="24" width="24" viewBox="0 0 24 24" />,
    Hydropower: <AreaHidroelectricaIcon height="24" width="24" viewBox="0 0 24 24" />,
    CSP: <AreaTermosolarIcon height="24" width="24" viewBox="0 0 24 24" />,
    Biomass: <AreaBiomasaIcon height="24" width="24" viewBox="0 0 24 24" />,
};

export { iconsMap, iconsMapLarge };
