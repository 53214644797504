import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useFade } from 'React/custom_hooks';

import './style.scss';

import { TagTitle, Link } from 'React/components';
import { ReactComponent as IconArrowRight } from 'Icons/ArrowLong.svg';

const AreasActividad = ({ title, module_cta, subModules, anchor }) => {

    const [activeFrame, setActiveFrame] = useState(1);

    const imagesContainerWidth = { width: subModules.length * 100 + 'vw' };
    const headerSliderComponent = React.createRef();
    const prevButton = React.createRef();
    const nextButton = React.createRef();

    // hovering on title triggers link icon effect
    const refList = subModules.map(() => React.createRef());

    const onHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId - 1)].current.classList.add('is-hovered');
    };

    const notHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId) - 1].current.classList.remove('is-hovered');
    };

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < subModules.length) {
            setActiveFrame(activeFrame + 1);
            var title = subModules[activeFrame].title
            var frame = activeFrame + 1;

            var dataObject = {
                event: "ga_event",
                event_name: "image_scroll_energy",
                event_category: "image scroll energy",
                event_action: frame + ' : ' + title,
                event_label: undefined,
                event_value: undefined,
                title: frame + ' : ' + title,
                location: "energia"
            }
            dataLayer.push(dataObject);

            if (activeFrame === (subModules.length - 1)) {
                sliderCompleted();
            }


        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {

            setActiveFrame(activeFrame - 1);

            var frame = activeFrame - 1;
            var title = subModules[frame - 1].title

            var dataObject = {
                event: "ga_event",
                event_name: "image_scroll_energy",
                event_category: "image scroll energy",
                event_action: frame + ' : ' + title,
                event_label: undefined,
                event_value: undefined,
                title: frame + ' : ' + title,
                location: "energia"
            }
            dataLayer.push(dataObject);
        }
    };


    const sliderCompleted = () => {

        window.dataLayer.push({
            event: "ga_event",
            event_name: "slider_completed_energy",
            event_category: "slider completed energy",
            event_action: undefined,
            event_label: undefined,
            event_value: undefined,
            location: "energia"
        });
        // console.log('slider completed')
    }

    useSliderSwipe(headerSliderComponent, setActiveFrame, subModules.length);
    useFade(headerSliderComponent);

    if (typeof window !== 'undefined') {
        return (
            <div className="module grid areas-actividad slow" data-active-frame={activeFrame} ref={headerSliderComponent}>
                <TagTitle style="slow" title={title} />
                {subModules.length > 1 && (
                    <>
                        <button
                            className="ghost-frame-button ghost-frame-button--left"
                            onMouseOver={prevHover}
                            onMouseOut={removeHover}
                            onClick={prevFrame}
                        >
                            Slider anterior
                        </button>
                        <button
                            className="ghost-frame-button ghost-frame-button--right"
                            onMouseOver={nextHover}
                            onMouseOut={removeHover}
                            onClick={nextFrame}
                        >
                            Slider siguiente
                        </button>
                    </>
                )}
                <div className="image-frames-container">
                    <div className="frames-container" style={imagesContainerWidth}>
                        {subModules.map(({ image, imageReplace }, idx) => {
                            const imageUrl = useWindowWidth() >= 767 ? image : image;

                            return (
                                <div className="image-frame data-point" data-frame-id={idx + 1} key={`frame-${idx}`}>
                                    <div className="img-container" style={{ backgroundImage: `url(${imageUrl ? imageUrl.url : ''})` }}></div>
                                </div>
                            );
                        })}
                        <span className="custom-anchor" id={anchor} />
                    </div>
                </div>
                <div className="slider-controls">
                    <button
                        ref={prevButton}
                        className="prev-button"
                        onClick={prevFrame}
                        data-frame={activeFrame}
                        data-title={subModules}>
                        <IconArrowRight />
                    </button>
                    <button
                        ref={nextButton}
                        className="next-button"
                        onClick={nextFrame}
                        data-frame={activeFrame}
                        data-title={subModules}>
                        <IconArrowRight />
                    </button>

                </div>
                <div className="frames-container text-frames-container slow">
                    {subModules.map((frame, idx) => {
                        const { title, body, link } = frame;

                        return (
                            <div className="text-frame" ref={refList[idx]} data-frame-id={idx + 1} key={`frame-${idx}`}>
                                <a href={link ? link.link : ''} data-ref-id={idx + 1} onMouseOut={notHover} onMouseOver={onHover} target={link ? link.target : ''}>
                                    <h3 className="title title--m">{title}</h3>
                                </a>
                                <p className="body body--m">{body}</p>
                                {link &&

                                    <Link frameTitle={title} frame={idx} module="distribuidor" type="arrow" className="frame-cta" path={link.link} target={link.target}>
                                        {link.caption}
                                    </Link>}
                            </div>
                        );
                    })}
                </div>

                {module_cta && <Link type="arrow" className="module_cta slow" path={module_cta.link} target={module_cta.target}>
                    {module_cta.caption}
                </Link>}
            </div>
        );
    }
    else {
        return (<></>);
    }
}

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default AreasActividad;
