// button.component.js
import React, { useState } from 'react';
import './styles.scss';
import { Link, Lightbox, Wysiwyg, PopUpLegalComponent, PopUpLegalComponentInvestor } from 'React/components';
import { rndKeyGen } from 'React/helpers';
import { ReactComponent as IconPhone } from 'Icons/phone.svg';
import { ReactComponent as IconEmail } from 'Icons/email.svg';
import { useSlowFade } from '../../custom_hooks';

const Banners = ({ model, privacyPolityHtml, labelPrivacyPolicy, labelSchedule, culture }) => {
    const { moduleTitle, type, subModules, anchor } = model;

    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');
    const [visibleLegalLightboxState, setVisibleLegalLightboxState] = useState(false);
    const [visibleLegalLightboxInvestorState, setVisibleLegalLightboxInvestorState] = useState(false);
    const [fileState, setFileState] = useState('');

    const moduleRef = React.createRef();

    useSlowFade(moduleRef);

    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

    const handleLegalLightboxClick = (status, file, type) => {
        setFileState(file);
        switch (type) {
            case "Popup-energia":
                setVisibleLegalLightboxState(status);
                break;
            case "Popup-quality-investor":
                setVisibleLegalLightboxInvestorState(status);
                break;
            default:
                setVisibleLegalLightboxState(status);
                break;
        }
    };

    if (type === 'motosharing') {
        const { title, description, phone, email, fax, schedule, cta, isLightbox, popup } = subModules[0];
        return (
            <div ref={moduleRef} className="module module-banners slow--y">
                <div className="grid">
                    <div className="banner-container slow--y">
                        <div className="banner-item slow--y">
                            <h2 className="title--m mb-2 slow--y">{title}</h2>
                            {description && <p className="body--m slow--y">{description}</p>}
                            {cta && <Link type={isLightbox ? "lightbox" : "arrow"} target={cta.target} path={cta.link} className="slow--y" label={cta.caption} />}
                        </div>
                        <div className="banner-item slow--y">
                            <div className="banner-contact">
                                {phone &&
                                    <div>
                                        <a className="title--s slow--y" href={"tel:" + phone}>
                                            <IconPhone />
                                        TEL {phone}{' '}
                                        </a>
                                    </div>
                                }
                                {fax &&
                                    <div>
                                        <a className="title--s slow--y" href={"fax:" + fax}>
                                            <IconPhone />
                                            FAX {fax}
                                        </a>
                                    </div>
                                }
                                {email &&
                                    <a href={"mailto:" + email.link} className="item-email body--l slow--y">
                                        <IconEmail />
                                        <span className="body--m slow--y">
                                            <span className="link--black">{email.caption}</span>
                                        </span>
                                    </a>
                                }
                                {popup &&
                                    <p className="body--s data-handling-link">
                                        {privacyPolityHtml} <a href="#" onClick={() => handleLightboxClick(popup.text)}>{labelPrivacyPolicy}</a>
                                    </p>
                                }
                                {schedule && schedule.length > 0 &&
                                    <div className="horario mt-4 slow--y">
                                        <div className="taglabel">{labelSchedule}</div>
                                        {schedule.map((item, idx) => {
                                            return (
                                                <p className="body--m" key={"horario-" + idx}>{item}</p>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <span className="custom-anchor" id={anchor} />
                </div>
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLightbox}
                    closeMethod={() => setVisibleLightbox(null)}
                >
                    <Wysiwyg html={lightboxContent} />
                </Lightbox>
            </div>
        );
    }

    else {
        return (
            <div ref={moduleRef} className="module module-banners">
                <div className="grid">
                    {moduleTitle && <h2 className={`slow--y title--tag`}>{moduleTitle}</h2>}
                    <div className="banner-container slow--y">
                        {subModules.map((frame, idx) => {
                            const { title, content, phone, emails, cta, isLightbox, blockAccess, legalPopup } = frame;

                            return (
                                <div className="banner-item slow--y" key={`data-point-${idx}`}>
                                    <div className="banner-item__content">
                                        <h2 className="title--s slow--y">{title}</h2>
                                        <p className="body--m slow--y">{content}</p>
                                        <div className="banner-contact slow--y">
                                            {phone &&
                                                <div>
                                                    <a className="title--s slow--y" href={"tel:+" + phone}>
                                                        <IconPhone />
                                                        {phone}
                                                    </a>
                                                </div>
                                            }
                                            {emails && emails.length > 0 &&
                                                <div>
                                                    <a href={"mailto:" + emails[0].link} className="slow--y item-email body--l">
                                                        <IconEmail />
                                                        {emails[0].caption}
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                        {cta && !blockAccess && !legalPopup && <Link className="slow--y" type={isLightbox ? "lightbox" : "arrow"} path={cta.link} label={cta.caption} target={cta.target} />}
                                        {cta && (blockAccess || legalPopup) && <Link className="slow--y" type="legalPopup" label={cta.caption} target={cta.target} onClickFunction={() => handleLegalLightboxClick(true, cta.link, legalPopup ? legalPopup : "Popup-energia")} />}

                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <span className="custom-anchor" id={anchor} />
                </div>
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLegalLightboxState}
                    hideCloseButton
                    closeMethod={() => setVisibleLegalLightboxState(null)}
                >
                    <PopUpLegalComponent setVisibleLightbox={handleLegalLightboxClick} file={fileState} culture={culture} />
                </Lightbox>
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLegalLightboxInvestorState}
                    hideCloseButton
                    closeMethod={() => setVisibleLegalLightboxInvestorState(null)}
                >
                    <PopUpLegalComponentInvestor setVisibleLightbox={handleLegalLightboxClick} file={fileState} culture={culture} />
                </Lightbox>
            </div>
        );
    }
};

export default Banners;
